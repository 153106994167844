import  {FC, useState} from 'react';
import styles from './personae.module.css';
import PersonaeAvatar, { PersonaeAvatarProps } from './PersonaeAvatar';
import { Button } from 'kls-ui';
import { ButtonProps } from 'kls-ui/components/Button/Button';
import {ProfileSwitcherItem} from "./ProfileSwitcherItem";
import classNames from "classnames";

interface PersonaeCards {
  avatar?: PersonaeAvatarProps;
  title?: string;
  content?: string;
  buttonFirst: ButtonProps;
  buttonSecond: ButtonProps;
  checkboxLabel: string;
}

interface PersonaeProps {
  personae: PersonaeCards[];
}

export const Personae: FC<PersonaeProps> = ({ personae }) => {
  const [selectedProfile, setSelectedProfile] = useState<number>(0);

  const profileStyle=(profile:number)=>classNames({
    ["show transition-all duration-300 py-8"]: selectedProfile===profile,
    ["notShow"]:selectedProfile!==profile,
    [styles.personaeSlide]:true
  })

  return (
    <div className={`${styles.personaeWrapper} ${styles["personae-avatar-css-var"]}`}>
      <div className={styles.personaeContainer}>
      


          <div className="w-5/6" >
            {personae.map((persona, index) => (
              <div key={index} className={profileStyle(index)}>
             
                <div className={styles.personaeContent}>
                  <span className={styles.personaeTitle}>{persona.title}</span>
                  <p className={styles.description}>{persona.content}</p>
                  <div className={styles.personaeActions}>
                    <Button
                      leftIcon={persona.buttonFirst?.leftIcon}
                      rightIcon={persona.buttonFirst?.rightIcon}
                      label={persona.buttonFirst?.label}
                      size={persona.buttonFirst?.size}
                      color={persona.buttonFirst?.color}
                      styling={persona.buttonFirst?.styling}
                      args={{ onClick: persona.buttonFirst?.args?.onClick }}
                    />
                    <Button
                      leftIcon={persona.buttonSecond?.leftIcon}
                      rightIcon={persona.buttonSecond?.rightIcon}
                      label={persona.buttonSecond?.label}
                      size={persona.buttonSecond?.size}
                      color={persona.buttonSecond?.color}
                      styling={persona.buttonSecond?.styling}
                      args={{ onClick: persona.buttonSecond?.args?.onClick }}
                    />
                  </div>
                </div>
                <div className={styles.personaeAvatar}>
                  <PersonaeAvatar avatar={persona.avatar?.avatar} title={persona.avatar?.title} />
                </div>
              </div>
            ))}
          </div>
          <div className={styles.containerAvatarList}>
          {personae.map((item,index)=>  <ProfileSwitcherItem key={index} id={index} setSelectStatus={setSelectedProfile} avatar={item.avatar?.avatar} title={item.avatar?.title} selected={selectedProfile===index} onClick={()=>{}}/>)}
        </div>
      </div>
    </div>
  );
};


