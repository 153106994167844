import {FC} from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import styles from './menuItem.module.css';
import {MaterialIcon} from "../../../../shared/components/icons";
export interface TopMenuItemProps {
  selectedItem: string;
  handleClick: (item: string) => void;
  label: string;
  link: string;
  materialIconLabel: string;
}

const SidebarMenuItemStyle = (props: TopMenuItemProps) =>
  classNames({
    [styles.topMenuItem]: true,
    [styles.innerBottomBorder]: props.label === props.selectedItem,
    [styles.topMenuItemColor]: props.label !== props.selectedItem
  });
const TopMenuItem: FC<TopMenuItemProps> = (props) => {
  return (
    <Link
      onClick={() => props.handleClick(props.label)}
      className={SidebarMenuItemStyle(props)}
      to={props.link}
    >
        <MaterialIcon className="text-base" label={props.materialIconLabel}/>
      <span className={styles.topMenuItemLabel}>{props.label}</span>
    </Link>
  );
};

export default TopMenuItem;
