import {createColumnHelper} from "@tanstack/react-table";
import {WindowOrganizationRequestsResponse} from "../../types";

export const getWindowRequestsColumns=()=> {
    const columnHelper = createColumnHelper<WindowOrganizationRequestsResponse>()
    const renderOrganizationImage=(gedId:string|undefined)=> {
        if (gedId) return <img className="rounded-full" src={`/karazal/DownloadFile?gedId=${gedId}`} alt=""/>
        return <></>;
    }
    return ([
        columnHelper.accessor('organismName', {
            cell: info =>
                <div className="flex gap-2 items-center" >
                    <div className="flex justify-center  h-6 aspect-square rounded-full">{renderOrganizationImage(info.row.original.gedId)}</div>
                    <span className="font-medium text-sm text-slate-800">{info.getValue()}</span>
                </div>
        }),
        columnHelper.accessor('statusRequest', {
            cell: info =>
                <div>
                    <span className="font-normal text-[13px] text-slate-700">{info.getValue()}</span>
               </div>
        }),
    ])
}