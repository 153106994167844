import { FC } from 'react';
import { BaseSVGIconProps } from '../../types/components.type';
import AbstractSVGIcon from './AbstractSVGIcon';
const XSvg: FC<BaseSVGIconProps> = (props) => (
  <AbstractSVGIcon
    size={props.size}
    className={props.className}
    spin={props.spin}
    hidden={props.hidden}
    viewBox="0 0 10 10">
    <path d="M9.66668 1.27333L8.72668 0.333328L5.00001 4.06L1.27334 0.333328L0.333344 1.27333L4.06001 5L0.333344 8.72666L1.27334 9.66666L5.00001 5.93999L8.72668 9.66666L9.66668 8.72666L5.94001 5L9.66668 1.27333Z" />
  </AbstractSVGIcon>
);
export default XSvg;
