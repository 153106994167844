import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {UserLoginSchema} from '../../../holders/schemas';
import styles from './login-steps.module.css';
import logo from '../../../../../shared/assets/icons/ksw-logo.svg';
import {InputText, InputPassword} from '../../../../../shared/components/controlled-ui';
import {Link} from 'react-router-dom';
import classNames from 'classnames';
import {LoginUser} from '../../../types';
import {FC, useEffect, useState,KeyboardEvent} from 'react';
import {NotificationCard, SimpleCard, SubmitButton} from '../../../../../shared/components';
import {useHandleTranslation} from '../../../../../shared/hooks';
import {NotificationType} from "../../../../../shared/utils/enums";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../shared/stores/store";

interface LoginFirstStepProps {
    onSubmit: (data: LoginUser) => void;
    isLoading?: boolean;
    isError?: boolean | undefined;
    submitError?: string;
    className?: string;
}

const LoginSendOTP: FC<LoginFirstStepProps> = ({
                                                     className = '',
                                                     submitError = '',
                                                     isLoading = false,
                                                     isError = false,
                                                     ...props
                                                 }) => {
    const {
        control,
        handleSubmit,
        formState: {errors}
    } = useForm<LoginUser>({
        resolver:yupResolver(UserLoginSchema)
    });
    const { isComingFromSignup } = useSelector((state: RootState) => state.auth);
    const [isInfoMessageDisplayed,setInfoMessageDisplayStatus]=useState<boolean>(true)
    const {t: tL} = useHandleTranslation('login');
    const submitLogin = (data: LoginUser) => {
        props.onSubmit(data);
    };

    const containerStyles = classNames({
        [className]: true,
        [styles.firstStepRootContainer]: true
    });

    const loginErrorStyles = classNames({
        "absolute":true,
        ['notDisplay']: !isError,
        ['show transition-all duration-500']: isError || !!submitError
    });
    const loginInfoStyles = classNames({
        ['notDisplay']: !(isInfoMessageDisplayed && isComingFromSignup),
        ['show transition-all duration-500']: isComingFromSignup && isInfoMessageDisplayed
    });
    useEffect(() => {
       if (isError) setInfoMessageDisplayStatus(false);
    }, [isError]);
    const handleEnterKeyClick=(e:KeyboardEvent<HTMLInputElement>)=>{
        if (e.key=="Enter") (handleSubmit(submitLogin))();
    }
    return (
        <SimpleCard className={containerStyles} spin={isLoading}>
            <div className="flex flex-col gap-3">
                <div className="flex items-center gap-8">
                    <img src={logo} alt="Logo" className="size-10"/>
                    <div className="flex flex-col">
            <span className={styles.firstStepTitle}>
              {tL('login_stepOne_title')}
            </span>
                        <span className={styles.firstStepDescription}>
              {tL('login_stepOne_subtitle')}
            </span>
                    </div>
                </div>
                <div className="relative flex justify-center">
                    <NotificationCard
                        type={NotificationType.ERROR}
                        className={loginErrorStyles}>
                        <>
                            {(submitError && tL(submitError)) ||
                                (errors.login?.message && tL(errors.login?.message)) ||
                                (errors.password?.message && tL(errors.password?.message))}
                        </>
                    </NotificationCard>
                    <NotificationCard
                        className={loginInfoStyles}
                        type={NotificationType.INFO}>
                        { tL("login_stepOne_fromSignup_info")}
                    </NotificationCard>
                </div>

                <div className="flex flex-col gap-4">
                    <div className={styles.formContainer}>
                        <InputText
                            size={'sm'}
                            label={tL('login_stepOne_email')}
                            control={control}
                            name={'login'}
                            placeholder={tL('login_stepOne_emailPlaceholder')}
                            error={errors.login?.message && tL(errors.login?.message)}
                            onKeyDown={handleEnterKeyClick}
                            withError
                        />
                        <InputPassword
                            size={'sm'}
                            label={tL('login_stepOne_password')}
                            control={control}
                            name={'password'}
                            placeholder={tL('login_stepOne_passwordPlaceholder')}
                            error={errors.password?.message && tL(errors.password?.message)}
                            withError
                            withProgress={false}
                            onKeyDown={handleEnterKeyClick}
                        />
                    </div>
                    <div>
                        <div className={styles.formFooterContainer}>
                            <div className="flex flex-col items-center gap-3 ">
                                <SubmitButton onClick={handleSubmit(submitLogin)}>
                  <span className={styles.submitButton}>
                    {tL('login_stepOne_login')}
                  </span>
                                </SubmitButton>
                            </div>
                        </div>
                        <div className="flex gap-1 justify-between pt-4">
                            <Link to={'/signup'} className={styles.loginRedirect}>
                                {tL('login_stepOne_signup')}
                            </Link>
                            <Link to={'/forgetpassword'} className={styles.redirectLink}>
                                {tL('login_stepOne_noAccount')}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </SimpleCard>
    );
};

export default LoginSendOTP;
