import {FC, ReactNode} from "react";
import StatisticsItem from "../statistics-item/StatisticsItem";
import styles from "./stats-numbers.module.css"
interface StatsNumbersProps {
 mainNumber:{text:string,color:string};
 statisticsItems: {label:string,color:string,icon:ReactNode}[];
}

export const StatsNumbers:FC<StatsNumbersProps>=(props)=>{
    return (
    <div className={styles.rootContainer}>
        <span className={styles.mainNumber} style={{color:props.mainNumber.color}}>{props.mainNumber.text}</span>
        <div className="flex w-full justify-around">
            {props.statisticsItems.map((element,index)=>(<StatisticsItem key={index} {...element }/>))}
        </div>
    </div>
    )
}