import {FC} from 'react';
import {BaseSVGIconProps, DropDownListButtonProps} from '../../../../../shared/types';
import styles from './filterBar.module.css';
import {DropDownListButton} from "../../../../../shared/components";
import { Button, ButtonProps } from 'kls-ui';
interface ButtonFilterProps {
  label: string;
  icon: FC<BaseSVGIconProps>;
  handleClick: (b: boolean) => void;
  isSelected: boolean;
}

export interface FilterBarContentProps {
  label: string;
  searchLength: number;
  buttons: ButtonFilterProps[];
  dropDownListButton?: DropDownListButtonProps;
  buttonAddArgs?:ButtonProps;
}

export const FilterBarContent: FC<FilterBarContentProps> = (props) => {
  return (
    <div className={styles.filterContent}>
      <div className="flex gap-4">
        <div className="flex gap-2 items-center">
          <span>{props.label}</span>
          <span className={styles.filterContentLength}>
            {props.searchLength}
          </span>
        </div>
        <div className="flex gap-4 justify-center">
          {props.buttons.map((button, index) => (
            <button
              onClick={() => button.handleClick(button.isSelected)}
              className={
                button.isSelected
                  ? styles.selectedFilterContentFltrBtn
                  : styles.filterContentFltrBtn
              }
              key={index}>
              <button.icon />
              {button.label}
            </button>
          ))}
        </div>
      </div>
    {props.dropDownListButton?.buttonAttribute && props.dropDownListButton.menuAttributes &&  <div className="relative flex items-center">
            <DropDownListButton buttonAttribute={props.dropDownListButton?.buttonAttribute} menuAttributes={props.dropDownListButton?.menuAttributes}/>
      </div>
}
{
  props.buttonAddArgs && <div className="relative flex items-center"><Button {...props.buttonAddArgs} ></Button></div>
}
    </div>
  );
};
