import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import styles from './forgetpassword-steps.module.css';
import logo from '../../../../../shared/assets/icons/ksw-logo.svg';
import { InputText } from '../../../../../shared/components/controlled-ui';
import classNames from 'classnames';
import { FC } from 'react';
import { SimpleCard, SubmitButton } from '../../../../../shared/components';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { useHandleTranslation } from '../../../../../shared/hooks';
import * as yup from 'yup';

interface ResetPasswordRequestProps {
    onSubmit: (email: string) => void;
    isLoading?: boolean;
    isError?: boolean | undefined;
    submitError?: string;
    className?: string;
}

const EmailSchema = yup.object().shape({
    email: yup.string().email('Invalid email address').required('Email is required'),
});

const ResetPasswordRequest: FC<ResetPasswordRequestProps> = ({
                                                                 className = '',
                                                                 submitError = '',
                                                                 isLoading = false,
                                                                 isError = false,
                                                                 ...props
                                                             }) => {
    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useForm<{ email: string }>({
        resolver: yupResolver(EmailSchema)
    });
    const { t: tL } = useHandleTranslation('resetPassword');

    const submitRequest = (data: { email: string }) => {
        props.onSubmit(data.email);
    };

    const containerStyles = classNames({
        [className]: true,
        [styles.forgotPasswordRootContainer]: true
    });

    const errorStyles = classNames({
        [styles.serverErrorMessage]: true,
        ['notShow']: !isError,
        ['show transition-all duration-500']: isError || !!submitError
    });

    return (
        <SimpleCard className={containerStyles} spin={isLoading}>
            <div className="flex flex-col gap-3">
                <div className="flex items-center gap-8">
                    <img src={logo} alt="Logo" className="size-10" />
                    <div className="flex flex-col">
                        <span className={styles.forgotPasswordTitle}>
                            {tL('reset_password_title')}
                        </span>
                        <span className={styles.forgotPasswordDescription}>
                            {tL('reset_password_subtitle')}
                        </span>
                    </div>
                </div>
                <div className={errorStyles}>
                    <>
                        <ExclamationCircleIcon className="size-5 text-red-700" />
                        {(submitError && tL(submitError)) ||
                            (errors.email?.message && tL(errors.email?.message))}
                    </>
                </div>
                <div className="flex flex-col gap-4">
                    <div className={styles.forgotPasswordFormContainer}>
                        <InputText
                            size={'sm'}
                            label={tL('reset_password_email')}
                            control={control}
                            name={'email'}
                            placeholder={tL('reset_password_emailPlaceholder') }
                            error={errors.email?.message && tL(errors.email?.message)}
                        />
                    </div>
                    <div>
                        <div className={styles.forgotPasswordFooterContainer}>
                            <div className="flex flex-col items-center gap-3 ">
                                <SubmitButton onClick={handleSubmit(submitRequest)}>
                                    <span className={styles.submitButton}>
                                        {tL('reset_password_submit')}
                                    </span>
                                </SubmitButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SimpleCard>
    );
};

export default ResetPasswordRequest;
