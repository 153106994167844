import { FC, useEffect, useRef, useCallback } from 'react';
import styles from './card-grid.module.css';
import {
  CARD_DROPDOWN_ADMIN,
  CARD_DROPDOWN_ALL,
  CARD_DROPDOWN_CITIZEN,
  CARD_DROPDOWN_CONTRIBUTOR_AD_CD,
  CARD_DROPDOWN_NO_ROLE
} from '../../../utils/constants/components.constants';
import { CardGuichet, Guichet } from '../cards/base-cards/CardGuichet';
import { CardEservice, Eservice } from '../cards/base-cards/CardEservice';
import SkeletonGrid from './SkeletonGrid';

import { DynamicListData } from '../../../../../shared/types';
import { FavoriteEserviceRequest, FavoriteGuichetRequest } from '../../../types';

export interface CardGridProps {
  items: Guichet[] | Eservice[];
  onSelectDropDown: (selection: { id: string; selectedValue: string }) => void;
  fetchMoreData: (page: number) => void;
  currentPage: number;
  totalPages: number;
  type: 'guichet' | 'eservice' | 'selectable';
  isFetching?: boolean | false;
  setFavoriteParams?:(params:FavoriteGuichetRequest)=>void;
  setFavoriteEservice?:(params:FavoriteEserviceRequest)=>void;

  dataDomaine?:DynamicListData[] | undefined;
  selectedItems?: Array<Eservice>;
  setSelectedItems?: (items: Array<Eservice>) => void;
}

export const CardGrid: FC<CardGridProps> = ({
  items,
  onSelectDropDown,
  fetchMoreData,
  currentPage,
  totalPages,
  type,
  isFetching,
  setFavoriteParams,dataDomaine,setFavoriteEservice,selectedItems,setSelectedItems
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  // Get dropdown data based on role
  const getDropDownData = useCallback((role: string[] | string | undefined) => {
    if (!role) return CARD_DROPDOWN_NO_ROLE;

    if (Array.isArray(role)) {
      if (role.includes('CONTRIBUTEUR') && role.includes('CITIZEN DEVELOPER')) {
        return CARD_DROPDOWN_CONTRIBUTOR_AD_CD;
      }
      if (role.includes('CONTRIBUTEUR') && role.includes('Administrateur')) {
        return CARD_DROPDOWN_CONTRIBUTOR_AD_CD;
      }
      
      if (role.includes('Administrateur')) {
        return CARD_DROPDOWN_ADMIN;
      }

      if (role.includes('CITIZEN DEVELOPER')) {
        return CARD_DROPDOWN_CITIZEN;
      }
    }
  
    return CARD_DROPDOWN_ALL;
}, []);

const handleSelectItem = (item:Eservice, isSelected: boolean) => {
  if(selectedItems && setSelectedItems){
  const updatedSelection = isSelected
    ? [...selectedItems, item]
    : selectedItems.filter((selected) => selected.id !== item.id);

  setSelectedItems(updatedSelection);
  }
};

  // Fetch more items when needed
  const fetchMoreItems = useCallback(() => {
    if (currentPage >= totalPages) return;
    fetchMoreData(currentPage + 1);
  }, [totalPages, fetchMoreData, currentPage]);

  // Handle scroll event
  const handleScroll = useCallback(() => {
    const container = containerRef.current;
    if (!container) return;

    const { scrollTop, scrollHeight, clientHeight } = container;
    if (scrollTop + clientHeight >= scrollHeight - 2) {
      fetchMoreItems();
    }
  }, [fetchMoreItems]);

  // Manage scroll event listener
  useEffect(() => {
    const container = containerRef.current;
    if (container && currentPage < totalPages) {
      container.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (container && currentPage < totalPages) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll, currentPage, totalPages]);

  return (
    <>
      <div className={styles.cardsContainer} ref={containerRef}>
        {items.map((item, index) => {
          if ('role' in item && type === 'guichet') {
            return (
              <CardGuichet
                setFavoriteParams={setFavoriteParams}
                key={index}
                guichet={item as Guichet}
                dropDownData={getDropDownData(item.role)}
                cardAction={{
                  onSelectDropDown: (selection) => onSelectDropDown(selection)
                }}
                icon={
                  dataDomaine &&
                  item.industry &&
                  dataDomaine.filter(
                    (domaine) => domaine.label === item.industry
                  )[0]?.icon
                    ? dataDomaine.filter(
                        (domaine) => domaine.label === item.industry
                      )[0].icon
                    : undefined // Fallback if no icon is found
                }
              />
            );
          } else if(type === 'selectable' ){
            return (
              <CardEservice
                key={index}
                eservice={item as Eservice}
                setFavoriteEservice={setFavoriteEservice}
                dropDownData={getDropDownData(item.role)}
                cardAction={{
                  onSelectDropDown: (selection) => onSelectDropDown(selection)
                }}
                isSelected={selectedItems?.some((selected) => selected.id === item.id )|| false}
                onSelect={handleSelectItem}
              />
            );
          }
          else {  return (
            <CardEservice
              key={index}
              eservice={item as Eservice}
              setFavoriteEservice={setFavoriteEservice}
              dropDownData={getDropDownData(item.role)}
              cardAction={{
                onSelectDropDown: (selection) => onSelectDropDown(selection)
              }}
            />
          );}
        })}
        {isFetching && <SkeletonGrid numberOfCards={6}/>}
      </div>
    </>
  );
};
