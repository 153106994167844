import {Section,WindowTaskItem} from "../../../components/ui";


export const WindowPendingActionsWidget=()=>{
    const mockWindowTaskItems = [
        {
            image: 'https://picsum.photos/200/300',
            title: 'Access Guichet',
            description: 'Manage your account and access all features through the Guichet portal.',
            onAccessGuichetClick: () => {
                console.log('Access Guichet clicked');
            },
        },
        {
            image: 'https://picsum.photos/200/300',
            title: 'Submit Documents',
            description: 'Easily submit your required documents online.',
            onAccessGuichetClick: () => {
                console.log('Submit Documents clicked');
            },
        },
        {
            image: 'https://picsum.photos/200/300',
            title: 'Schedule Appointment',
            description: 'Book an appointment with our service team at your convenience.',
            onAccessGuichetClick: () => {
                console.log('Schedule Appointment clicked');
            },
        },
        {
            image: 'https://picsum.photos/200/300',
            title: 'View Notifications',
            description: 'Check your latest notifications and updates.',
            onAccessGuichetClick: () => {
                console.log('View Notifications clicked');
            },
        },
    ];
    const menuList=[
        {label:"Voir plus",value:"see_more",icon:<i className="material-flex-icon text-red-400">arrow_outward</i>},
        {label: "Masquer",value:"hide",icon:<i className="material-flex-icon text-red-400">delete</i>},
    ]
    return (
     <Section title={"Actions guichets en attente"} materialIconLabel="check_circle" className="flex flex-col gap-2" menuList={menuList} >
        {mockWindowTaskItems.map((element, index) => (<WindowTaskItem key={index}  {...element}/>))}
     </Section>
    )
}