import { FC } from 'react';
import { BaseSVGIconProps } from '../../../../../shared/types';
import styles from './buttons.module.css';
import classNames from 'classnames';
export interface FilterBarButtonProps {
  label?: string;
  icon: FC<BaseSVGIconProps>;
  handleClick: (b: boolean) => void;
  selected: boolean;
  reset?: boolean;
  resetClick?: () => void;
}
const filterBarStyle = (props: FilterBarButtonProps) =>
  classNames({
    [styles.filterBarButton]:
      props.reset != undefined && props.reset == false
        ? !props.reset
        : !props.selected,
    [styles.selectedFilterBarButton]:
      props.reset ?? props.selected
  });
const filterBarIconStyle = (props: FilterBarButtonProps) =>
  classNames({
    [styles.filterBarButtonIcon]: props.reset != undefined && props.reset == false
    ? !props.reset
    : !props.selected,
    [styles.selectedFilterBarButtonIcon]: props.reset ?? props.selected
  });
export const FilterBarButton: FC<FilterBarButtonProps> = (props) => {

  return (
    <button
      className={filterBarStyle(props)}
      onClick={() => props.handleClick(props.selected)}>
      {props.reset && (
        <i
          style={{ color: 'var(--mapping-slate-500)' }}
          onClick={(e) => {
            if (props.resetClick) props.resetClick();
            e.stopPropagation();
          }}
          className="material-flex-icon text-lg">
          {'reset_settings'}
        </i>
      )}
      {props.label && <span>{props.label}</span>}
      <props.icon className={filterBarIconStyle(props)} />
    </button>
  );
};
