import { FC } from 'react';
import { BaseSVGIconProps } from '../../types/components.type';
import AbstractSVGIcon from './AbstractSVGIcon';
const CloseSvg: FC<BaseSVGIconProps> = (props) => (
  <AbstractSVGIcon
    size={props.size}
    className={props.className}
    spin={props.spin}
    hidden={props.hidden}
  >
    <path d="M5.00103 10L6.17603 8.825L2.35936 5L6.17603 1.175L5.00103 0L0.00102568 5L5.00103 10Z" />
  </AbstractSVGIcon>
);
export default CloseSvg;
