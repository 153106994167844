import {MutableRefObject, useEffect, useRef} from "react";

export  const useEvent=<T extends HTMLElement>(
    name: string,
    callback: (e: Event) => void
): [MutableRefObject<T | null>, () => void] =>{
    const ref = useRef<T | null>(null);

    useEffect(() => {
        ref.current?.addEventListener(name, callback);
        return () => ref.current?.removeEventListener(name, callback);
    }, [ref.current, name, callback]);

    return [ref, () => ref.current?.dispatchEvent(new Event(name))];
}