import { FC } from 'react';
import { BaseSVGIconProps } from '../../types/components.type';
import AbstractSVGIcon from './AbstractSVGIcon';
const HomeSvg: FC<BaseSVGIconProps> = (props) => (
  <AbstractSVGIcon
    size={props.size}
    className={props.className}
    spin={props.spin}
    hidden={props.hidden}
    viewBox="0 0 18 16"
  >
    <path d="M9.00033 3.15833L13.167 6.90833V13.4167H11.5003V8.41666H6.50033V13.4167H4.83366V6.90833L9.00033 3.15833ZM9.00033 0.916664L0.666992 8.41666H3.16699V15.0833H8.16699V10.0833H9.83366V15.0833H14.8337V8.41666H17.3337L9.00033 0.916664Z"/>
    </AbstractSVGIcon>
);
export default HomeSvg;
