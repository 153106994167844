import {ChangeEvent, forwardRef, ForwardRefRenderFunction, useEffect,useMemo, useState} from "react";
import {InputText} from "../input-text/InputText";
import {Select} from "../../drop-down/select/Select";
import classNames from "classnames";
import globalStyles from "../input.module.css";
import {PHONE_NUMBER_DATA} from "../../../utils/constants";
import {InputPhoneProps} from "../../../types";
import {useEvent} from "../../../hooks";

export const ForwardedInputPhone:ForwardRefRenderFunction<HTMLInputElement,InputPhoneProps>=(props)=>{

    const handleEvent = (e: Event) => {
        if (props.onChange) props.onChange(e);
    };
    const [inputRef, emitEvent] = useEvent<HTMLInputElement>(
        'input-select',
        handleEvent
    );
   const {size="sm"}=props
   const [phoneNumber,setPhoneNumber]=useState<{prefix:string|undefined,suffix:string|undefined}>({prefix:undefined,suffix:undefined})
   const getOptionData = useMemo(
        () => () =>
            PHONE_NUMBER_DATA.map((item) => ({
                label: item.prefix,
                value: item.prefix,
            })),
        []
    );
    const getInputPlaceholder= useMemo(
        () => () =>
            PHONE_NUMBER_DATA.find((item) => (item.prefix===phoneNumber.prefix))?.placeholder,
        [phoneNumber]
    );
  const handleSelectChange=(e:Event)=>{
      const inputElement=e.target as HTMLInputElement
    if (inputElement)  setPhoneNumber({...phoneNumber,prefix:inputElement.value})
  }
  const handleInputChange=(e:ChangeEvent<HTMLInputElement>)=>{
    if (e.target)  setPhoneNumber({...phoneNumber,suffix:e.target.value})
 }

const rootStyles=classNames({
    [globalStyles.rootContainer]:true,
    [globalStyles["portal-input-css-var"]]:true,
})
const inputLabelStyle=classNames({
        [globalStyles.inputLabel]:true,
        ["text-xs"]:size==="sm",
        ["text-sm"]:["lg","md"].includes(size),
    })
    const renderLabel=()=>{
        if (props.label) return (
            <div className="flex justify-between w-full ">
                <span className={inputLabelStyle}>{props.label}</span>
                <span></span>
            </div>)
    }
    useEffect(() => {
        if (inputRef.current && phoneNumber.prefix && phoneNumber.suffix) {
            inputRef.current.value = (phoneNumber.prefix+phoneNumber.suffix);
            emitEvent();
        }
    }, [phoneNumber]);
 const renderSelectedValueSelectProps=()=>{
     if(props.value) return {selectedValues:props.value.prefix}
 }
 const renderSelectedValueInputProps=()=>{
        if(props.value) return {value:props.value.suffix}
    }
return (
    <div className={rootStyles}>
        {renderLabel()}
    <div className={"flex items-center w-full"}>
     <input className="hidden" ref={inputRef} />
     <Select size={size} options={getOptionData()} disabled={props.disabled} placeholder={props.prefixPlaceholder}  onChange={handleSelectChange}  removeRightRadius withError={props.withError} {...renderSelectedValueSelectProps()} />
     <InputText size={size} placeholder={getInputPlaceholder()||props.placeholder} disabled={props.disabled} onChange={handleInputChange} errorMessage={props.errorMessage} withError={props.withError}  {...renderSelectedValueInputProps()}  removeLeftRadius />
    </div>
    </div>)
}
export const InputPhone=forwardRef(ForwardedInputPhone)

