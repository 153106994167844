import React, { useState } from 'react';
import styles from './switcher-button.module.css';
interface ToggleSwitchProps {
  label: string;
  onToggle: (isOn: boolean) => void;
  defaultValue: boolean;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ label, onToggle,defaultValue }) => {
  const [isOn, setIsOn] = useState(defaultValue);

  const handleToggle = () => {
    setIsOn(!isOn);
    onToggle(!isOn);
  };

  return (
    <div className="flex items-center justify-between p-2">
      <label className={styles.switcherLabel}>{label}</label>
      <div
      style={{backgroundColor: !isOn ? 'var(--blue-500)' : 'var(--mapping-slate-050)',}}
        className={`relative flex justify-between w-9 h-4 cursor-pointer rounded-full`}
        onClick={handleToggle}
      >
        {isOn?<span style={{color:'var(--white-base)'}} className='material-icon'>dark_mode</span>
        :<span style={{color:'var(--white-base)'}} className={`material-icon ${!isOn && 'absolute left-0.5 duration-300 transform translate-x-4'}`}>light_mode</span>}
        <div
          style={{backgroundColor:'var(--white-base)'}}
          className={`absolute top-0.5 left-0.5 w-3 h-3 rounded-full transition-transform duration-300 ${
            isOn && 'transform translate-x-5'
          }`}
        >
        </div>
      </div>
    </div>
  );
};

export default ToggleSwitch;
