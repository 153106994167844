import { postGenericKarazActionRequest } from '../../../../shared/utils/helpers';
import {
    PASSWORD_RESET_REQUEST_PATH,
    PASSWORD_RESET_VERIFY_CODE_PATH,
    PASSWORD_RESET_SUBMIT_PATH
} from './apiPath';
import { ResponseResult } from '../../../../shared/utils/enums';
import {
    PasswordResetRequest,
    PasswordResetConfirm,
    PasswordResetSubmit, PasswordResetResponse
} from '../../types';
import {
    GenericResponseWrapper
} from '../../../../shared/types';

export const postPasswordResetRequest = async (
    data: PasswordResetRequest
): Promise<GenericResponseWrapper<PasswordResetResponse>> => {
    return await postGenericKarazActionRequest<
        PasswordResetRequest,
        GenericResponseWrapper<PasswordResetResponse>
    >(data, PASSWORD_RESET_REQUEST_PATH, ResponseResult.JSON);
};

export const postPasswordResetVerifyCode = async (
    data: PasswordResetConfirm
): Promise<GenericResponseWrapper<null>> => {
    return await postGenericKarazActionRequest<
        PasswordResetConfirm,
        GenericResponseWrapper<null>
    >(data, PASSWORD_RESET_VERIFY_CODE_PATH, ResponseResult.JSON);
};

export const postPasswordResetSubmit = async (
    data: PasswordResetSubmit
): Promise<GenericResponseWrapper<null>> => {
    return await postGenericKarazActionRequest<
        PasswordResetSubmit,
        GenericResponseWrapper<null>
    >(data, PASSWORD_RESET_SUBMIT_PATH, ResponseResult.JSON);
};
