export function cleanAndFormatNameResource(phrase:string) {
    return phrase.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[^a-zA-Z0-9_]/g, '_').toLowerCase();
}

export function formatDate(dateString:string):string {
    const inputDate = new Date(dateString);
    const currentDate = new Date();
  
    const diffTime = Math.abs(currentDate.getTime() - inputDate.getTime());
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  
    if (diffDays === 0) {
      return "Crée aujourd'hui";
    }
      if (diffDays < 30) {
      return `Crée il y a ${diffDays} jours `;
    }
      const diffMonths = Math.floor(diffDays / 30);
    return `Crée il y a ${diffMonths} mois`;
  }