import './shared/styles/App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import MainRoutes from './modules/portal/routes/MainRoutes';
import { useHandleTranslation } from './shared/hooks';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { store } from './shared/stores/store';
const App = () => {
  const { currLng } = useHandleTranslation();
  useEffect(() => {
    document.body.setAttribute('lang', currLng);
  }, [currLng]);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 5 * 60 * 1000
      }
    }
  });

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
      <Provider store={store}>
        <Routes>
          <Route path={'/*'} element={<MainRoutes />} />
        </Routes>
      </Provider>
      </BrowserRouter>
    </QueryClientProvider>

  );
};

export default App;
