import { Button} from "kls-ui";
import {FC,} from "react";
import {List} from "../../list/List";
import {useHandleComponentVisibility} from "../../../hooks";
import {DropDownListButtonProps} from "../../../types";


export const DropDownListButton:FC<DropDownListButtonProps>=(props)=>{
 const {isOpen,setOpenStatus,ref}= useHandleComponentVisibility();
  const open=()=>setOpenStatus(true)
   return (
       <div ref={ref}>
       <Button
           {...props.buttonAttribute}
           args={{...props.buttonAttribute.args,onClick:open}}
       />
           <div className="relative flex justify-center">
               {props.menuAttributes && <List
                   list={props.menuAttributes.list}
                   onChange={props.menuAttributes.onChange}
                   open={isOpen}
                   className="my-1 min-w-40  z-30"
                   setOpenStatus={setOpenStatus}
                   action={props.menuAttributes.action}
                   preventCheck
                   closeAlways
               />}
           </div>
        </div>
    )
}