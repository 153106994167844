import React from 'react';
import styles from './document.module.css';
import DocumentImageViewer from './DocumentImageViewer';
import {Star} from "@phosphor-icons/react";
import {MaterialIcon} from "../../../../../shared/components/icons";
import {Menu} from "../../../../../shared/components";

interface DocumentCardProps {
  documentImage: string;
  addedOn: string;
  totalPage: number;
  title: string;
  status: string;
  description: string;
  favorite?: boolean;
  documentLink?: string;
}

export const  DocumentCard: React.FC<DocumentCardProps> = (props) => {
const {
  documentImage,addedOn ,
  totalPage,
  title,
  status,
  description,
  favorite=false,
  documentLink}=props


  const renderFavoriteIcon=()=>{
  console.log(documentLink);
    console.log(documentImage);
   if(favorite) return (<div className="flex items-center"><Star size={24} weight="fill" className="text-amber-400"  /></div>)
    return <div className="flex items-center invisible"><Star size={24} weight="fill" className="text-amber-400"/></div>
  }
  const menuIcon=<MaterialIcon label="more_horiz" center className="text-md text-slate-600 text-2xl"/>
  const menuList=[
    {label:"Télécharger",value:"download",icon:<MaterialIcon center label="cloud_download" className="text-blue-600 text-base"/>},
    {label: "Copier lien",value:"copy_link",icon:<MaterialIcon center label="link" className="text-slate-500 text-base"/>},
    {label:"Ajouter favoris",value:"add_favorite",icon:<MaterialIcon center  label="star" className="text-slate-500 text-base"/>},
    {label: "Actualiser",value:"refresh",icon:<MaterialIcon center label="restore_page" className="text-emerald-600 text-base"/>},
    {label:"Supprimer",value:"delete",icon:<MaterialIcon center label="delete_outline" className="text-red-500 text-base"/>},
  ]
  return (
      <div className={`${styles.documentWrapper} ${styles['portal-documents-document_card-css-var']}`}>
        <div className="flex gap-2">
          {renderFavoriteIcon()}
          <DocumentImageViewer documentImage={"https://picsum.photos/200/300"} />
        </div>
          <div className="flex flex-col justify-between gap-2 w-full">
            <div className="flex flex-col gao-3">
              <div className={styles.documentHeader}>
                <div className="flex items-center gap-3">
                  <span className={styles.documentTitle}>{title}</span>
                  <span className={styles.statusBadge}>{status}</span>
                </div>
                  <Menu list={menuList} icon={menuIcon} onChange={()=>{}}/>
              </div>

              <div className={styles.documentContent}>
                <span>Ajouté le {addedOn}</span>
                <span>{description}</span>
                <span>{totalPage} {totalPage > 1 ? 'Pages' : 'Page'}</span>
              </div>
            </div>

            <div className={styles.documentActions}>
              <div className={styles.documentLink}>
                <span>Accéder au dossier source</span>
                 <MaterialIcon label="arrow_outward" center className="text-blue-600 text-base"/>
              </div>
            </div>


          </div>
      </div>
  );
};

