import React, {useState} from 'react';
import styles from './document.module.css';
import classNames from "classnames";

interface DocumentImageProps {
  documentImage: string;
}

const DocumentImageViewer: React.FC<DocumentImageProps> = (props) => {
    const [isHovered, setHovered] = useState<boolean>(false);

    const documentHoverStyle=classNames({
     [styles.documentBackgroundHover]:true,
     ["show transition-all duration-700 !opacity-80"]:isHovered,
     ["notShow"]:!isHovered,
 })
    const iconStyle=classNames({
        ["show transition-all duration-100"]:isHovered,
        ["notShow"]:!isHovered,
        ["absolute material-flex-icon text-white text-md z-40"]:true
    })
  return (
      <div className="flex items-center">
          <div className={styles.documentImgContainer} onMouseOver={()=>setHovered(true)} onMouseOut={()=>setHovered(false)}>
              <img className={styles.documentImg} alt="document" src={props.documentImage}/>
              <i className={iconStyle}>remove_red_eye</i>
              <div className={documentHoverStyle}></div>
          </div>
      </div>
  );
};

export default DocumentImageViewer;
