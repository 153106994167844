import {Controller, FieldPath, FieldValues} from "react-hook-form";
import {ControlledUiProps, InputColorProps} from "../../types";
import {InputColor as NativeInputColor} from "../input/input-color/InputColor";

export interface ControlledInputColorProps <TFieldValues extends FieldValues,TName extends FieldPath<TFieldValues>>
    extends InputColorProps, ControlledUiProps<TFieldValues,TName>{}

export const InputColor  = <
    TFieldValues extends FieldValues,
    TName extends FieldPath<TFieldValues>
>
(props:ControlledInputColorProps<TFieldValues, TName>) => {
    return (
        <Controller
            control={props.control}
            name={props.name}
            render={({ field: { onChange, onBlur, value,ref } }) => (
                <NativeInputColor
                    ref={ref}
                    {...props}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    errorMessage={props.error}
                />
            )}
        />
    );
};
