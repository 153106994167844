import { FC } from 'react';
import { BaseSVGIconProps } from '../../types/components.type';
import AbstractSVGIcon from './AbstractSVGIcon';
const OpenSvg: FC<BaseSVGIconProps> = (props) => (
  <AbstractSVGIcon
    size={props.size}
    className={props.className}
    spin={props.spin}
    hidden={props.hidden}
  >
    <path d="M1.17524 0L0.000244141 1.175L3.81691 5L0.000244141 8.825L1.17524 10L6.17524 5L1.17524 0Z" />
  </AbstractSVGIcon>
);
export default OpenSvg;
