import { array, object, string } from 'yup';

export const EServiceSettingsSchema = object().shape({
  name: string().required('Ce champ est obligatoire.'),
  owner: string().required('Ce champ est obligatoire.'),
  icon: string().required('Ce champ est obligatoire.'),
  domain: string().required('Ce champ est obligatoire.'),
  description: string().required('Ce champ est obligatoire.'),
  color: string().required('Ce champ est obligatoire.'),
  tags: array().of(string().required('Chaque tag est obligatoire')).min(1, 'Au moins un tag est requis').required('Ce champ est obligatoire.')
});
