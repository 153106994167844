import { useParams } from "react-router-dom";

export const OrganisationPage = () => {
  const  guichetId  = useParams<{ guichetId: string }>();
  console.log("==========",guichetId );
  
  return (
    <div>OrganisationPage</div>
  )
}
