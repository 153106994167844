import { ApiRemoteAction } from '../../../../shared/types';
import { REMOTE_ACTION_BASE_PATH } from '../../../../shared/utils/constants';

export const SIGNUP_KARAZ_ACTION= 'karazapps/karaz/kls/creationcompte/signup/action/SignupBacker';

export const SIGN_UP_PATH: ApiRemoteAction = {
  path: REMOTE_ACTION_BASE_PATH,
  karazAction: SIGNUP_KARAZ_ACTION,
  operation: 'submitRegistration'
};

export const SIGN_UP_ACCOUNT_ACTIVATION_PATH: ApiRemoteAction = {
  path: REMOTE_ACTION_BASE_PATH,
  karazAction: SIGNUP_KARAZ_ACTION,
  operation: 'verifyCode'
};
export const SIGN_UP_RESEND_ACTIVATION: ApiRemoteAction = {
  path: REMOTE_ACTION_BASE_PATH,
  karazAction: SIGNUP_KARAZ_ACTION,
  operation: 'sendNewCode'
};
