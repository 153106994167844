import styles from './table-items.module.css';
export const RowSkeleton = () => {
  return (
    <tr>
      <td className={styles.fCaseContainer}>
        <div className={styles.fCase}>
          <span>
            <div style={{backgroundColor:'var(--mapping-slate-000)'}} className="w-6 h-6 rounded-full animate-pulse"></div>
          </span>
          <div style={{backgroundColor:'var(--mapping-slate-000)'}} className="h-8 w-8 rounded animate-pulse"></div>
        </div>
      </td>
      <td className={styles.nameCaseContainer}>
        <div className={styles.nameCase}>
          <div style={{backgroundColor:'var(--mapping-slate-000)'}} className="h-4 w-1/2 rounded animate-pulse"></div>
        </div>
      </td>
      <td className={styles.aCaseContainer}>
        <div style={{backgroundColor:'var(--mapping-slate-000)'}} className="h-4 w-1/3 rounded animate-pulse"></div>
      </td>
      <td className={styles.aCaseContainer}>
        <div style={{backgroundColor:'var(--mapping-slate-000)'}} className="h-4 w-1/4 rounded animate-pulse"></div>
      </td>
      <td className={styles.lCaseContainer}>
        <div className={styles.lCase}>
          <div style={{backgroundColor:'var(--mapping-slate-000)'}} className="h-4 w-1/4 rounded animate-pulse"></div>
        </div>
      </td>
    </tr>
  );
};
