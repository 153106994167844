import {AbstractSVGIcon} from "../../../../../shared/components/icons";
import {BaseSVGIconProps} from "../../../../../shared/types";
import {FC} from "react";

export const PlusIcon: FC<BaseSVGIconProps> = (props) => {
    return (<AbstractSVGIcon
        size={props.size}
        className={props.className}
        spin={props.spin}
        hidden={props.hidden}
        viewBox="0 0 20 21"
    >
        <path
            d="M15.8337 11.3333H10.8337V16.3333H9.16699V11.3333H4.16699V9.66663H9.16699V4.66663H10.8337V9.66663H15.8337V11.3333Z"
        />
    </AbstractSVGIcon>)
}
