import { FC } from "react";
import styles from './table-items.module.css'
import classNames from "classnames";
export interface HeaderTableProps {
    items:string[];
}
export const HeaderTable:FC<HeaderTableProps> = (props) => {
    const borderStyle = (length:number,index:number)=>classNames({
        [styles.headerItem]: true,
          [styles.borderL]: index === 0,
          [styles.borderR]: index+1 === length
    });
  return (
    <tr>
        {props.items.map((item:string,index:number)=>(
            <th key={index} className={borderStyle(props.items.length,index)}>{item}</th>
        ))}
    </tr>
  );
};
