import { FC, useEffect, useRef } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';

export const LocateControl: FC<{
  setPosition?: (position:L.LatLngLiteral) => void;
}> = ({ setPosition }) => {
  const map = useMap();
  const markerRef = useRef<L.Marker | null>(null); // Reference to store the marker

  useEffect(() => {
    const locateButton = L.control({ position: 'topleft' });
    locateButton.onAdd = function () {
      const div = L.DomUtil.create(
        'div',
        'leaflet-bar leaflet-control leaflet-control-custom'
      );
      div.innerHTML =
        '<span class="flex justify-center items-center w-full h-full"><svg xmlns="http://www.w3.org/2000/svg" fill="var(--mapping-map-button)" height="24px" viewBox="0 -960 960 960" width="24px" ><path d="M440-42v-80q-125-14-214.5-103.5T122-440H42v-80h80q14-125 103.5-214.5T440-838v-80h80v80q125 14 214.5 103.5T838-520h80v80h-80q-14 125-103.5 214.5T520-122v80h-80Zm40-158q116 0 198-82t82-198q0-116-82-198t-198-82q-116 0-198 82t-82 198q0 116 82 198t198 82Zm0-120q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Z"/></svg></span>'; // Icon for the button
      div.style.backgroundColor = 'var(--mapping-slate-000)';
      div.style.border = '1px solid var(--mapping-slate-200)';
      div.style.width = '40px';
      div.style.height = '40px';
      div.style.borderRadius = '8px';
      div.style.cursor = 'pointer';
      // Add hover effect
      div.onmouseover = function () {
        div.style.border = '1px solid var(--mapping-slate-300)'; // Change border on hover
        div.style.transform = 'scale(1.05)';
      };

      div.onmouseout = function () {
        div.style.border = '1px solid var(--mapping-slate-200)'; // Reset border when not hovered
        div.style.transform = 'scale(1)';
      };
      div.onclick = function () {
        map.locate({ setView: true, maxZoom: 16 });
      };

      return div;
    };
    if (locateButton) {
      locateButton.addTo(map);
    }

    const customIcon = L.icon({
      iconUrl:
        'data:image/svg+xml;charset=utf-8,' +
        encodeURIComponent(`
      <svg xmlns="http://www.w3.org/2000/svg" fill="#3B82F6" height="24px" viewBox="0 -960 960 960" width="24px" ><path d="M440-42v-80q-125-14-214.5-103.5T122-440H42v-80h80q14-125 103.5-214.5T440-838v-80h80v80q125 14 214.5 103.5T838-520h80v80h-80q-14 125-103.5 214.5T520-122v80h-80Zm40-158q116 0 198-82t82-198q0-116-82-198t-198-82q-116 0-198 82t-82 198q0 116 82 198t198 82Zm0-120q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Z"/></svg>
    `),
      iconSize: [32, 32],
      iconAnchor: [16, 16],
      popupAnchor: [0, -8],
    });

    // Handle location found event
    map.on('locationfound', (e: L.LocationEvent) => {
     if(setPosition) setPosition({ lat: e.latlng.lat, lng: e.latlng.lng });

      // Remove the previous marker if it exists
      if (markerRef.current) map.removeLayer(markerRef.current);

      // Create a new marker
      const newMarker = L.marker(e.latlng, { icon: customIcon })
        .addTo(map)
        .bindPopup('Voici, votre position!')
        .openPopup();

      // Store the reference to the new marker
      markerRef.current = newMarker;
    });

    return () => {
      map.off('locationfound');
      map.removeControl(locateButton);
    };
  }, [map, setPosition]);

  return null;
};
