import { PubStates } from "../../../../../components/ui/pubstates/PubStates";
import { EServiceSettingsSchema } from "../../../../../holders/schemas";
import { EServiceSettingsForm } from "../../../../../types/data.types";
import { Button } from "kls-ui";
import { InputText, Select, TextArea, InputColor, InputTags } from "../../../../../../../shared/components/controlled-ui";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import styles from '../eservice.param.module.css';
import { useHandleTranslation } from "../../../../../../../shared/hooks";

export const EServiceInfoTab = () => {
    const { control, handleSubmit, formState: { errors } } = useForm<EServiceSettingsForm>({ resolver: yupResolver(EServiceSettingsSchema) });
    const { t: tO } = useHandleTranslation("window");


    const dropdownList = [
      { value: "1", label: "Option 1", icon: <span className="material-icon size-6">star</span> },
      { value: "2", label: "Option 2", icon: <span className="material-icon size-6">favorite</span> },
      { value: "3", label: "Option 3", icon: <span className="material-icon size-6">access_alarm</span> },
      { value: "4", label: "Option 4", icon: <span className="material-icon size-6">check_circle</span> },
    ];

    const onSubmit = (formData: EServiceSettingsForm) => {
      console.log(formData, "formData")
    };


    return (
      <div className={styles.settingsContainer}>
        <div className={styles.settingsForm}>
          <div className={styles.formHeader}>
            <InputText control={control} size="md" label={tO("eService_name_label")} placeholder={tO("eService_name_placeholder")} name="name" error={errors.name?.message} errorMessage={errors.name?.message} />
            <InputText control={control} size="md" label={tO("eService_owner_label")} placeholder={tO("eService_owner_placeholder")} name="owner" error={errors.owner?.message} errorMessage={errors.owner?.message} />
          </div>
          <div className={styles.formDescription}>
            <TextArea control={control} error={errors.description?.message} name="description" size="md" label={tO("eService_description_label")} placeholder={tO("eService_description_placeholder")} />
          </div>
        </div>
        <div className={styles.dividerLine}></div>
        <div className={styles.identityDetails}>
          <div className={styles.domainIconColor}>
            <div className={styles.dropdownDomain}>
              <Select size="lg" label={tO("eService_domain_label")} placeholder={tO("eService_domain_placeholder")} options={dropdownList} alwaysClose name="domain" control={control} error={errors.domain?.message} />
            </div>
            <div className={styles.dropdownIcon}>
              <Select size="lg" label={tO("eService_icon_label")} placeholder={tO("eService_icon_placeholder")} options={dropdownList} alwaysClose name="icon" control={control} error={errors.icon?.message} />
            </div>
            <div className={styles.colorPicker}>
              <InputColor error={errors.color?.message} errorMessage={errors.color?.message} name="color" control={control} label={tO("eService_color_label")} size="md" />
            </div>
          </div>
          <div className={styles.tagInput}>
            <InputTags label={tO("eService_tags_label")} placeholder={tO("eService_tags_placeholder")} control={control} name="tags" size="md" errorMessage={errors.tags?.message} />
          </div>
          <div className={styles.accountContainer}>
            <PubStates status="Publié" value="green" type="color" description={tO("eService_pubStates_publicDescription")} actionText={tO("eService_pubStates_publicActionText")} />
            <PubStates status="Privé" type="icon" value="visibility_off" description={tO("eService_pubStates_privateDescription")} actionText={tO("eService_pubStates_privateActionText")} />
          </div>
        </div>
        <div className={styles.actions}>
          <Button label={tO("eService_button_label")} size="md" color="primary" styling={"solid"} args={{ onClick: handleSubmit(onSubmit) }} />
        </div>
      </div>
    );
  };