import {CardGuichet, Section} from "../../../components/ui";
import {useCallback, useState} from "react";
import {AllGuichetsRequest} from "../../../types";
import {convertToGuichetCard, useGetAllGuichets} from "../../../api";
import {EmptyData} from "../../../../../shared/components";
import {Guichet} from "../../../components/ui/cards/CardComponents/CardGuichet";
import {Card} from "../../../components/ui/cards/Card";
import {CARD_DROPDOWN_ALL, CARD_DROPDOWN_NO_ROLE} from "../../../utils/constants/components.constants.tsx";
export const FavoriteWindowWidget=()=> {
    const [guichetsParams,] = useState<AllGuichetsRequest>({
        numPage: 1,
        pageSize: 5,
        contributeur: false,
        key: '',
        name: '',
        status: '',
        statutRole: '',
        reference: '',
        domainName: '',
        email: '',
        activation: '',
        verification: '',
        tags: '',
        type: '',
        role: '',
        favorite: true,
        industry: '',
        entity: '',
        recent: '',
        Fields: 'ALL'
    });
    const getGuichets = useGetAllGuichets(guichetsParams);
    const menuList=[
        {label:"Voir plus",value:"see_more",icon:<i className="material-flex-icon text-red-400">arrow_outward</i>},
        {label: "Masquer",value:"hide",icon:<i className="material-flex-icon text-red-400">delete</i>},
    ]
    const getDropDownData = useCallback((role: string | undefined) => {
        if (!role) return CARD_DROPDOWN_NO_ROLE;
        return role === 'admin' || role === 'user'
            ? CARD_DROPDOWN_ALL
            : CARD_DROPDOWN_NO_ROLE;
    }, []);
    const renderFavoriteWindowData=()=>{
        if((getGuichets.isFetching || getGuichets.customHandler.isSuccess) && (getGuichets.customHandler.data?.pagination.totalLength!==0)) return (
            <div className="grid grid-cols-3 gap-2 gap-y-4">
            { convertToGuichetCard(getGuichets.customHandler.data?.data).map(
                (window: Guichet, index: number) =>
                    <CardGuichet
                        key={index}
                        guichet={window}
                        dropDownData={getDropDownData(window.role)}
                    />
            )}
            <Card toAdd/>
          </div>)
        return (<div className="flex justify-center h-full items-center">
            <EmptyData title="Aucun guichet pour l'instant" description="La liste des guichets sera affichée ici"  searchIcon />
             </div>)
    }
    return (
        <Section title="Guichet favoris / fréquemment visités" materialIconLabel="corporate_fare" className="h-full" menuList={menuList}>
                {renderFavoriteWindowData()}
        </Section>
    )
}
