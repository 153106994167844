import { FC } from 'react';
import { BaseSVGIconProps } from '../../types/components.type';
import AbstractSVGIcon from './AbstractSVGIcon';
const VerticalDeviderSvg: FC<BaseSVGIconProps> = (props) => (
  <AbstractSVGIcon
    size={props.size}
    className={props.className}
    spin={props.spin}
    hidden={props.hidden}
    viewBox="0 0 1 24">
    <path d="M0 0.5C0 0.223857 0.223858 0 0.5 0C0.776142 0 1 0.223858 1 0.5V23.5C1 23.7761 0.776142 24 0.5 24C0.223858 24 0 23.7761 0 23.5V0.5Z"/>

  </AbstractSVGIcon>
);
export default VerticalDeviderSvg;
