import { ApiJaxRsResponse } from '../../../../shared/types';

export const ALL_WINDOW_API_PATH: ApiJaxRsResponse = {
  path: '/karazal/ow-kls-extrat/appmanager/allGuichets',
  apiKey: 'AB90G-BH903-W4EE1-Z66Q9-907E6'
};

export const ADD_FAVORITE_WINDOW_API_PATH: ApiJaxRsResponse = {
  path: '/karazal/ow-kls-extrat/appmanager/addFavoriteGuichetUser',
  apiKey: 'AB90G-BH903-W4EE1-Z66Q9-907E6'
};

export const SUBMIT_WINDOW_CREATION_API_PATH:ApiJaxRsResponse ={
  path:"/karazal/ow-kls-extrat/appmanager/createGuichet",
  apiKey: "AB90G-BH903-W4EE1-Z66Q9-907E6",
}

export const JOIN_WINDOW_API_PATH:ApiJaxRsResponse ={
  path:"/karazal/ow-kls-extrat/appmanager/",
  apiKey: "AB90G-BH903-W4EE1-Z66Q9-907E6",
}

