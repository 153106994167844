import { FC } from 'react';
import { BaseSVGIconProps } from '../../types/components.type';
import AbstractSVGIcon from './AbstractSVGIcon';
const LocalisationSvg: FC<BaseSVGIconProps> = (props) => (
  <AbstractSVGIcon
    size={props.size}
    className={props.className}
    spin={props.spin}
    hidden={props.hidden}
    viewBox="0 0 12 14">
   <path d="M6 7.00001C5.26667 7.00001 4.66667 6.40001 4.66667 5.66668C4.66667 4.93334 5.26667 4.33334 6 4.33334C6.73333 4.33334 7.33333 4.93334 7.33333 5.66668C7.33333 6.40001 6.73333 7.00001 6 7.00001ZM10 5.80001C10 3.38001 8.23333 1.66668 6 1.66668C3.76667 1.66668 2 3.38001 2 5.80001C2 7.36001 3.3 9.42668 6 11.8933C8.7 9.42668 10 7.36001 10 5.80001ZM6 0.333344C8.8 0.333344 11.3333 2.48001 11.3333 5.80001C11.3333 8.01334 9.55333 10.6333 6 13.6667C2.44667 10.6333 0.666667 8.01334 0.666667 5.80001C0.666667 2.48001 3.2 0.333344 6 0.333344Z"/>
   </AbstractSVGIcon>
);
export default LocalisationSvg;
