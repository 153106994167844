import { DynamicListData } from '../../../../shared/types';
import { Guichet } from '../../components/ui/cards/base-cards/CardGuichet';
import { AllGuichetsResponse } from '../../types';

export const convertToGuichetCard = (
  guichets: AllGuichetsResponse[] | undefined
): Guichet[] => {
  if (!guichets) return [];
  return guichets
    .filter((guichet) => guichet.guichetId !== '')
    .map((guichet: AllGuichetsResponse) => {
      const randomLat = (Math.random() * (33.2 - 30.65) + 30.65).toFixed(6); // Latitude range for Morocco (27 to 36)
      const randomLng = (Math.random() * (-3.8 - -8.51) + -8.51).toFixed(6); // Longitude range for Morocco (-13 to -1)

      return {
        id: guichet.guichetId,
        name: guichet.name,
        industry: guichet.industry || '',
        role: guichet.role || '',
        status: guichet.status || 'N/A',
        verification: guichet.verification || '',
        favorite: guichet.favorite || false,
        typeGuichet:guichet.typeGuichet,
        imageUrl:
          guichet.logoGedId && guichet.logoGedId !== ''
            ? `/karazal/DownloadFile?gedId=${guichet.logoGedId}`
            : '/pictures/empty-picture.png',
        changeTime: guichet.changeTime || '',
        lat: guichet.latitude
          ? parseFloat(guichet.latitude)
          : parseFloat(randomLat),
        lng: guichet.longitude
          ? parseFloat(guichet.longitude)
          : parseFloat(randomLng)
      };
    });
};
export const convertToItemDropDown = (
  organizations: AllGuichetsResponse[] | undefined
): DynamicListData[] => {
  if (!organizations) return [];
  return organizations
    .filter((organization) => organization.guichetId !== '')
    .map((organization: AllGuichetsResponse) => {
      return {
        label: organization.name,
        value: organization.name,
        icon: 'corporate_fare'
      };
    });
};
