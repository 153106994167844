import styles from './badge-logo.module.css'
import classNames from "classnames";
import {FC} from "react"
    ;
interface BadgeLogoProps{
    backgroundColor:string
    logo:string,
    label:string,
    obtained?:boolean
}
export const BadgeLogo:FC<BadgeLogoProps>=(props)=>{
    const {obtained=false} =props
    const rootStyle=classNames({
        [styles.rootContainer]:true,
    })
    const logoContainerStyle=classNames({
        [styles.logoContainer]:true,
        ["opacity-40"]:obtained
    })
    return (
        <div className={rootStyle}>
         <div className={logoContainerStyle} style={{backgroundColor:props.backgroundColor}}>
               <img className="absolute w-24" src={props.logo}/>
         </div>
          <span className={styles.label}>{props.label}</span>
      </div>)
}