import { SidebarMenuItemProps } from './SidebarMenuItem';
import { TopMenuItemProps } from './TopMenuItem';
import { SwitcherItemProps } from './SwitcherItem';
import { useHandleTranslation } from '../../../../shared/hooks';

export const useMenuItems = (
  selectedRoute: string,
  organisationId: string
): SidebarMenuItemProps[] => {
  const { t: tSm } = useHandleTranslation('menu');

  return [
    {
      label: tSm('menu.dashboard'),
      materialIconLabel: "dashboard",
      link: `${selectedRoute}/${organisationId}`,
      selectedItem: '',
      handleClick: () => {}
    },
    {
      label: tSm('menu.identity'),
      materialIconLabel: "verified",
      link: `${selectedRoute}/${organisationId}/identity`,
      selectedItem: '',
      handleClick: () => {}
    },
    {
      label: tSm('menu.eservices'),
      materialIconLabel: "apps",
      link: `${selectedRoute}/${organisationId}/eservices`,
      blockEnd: true,
      selectedItem: '',
      handleClick: () => {}
    },
    {
      label: tSm('menu.offre'),
      materialIconLabel: "local_grocery_store",
      link: `${selectedRoute}/${organisationId}/offre`,
      selectedItem: '',
      handleClick: () => {}
    },
    {
      label: tSm('menu.parametres'),
      materialIconLabel: "tune",
      link: `${selectedRoute}/${organisationId}/parameters`,
      selectedItem: '',
      handleClick: () => {}
    },
    {
      label: tSm('menu.monitoring'),
      materialIconLabel: "multiline_chart",
      link: `${selectedRoute}/${organisationId}/monitoring`,
      selectedItem: '',
      handleClick: () => {}
    },
    {
      label: tSm('menu.securite'),
      materialIconLabel: "security",
      link: `${selectedRoute}/${organisationId}/security`,
      blockEnd: true,
      selectedItem: '',
      handleClick: () => {}
    },
    {
      label: tSm('menu.contributeurs'),
      materialIconLabel: "manage_accounts",
      link: `${selectedRoute}/${organisationId}/contributors`,
      selectedItem: '',
      handleClick: () => {}
    },
    {
      label: tSm('menu.utilisateurs'),
      materialIconLabel: "group",
      link: `${selectedRoute}/${organisationId}/users`,
      selectedItem: '',
      handleClick: () => {}
    },
    {
      label: tSm('menu.groupes'),
      materialIconLabel: "group",
      link: `${selectedRoute}/${organisationId}/groups`,
      blockEnd: true,
      selectedItem: '',
      handleClick: () => {}
    },
    {
      label: tSm('menu.developer_zone'),
      materialIconLabel: "code",
      link: `${selectedRoute}/${organisationId}/devzone`,
      selectedItem: '',
      handleClick: () => {}
    },
    {
      label: tSm('menu.market_place'),
      materialIconLabel: "interests",
      link: `${selectedRoute}/${organisationId}/marketplace`,
      selectedItem: '',
      handleClick: () => {}
    },
    {
      label: tSm('menu.communaute'),
      materialIconLabel: "person_search",
      link: `${selectedRoute}/${organisationId}/communaute`,
      blockEnd: true,
      selectedItem: '',
      handleClick: () => {}
    },
    {
      label: tSm('menu.suppression_organisation'),
      materialIconLabel: "remove_circle_outline",
      link: `${selectedRoute}/${organisationId}/deleteorganisation`,
      isForDelete: true,
      selectedItem: '',
      handleClick: () => {}
    }
  ];
};

export const useTopMenuItems = (selectedRoute: string): TopMenuItemProps[] => {
  const { t: tTm } = useHandleTranslation('menu');

  return [
    {
      label: tTm('top_menu.accueil'),
      link: `${selectedRoute}`,
      selectedItem: '',
      materialIconLabel: "home",
      handleClick: () => {}
    },
    {
      label: tTm('top_menu.guichets'),
      link: `${selectedRoute}/guichets`,
      selectedItem: '',
      materialIconLabel:"corporate_fare",
      handleClick: () => {}
    },
    {
      label: tTm('top_menu.catalogue'),
      link: `${selectedRoute}/catalogue`,
      selectedItem: '',
      materialIconLabel:"grid_view",
      handleClick: () => {}
    },
    {
      label: tTm('top_menu.offre'),
      link: `${selectedRoute}/offre`,
      selectedItem: '',
      materialIconLabel: "local_grocery_store",
      handleClick: () => {}
    },
    {
      label: tTm('top_menu.academy'),
      link: `${selectedRoute}/academy`,
      selectedItem: '',
      materialIconLabel:"school",
      handleClick: () => {}
    },
    {
      label: tTm('top_menu.support'),
      link: `${selectedRoute}/support`,
      selectedItem: '',
      materialIconLabel:"help_outline",
      handleClick: () => {}
    }
  ];
};

export const useSwitcherItems = (): SwitcherItemProps[] => {
  const { t: tSw } = useHandleTranslation('menu');

  return [
    {
      label: tSw('switcher.window'),
      link: 'guichet',
      selectedItem: '',
      materialIconLabel: "business",
      handleClick: () => {}
    },
    {
      label: tSw('switcher.user'),
      link: 'utilisateur',
      selectedItem: '',
      materialIconLabel:"person_outline",
      handleClick: () => {}
    }
  ];
};
