import HomeSvg from '../../../shared/components/icons/HomeSvg';
import { FilterBarHeaderProps } from '../components/ui/filter-bar/FilterBarHeader';
import { FilterBarSortByProps } from '../components/ui/filter-bar/FilterBarSortBy';

export const fBh: FilterBarHeaderProps = {
  hasChilds: false,
  icon: HomeSvg,
  parent: 'Guichets'
};
export const fBhEsrv: FilterBarHeaderProps = {
  hasChilds: true,
  icon: HomeSvg,
  parent: 'Guichets',
  childs:['e-Services']
};
export const fBhEsrvParam: FilterBarHeaderProps = {
  hasChilds: true,
  icon: HomeSvg,
  parent: 'E-services',
  childs:['Gestion des projets']
};
export const fBhCat: FilterBarHeaderProps = {
  hasChilds: true,
  icon: HomeSvg,
  parent: 'Catalogue',
};
export const fBs: FilterBarSortByProps = {
  items: [
    {
      data: [
        {
          label: 'Vérifiée', 
          value: 'Vérifiée'
        },
        {
          label: 'Non vérifiée',
          value: 'Non vérifiée'
        }
      ],
      label: 'Domaine',
      placeHolder: 'Domaine',
      selectedValue: ''
    },
    {
      data: [
        {
          label: 'Vérifiée', 
          value: 'Vérifiée'
        },
        {
          label: 'Non vérifiée',
          value: 'Non vérifiée'
        }
      ],
      label: 'Vérification Guichet',
      placeHolder: 'Vérification Guichet',
      selectedValue: ''
    },
    {
      data: [
        { label: 'Propriétaire', value: 'Owner' },
        { label: 'Administrateur', value: 'Administrator' },
        { label: 'Développeur citoyen', value: 'Citizen Developer' },
        { label: 'Utilisateur', value: 'User' }
      ],
      label: 'Rôle',
      placeHolder: 'Rôle',
      selectedValue: ''
    },
    {
      data: [
        {
          label: 'Validé',
          value: 'Validé'
        },
        {
          label: 'Non Validé',
          value: 'Non Validé'
        }
      ],
      label: 'Statut Rôle',
      placeHolder: 'Statut Rôle',
      selectedValue: ''
    }
  ]
};
export const fBsEserv: FilterBarSortByProps = {
  items: [
    {
      data: [
        {
          label: '', 
          value: ''
        },
        
      ],
      label: 'Guichet',
      placeHolder: 'Guichet',
      selectedValue: ''
    },
    {
      data: [
        {
          label: '', 
          value: ''
        },
       
      ],
      label: 'Catégorie(s)',
      placeHolder: 'Catégorie(s)',
      selectedValue: ''
    }, {
      data: [
        {
          label: '',
          value: ''
        },
       
      ],
      label: 'Statut',
      placeHolder: 'Statut',
      selectedValue: ''
    },
    {
      data: [
        { label: '', value: '' },
       
      ],
      label: 'Visibilité',
      placeHolder: 'Visibilité',
      selectedValue: ''
    }
   
  ]
};
export const fBMap: FilterBarSortByProps = {
  items: [
    {
      data: [
        {
          label: 'Vérifiée', 
          value: 'Vérifiée'
        },
        {
          label: 'Non vérifiée',
          value: 'Non vérifiée'
        }
      ],
      label: 'Catégorie(s)',
      placeHolder: 'Catégorie',
      selectedValue: ''
    },
    {
      data: [
        {
          label: 'Vérifiée', 
          value: 'Vérifiée'
        },
        {
          label: 'Non vérifiée',
          value: 'Non vérifiée'
        }
      ],
      label: 'Domaine(s)',
      placeHolder: 'Domaine',
      selectedValue: ''
    },
    {
      data: [
        { label: 'Propriétaire', value: 'Owner' },
        { label: 'Administrateur', value: 'Administrator' },
        { label: 'Développeur citoyen', value: 'Citizen Developer' },
        { label: 'Utilisateur', value: 'User' }
      ],
      label: 'Préfecture/province',
      placeHolder: 'Préfecture/province',
      selectedValue: ''
    },
    {
      data: [
        {
          label: 'Validé',
          value: 'Validé'
        },
        {
          label: 'Non Validé',
          value: 'Non Validé'
        }
      ],
      label: 'Arrondissement/commune',
      placeHolder: 'Arrondissement/commune',
      selectedValue: ''
    }
  ]
};

