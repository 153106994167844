import {GenericResponse} from "../../../../shared/types";
import {fetchGenericJaxRsRequestFromFormData} from "../../../../shared/utils/helpers";

import {HttpMethods} from "../../../../shared/utils/enums";
import {
    AllGuichetsRequest,
    AllGuichetsResponse,
    IdentityCreateOrganizationRequest,
    AssociateOrganizationRequest,
    IdentityOrganizationStatusResponse,
    WindowOrganizationRequestsRequest,
    WindowOrganizationRequestsResponse,
    OrganizationByWindowResponse
} from "../../types";
import {mapCreateOrganizationRequest} from "./formatter";
import {
    ALL_ORGANIZATIONS_API_PATH,
    CANCEL_JOINING_ORGANIZATION_REQUEST_API_PATH,
    CREATE_ORGANIZATION_API_PATH,
    GET_ORGANIZATIONS_BY_WINDOW_API_PATH,
    GET_WINDOW_ORGANIZATION_ASSOCIATION_REQUESTS_API_PATH,
    GET_WINDOW_ORGANIZATION_STATUS_API_PATH,
    REQUEST_ASSOCIATE_ORGANIZATION_API_PATH
} from "./apiPath";
import {PageResponse} from "../../../../shared/types/api.type";

 export const postCreateOrganization=(data:IdentityCreateOrganizationRequest):Promise<GenericResponse<null>> =>{
     return  fetchGenericJaxRsRequestFromFormData(mapCreateOrganizationRequest(data),CREATE_ORGANIZATION_API_PATH,HttpMethods.POST);
}
export const getAllOrganizations = (data: AllGuichetsRequest): Promise<GenericResponse<PageResponse<AllGuichetsResponse[]>>> => {
    return fetchGenericJaxRsRequestFromFormData(data, ALL_ORGANIZATIONS_API_PATH, HttpMethods.POST
    );}
export const getOrganizationByWindow= (data:{guichetId:string|undefined}): Promise<GenericResponse<OrganizationByWindowResponse>> => {
    return fetchGenericJaxRsRequestFromFormData(data, GET_ORGANIZATIONS_BY_WINDOW_API_PATH, HttpMethods.POST);
 }
export const getWindowOrganizationStatus=(data:{guichetId:string|undefined}):Promise<GenericResponse<IdentityOrganizationStatusResponse|null>> =>{
    return fetchGenericJaxRsRequestFromFormData(data,GET_WINDOW_ORGANIZATION_STATUS_API_PATH, HttpMethods.POST)
}
export const requestAssociateOrganization = (data: AssociateOrganizationRequest): Promise<GenericResponse<PageResponse<AllGuichetsResponse[]>>> => {
        return fetchGenericJaxRsRequestFromFormData(data,REQUEST_ASSOCIATE_ORGANIZATION_API_PATH, HttpMethods.POST);
}
export const cancelJoiningOrganization=(data:{guichetId:string,organisationId:string,joindemandeId:string})=>{
    return fetchGenericJaxRsRequestFromFormData(data,CANCEL_JOINING_ORGANIZATION_REQUEST_API_PATH,HttpMethods.POST);
}

export const getWindowOrganizationAssociationRequest=(data:WindowOrganizationRequestsRequest):Promise<GenericResponse<PageResponse<WindowOrganizationRequestsResponse[]>>> =>{
    return fetchGenericJaxRsRequestFromFormData(
        data,
        GET_WINDOW_ORGANIZATION_ASSOCIATION_REQUESTS_API_PATH,
        HttpMethods.POST
    );
}