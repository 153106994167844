import { forwardRef, ForwardRefRenderFunction, ReactNode} from "react";
import  styles from './style.module.css'
interface SwiperItemProps{
    maxItems:number
    children:ReactNode
}

const SwiperItem:ForwardRefRenderFunction<HTMLDivElement,SwiperItemProps>=(props,ref)=>{
    const SwiperSlideStyles = {flex: `0 0 calc(100% /${props.maxItems} )`};
    return (
    <div className={styles.swiperItem} style={SwiperSlideStyles} ref={ref}>
        {props.children}
    </div>
    )
}
export default forwardRef(SwiperItem)
