import styles from './landing.module.css'
import logo from '../../../../shared/assets/icons/ksw-logo.svg';
import { Button } from 'kls-ui';
import { MaterialIcon } from '../../../../shared/components/icons';
import {  useState } from 'react';
import { useNavigate } from 'react-router-dom';

const NavbarComponent = () => {
    const [theme, setTheme] = useState(
        localStorage.getItem('data-theme') !== null
          ? localStorage.getItem('data-theme')
          : 'light'
      );
      const navigate = useNavigate();
      const toggleTheme = () => {
        const newTheme = (theme== 'light' ? 'dark' : 'light');
        setTheme(newTheme);
        document.documentElement.setAttribute('data-theme', newTheme);
        localStorage.setItem('data-theme', newTheme);
      };
  
  return (
    <nav className={ `dark:bg-gray-900 ${styles.navbar}`}>
      <div className={styles.navbarLeft}>
        <img src={logo} alt="Logo" className={styles.navbarLogo}/>
        <select className={styles.navbarSelect}>
          <option value="option1">Karaz</option>
        
        </select>
        <select className={styles.navbarSelect}>
          <option value="option1">Services</option>
      
        </select>
        <select className={styles.navbarSelect}>
          <option value="option1">Support</option>
        
        </select>
        <select className={styles.navbarSelect}>
          <option value="option1">Espace partenaire</option>
      
        </select>
      </div>

      <div className={styles.navbarRight}>
                <Button size={'md'} color={"primary"} styling={"solid"}  label="Connexion" args={{onClick:()=>navigate("/authentication")}}/>
                <Button size={'md'} color={"primary"} styling={"outline"}  label="S'inscrire" args={{onClick:()=>navigate("/signup")}}/>
                <MaterialIcon label={theme == 'light' ? 'dark_mode' : 'light_mode'} className={styles.navIcons} onClick={()=>{toggleTheme()}}></MaterialIcon>

                <MaterialIcon label={'language'} className={styles.navIcons} ></MaterialIcon>
      </div>
    </nav>
  );
};

export default NavbarComponent;