import {UserProfile} from "../../types";

export const saveUserProfile = (profile: UserProfile): void => {
    localStorage.setItem('USER_PROFILE', JSON.stringify(profile));
};

export const getUserProfile = (): UserProfile | undefined => {
    const profile = localStorage.getItem('USER_PROFILE');
    return profile ? JSON.parse(profile) : undefined;
};

export const clearUserProfile = (): void => {
    localStorage.removeItem('USER_PROFILE');
};

export function setCookiesUser(cookieString: string ) {
    const cookies = cookieString.split(';').map(cookie => cookie.trim());
    cookies.forEach(cookie => {
        const [name, value] = cookie.split(/=(.*)/s,2);
        if (name && value) {
            document.cookie = `${name}=${value}; path=/`;
        }
    });
}
export function getCookiesUser(): Record<string, string> {
    const cookies: Record<string, string> = {};
    document.cookie.split(';').forEach(cookie => {
        const [name, value] = cookie.split(/=(.*)/s, 2).map(c => c.trim());
        if (name && value) {
            cookies[name] = value;
        }
    });
    return cookies;
}
export  const clearCookiesUser = (): void => {
    const cookiesToClear = ['e3eCallBackward', 'e2eCallForward', 'JSESSIONID'];
    cookiesToClear.forEach(cookieName => {
        document.cookie = `${cookieName}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
    });
}