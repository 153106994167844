import { ApiRemoteAction } from '../../../../shared/types';
import { REMOTE_ACTION_BASE_PATH } from '../../../../shared/utils/constants';


export const LOGIN_KARAZ_ACTION = 'karazapps/karaz/kls/creationcompte/signup/action/UserBacker';

export const LOGIN_PATH: ApiRemoteAction = {
  path: REMOTE_ACTION_BASE_PATH,
  karazAction: LOGIN_KARAZ_ACTION,
  operation: 'doubleAuthentification'
};

export const LOGIN_ACCOUNT_ACTIVATION_PATH: ApiRemoteAction = {
  path: REMOTE_ACTION_BASE_PATH,
  karazAction: LOGIN_KARAZ_ACTION,
  operation: 'verifyOTP'
};

export const LOGIN_RESEND_CODE_PATH: ApiRemoteAction = {
  path: REMOTE_ACTION_BASE_PATH,
  karazAction: LOGIN_KARAZ_ACTION,
  operation: 'resendSMSOTP'
};
