export const enum HttpMethods  {
    GET= 'GET',
    POST='POST',
    DELETE= 'DELETE',
    PUT='PUT',
    PATCH= 'PATCH',
};
export const enum HttpRequestFormat {
    FORM_DATA="FORM_DATA",
    JSON="JSON",
}