
import {OuterContainer,} from "../../../../../shared/components";
import {OrgCreationStep, QAStep, SpaceConfigStep} from "../../../components/ui";
import {
    useCreateWindow,
    useGetOnboardingQuestions,
    usePostOnboardingQA
} from "../../../api";
import {OnboardingGuichetCreationRequest, OnboardingQARequest} from "../../../types";
import {useEffect, useState} from "react";
import classNames from "classnames";
import {OnboardingSteps} from "../../../utils/enums";
import {useGetIndustriesList} from '../../../api'
import {useNavigate} from "react-router-dom";
import {useHandleTranslation} from "../../../../../shared/hooks";
import {getUserProfile, saveUserProfile} from "../../../../../shared/utils/helpers";
import {UserProfile} from "../../../../../shared/types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../shared/stores/store";
import { setOnboardingStatus } from "../../../../auth/features/auth/authSlice";
import {USER_ROUTE_LOCATION_PATHNAME, WINDOW_ROUTE_LOCATION_PATHNAME} from "../../../utils/constants";


export const OnboardingPage=()=> {
    const [currentStep,setCurrentStep]=useState<OnboardingSteps>(OnboardingSteps.QA);
    const navigate=useNavigate();
    const getOnboardingQuestions=useGetOnboardingQuestions();
    const getIndustriesList =useGetIndustriesList();
    const postOnboardingQA=usePostOnboardingQA();
    const postCreateOrganization=useCreateWindow();
    const { t:tW } = useHandleTranslation("window");
    const dispatch = useDispatch();
    const { hasAccomplishedOnboarding } = useSelector(
        (state: RootState) => state.auth
    );
    const handleSetOnboardingStatus = (value: boolean) => {
        dispatch(setOnboardingStatus(value)); // Dispatch an action to set authenticated
    };
    const handleSubmitQAStep=(data:OnboardingQARequest)=>{
        postOnboardingQA.mutate(data);
    }
    const handleSubmitGuichetCreationStep=(data:OnboardingGuichetCreationRequest)=>{
        postCreateOrganization.mutate(data);
    }
    const onboardingStyle=(step:OnboardingSteps)=>classNames({
        ['show transition-all duration-300']: currentStep === step,
        ['notDisplayV2']: currentStep !== step
    })
    useEffect(() => {
        if(postOnboardingQA.customHandler.isSuccess) {
            if (!hasAccomplishedOnboarding){
                handleSetOnboardingStatus(true);
                const userProfile:UserProfile | undefined = getUserProfile();
                if(userProfile)  saveUserProfile( {...userProfile,onboardingAccomplished:"true"});
            }
            setCurrentStep(OnboardingSteps.WORK_SPACE_CONFIG);
        }
    }, [postOnboardingQA.customHandler.isSuccess]);

   const handleValidateSpaceConfigStep=(step:OnboardingSteps|null|undefined)=>{
       if (step===OnboardingSteps.GUICHET_CREATION) setCurrentStep(step);
       if (step===null) navigate(`/${USER_ROUTE_LOCATION_PATHNAME}`);
   }
    useEffect(() => {
       if (postCreateOrganization.customHandler.isSuccess){
           navigate(`/${WINDOW_ROUTE_LOCATION_PATHNAME}`);
       }
    }, [postCreateOrganization.customHandler.isSuccess]);
return (
    <OuterContainer>
        <QAStep
            data={getOnboardingQuestions.customHandler.data}
            rootClassName={onboardingStyle(OnboardingSteps.QA)}
            onSubmit={handleSubmitQAStep}
            isLoading={{onboardingQuestions:getOnboardingQuestions.isFetching,postAnswers:postOnboardingQA.isPending}}
            isError={{onboardingQuestions:getOnboardingQuestions.customHandler.isError,postAnswers:postOnboardingQA.customHandler.isError}}
            submitError={postOnboardingQA.customHandler.errorMessage}
        />
        <SpaceConfigStep
            onValidate={handleValidateSpaceConfigStep}
            rootClassName={onboardingStyle(OnboardingSteps.WORK_SPACE_CONFIG)}
            onBack={()=>setCurrentStep(OnboardingSteps.QA)}/>
        <OrgCreationStep
            data={{industriesList:getIndustriesList.customHandler.data}}
            isLoading={{postCreateOrganization:postCreateOrganization.isPending,industriesList:getIndustriesList.isLoading}}
            rootClassName={onboardingStyle(OnboardingSteps.GUICHET_CREATION)}
            backButton={{label:tW("orgCreation.onboarding.back_button"),onClick:()=>setCurrentStep(OnboardingSteps.WORK_SPACE_CONFIG)}}
            submitError={postCreateOrganization.customHandler.errorMessage}
            onSubmit={handleSubmitGuichetCreationStep} />
        {/*<OrgJoiningStep*/}
        {/*    rootClassName={onboardingStyle(OnboardingSteps.GUICHET_JOINING)}*/}
        {/*    onBack={()=>setCurrentStep(OnboardingSteps.GUICHET_CREATION)}/>*/}
    </OuterContainer>
)
}





