import { ChangeEvent, FocusEvent, useEffect, ForwardRefRenderFunction, forwardRef } from 'react';
import {useState } from 'react';
import styles from './inputPassword.module.css'
import classNames from 'classnames';
import { PasswordStrength } from '../../../utils/enums';
import { InputPasswordProps } from '../../../types';
import {InputText} from "../input-text/InputText";



const ForwardedInputPassword:ForwardRefRenderFunction<HTMLInputElement,InputPasswordProps>=({withProgress=false,errorMessage,...props},ref)=>{
const [visiblePassword,setIsPasswordVisible]=useState<boolean>(false);
const [isProgressBarDisplayed,setDisplayedProgressBar]=useState<boolean>(false);
const [passwordStrength,setPasswordStrength]=useState<PasswordStrength |null >(null)
const progressStyles=classNames({
     [styles.progressBar]:true,
     [styles.progressBarSuccess]:passwordStrength===PasswordStrength.STRONG,
     [styles.progressBarWarning]:passwordStrength===PasswordStrength.MODERATE,
     [styles.progressBarDanger]:passwordStrength===PasswordStrength.WEAK,
   })

  const overrideBlur=(event: FocusEvent<HTMLInputElement>)=>{
  if(withProgress) setDisplayedProgressBar(false) ;
  if(props.onBlur)  props.onBlur(event);
  }
  const overrideFocus=(event: FocusEvent<HTMLInputElement>)=>{
    if(withProgress && event.target.value.length !==0 ) setDisplayedProgressBar(true) ;
    if(props.onFocus) props.onFocus(event);
  }

  const overrideChange=(event:ChangeEvent<HTMLInputElement>)=>{
   if (event.target.value.length===0) setDisplayedProgressBar(false);
   else if(!isProgressBarDisplayed) setDisplayedProgressBar(true);
   if (withProgress){
     if (errorMessage && (errorMessage in PasswordStrength)) setPasswordStrength(PasswordStrength[errorMessage as keyof typeof PasswordStrength])
     else if (props.validPasswordStrength ) setPasswordStrength(props.validPasswordStrength(event.target.value))
   }
     if(props.onChange) props.onChange(event);
  }

  useEffect(() => {
    if (withProgress && !passwordStrength  &&  errorMessage && (errorMessage in PasswordStrength)) setPasswordStrength(PasswordStrength[errorMessage as keyof typeof PasswordStrength]);
  }, [errorMessage]);

const errorMessageRenderer=():object=>{
    if (!withProgress) return {errorMessage}
    if (withProgress && !isProgressBarDisplayed && errorMessage && !(errorMessage in PasswordStrength)) return {errorMessage}
    if (withProgress &&  !isProgressBarDisplayed ) {
    if (passwordStrength && errorMessage && props.passwordHints) return { errorMessage: props.passwordHints[passwordStrength].errorMessage }
    if (!passwordStrength && errorMessage && !(errorMessage in PasswordStrength)) return { errorMessage }
    }
    return {}
}
const renderPasswordIcon=()=> {
    const sharedStyle="material-flex-icon cursor-pointer text-md text-slate-400";
    if (visiblePassword) return <i className={sharedStyle} onClick={()=>setIsPasswordVisible(false)}>remove_red_eye</i>
    return <i className={sharedStyle} onClick={()=>setIsPasswordVisible(true)}>visibility_off</i>
}
const renderPasswordVisibility=()=>{
    if (visiblePassword) return "text";
    return "password";
}
    return (<div>
        <InputText
            ref={ref}
            {...props}
            {...errorMessageRenderer()}
       onBlur={overrideBlur}
       onFocus={overrideFocus}
       onChange={overrideChange}
       type={renderPasswordVisibility()}
       icon={renderPasswordIcon()}
       withError={props.withError && Object.keys(errorMessageRenderer()).length!==0}
    />
    <div className={styles.fixedProgressBar}>
    {withProgress && passwordStrength && isProgressBarDisplayed && props.passwordHints &&
      <div className={styles.progressContainer}>
        <div className={styles.progress}>
          <div className={progressStyles} role="progress-bar"/>
        </div>
        <div className="flex gap-2">
             <span className={styles.hintTitle}>{props.passwordHints[passwordStrength].title}</span>
             <span className={styles.hintDescription}>{props.passwordHints[passwordStrength].description}</span>
       </div>
  </div>}
    </div>
  </div>)
    }

export const  InputPassword=forwardRef(ForwardedInputPassword);