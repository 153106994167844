import {CardEservice , Section} from "../../../components/ui";
import {useCallback, useState} from "react";
import {ListEserviceRequest} from "../../../types/data.types";
import {useGetEserviceList} from "../../../api";
import {CARD_DROPDOWN_ALL, CARD_DROPDOWN_NO_ROLE} from "../../../utils/constants/components.constants";
import {Guichet} from "../../../components/ui/cards/base-cards/CardGuichet";
import {Card} from "../../../components/ui/cards/Card";
import {EmptyData} from "../../../../../shared/components";
import {Eservice} from "../../../components/ui/cards/base-cards/CardEservice";
import {convertToEserviceCard} from "../../../api/eservice/formatteur";

export const FavoriteEserviceWidget=()=>{
    const menuList=[
        {label:"Voir plus",value:"see_more",icon:<i className="material-flex-icon text-red-400">arrow_outward</i>},
        {label: "Masquer",value:"hide",icon:<i className="material-flex-icon text-red-400">delete</i>},
    ]
    const [allEservicesParams, ] =
        useState<ListEserviceRequest>({
            numPage: 1,
            pageSize: 5,
            contributeur: false,
            status: '',
            reference: '',
            favorite: true,
            industry: '',
            entity: '',
            guichetName:'',
            idGuichet:'',
            Fields: 'ALL'
        });
    const getEserviceList = useGetEserviceList(allEservicesParams);
    const getDropDownData = useCallback((role: string | undefined) => {
        if (!role) return CARD_DROPDOWN_NO_ROLE;
        return role === 'admin' || role === 'user'
            ? CARD_DROPDOWN_ALL
            : CARD_DROPDOWN_NO_ROLE;
    }, []);

    const renderFavoriteWindowData=()=>{
        if((getEserviceList.isFetching || getEserviceList.customHandler.isSuccess) && (getEserviceList.customHandler.data?.pagination.totalLength!==0)) return (
            <div className="grid grid-cols-2 gap-2 gap-y-2">
                { convertToEserviceCard(getEserviceList.customHandler.data?.data).map(
                    (eservice: Guichet, index: number) =>
                        <CardEservice
                            key={index}
                            eservice={eservice as Eservice}
                            dropDownData={getDropDownData(eservice.role)}
                            cardAction={{
                                onSelectDropDown: (selection) => onSelectDropDown(selection)
                            }}
                        />

                )}
                <Card toAdd/>
            </div>)
        return (<div className="flex justify-center h-full items-center">
            <EmptyData title="Aucun e-service pour l'instant" description="La liste des e-services sera affichée ici"  searchIcon />
        </div>)
    }
    return (
        <Section title="e-Services favoris / fréquemment visités" materialIconLabel="grid_view" className="h-full" menuList={menuList}>
            {renderFavoriteWindowData()}
        </Section>
    )
}