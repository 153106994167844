import { FC } from 'react';
import { BaseSVGIconProps } from '../../../../../shared/types';
import ArrowChildSvg from '../../../../../shared/components/icons/ArrowChildSvg';
import { IconSize } from '../../../../../shared/utils/enums';
import styles from './filterBar.module.css';
export interface FilterBarHeaderProps {
  hasChilds: boolean;
  parent: string;
  childs?: string[];
  icon: FC<BaseSVGIconProps>;
}

export const FilterBarHeader: FC<FilterBarHeaderProps> = (props) => {
  return (
    <div className={styles.filterHeader}>
      <props.icon
        size={IconSize.SM}
        className={styles.filterHeaderHomeIcon}></props.icon>
      <ArrowChildSvg
        size={IconSize.SM}
        className={styles.filterHeaderArrowIcon}
      />
      <span>{props.parent}</span>
      {props.hasChilds &&
        props.childs?.map((child: string, index: number) => (
          <div key={index} className={styles.filterHeader}>
            <ArrowChildSvg
              size={IconSize.SM}
              className={styles.filterHeaderArrowIcon}
            />
            <span>{child}</span>
          </div>
        ))}
    </div>
  );
};
