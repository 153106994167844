import { Button } from "kls-ui";
import { CardGrid } from "../../../portal/components/ui/card-grid/CardGrid";
import { ESERVICEs_MOCK_DATA } from "../../../portal/mockdata/MockDataEservices";
import styles from './landing.module.css'

export const ServiceDigital = ()=>{

    return(<div className={`${styles.serviceContainer} flex flex-col items-center gap-7`}>
        <span className={styles.serviceTitle}>Services Digitaux en Vedette</span>
        <span className={styles.serviceText}>Accédez aux services Digitaux les plus demandées et simplifiez votre transition numérique  </span>
        <div  className="w-10/12">
        <CardGrid
                            currentPage={0}
                            totalPages={1}
                            fetchMoreData={() => {
                            }}
                            items={ESERVICEs_MOCK_DATA}
                            type="eservice"
                            onSelectDropDown={()=>{}}
                        />
                        </div>
        <Button size={'md'} color={"primary"} styling={"solid"} label="Explorez le référentiel e-services" args={{}}/>
            
    </div>);
}