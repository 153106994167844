import {ConfigProvider} from "antd";
import {FC, ReactNode} from "react";
import {KSW_ANTD_THEME} from "../utils/constants";

interface  KSWAntdProviderProps{
    children:ReactNode
}
export const KSWAntdProvider:FC<KSWAntdProviderProps> = (props)=>{

    return <ConfigProvider theme={KSW_ANTD_THEME} >
        {props.children}
    </ConfigProvider>
}