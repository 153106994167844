import { FC } from 'react';
import Arrow from '../../../../shared/components/icons/ArrowSvg';
import { Organisation } from '../../../../shared/types/components.type';
import styles from './menuItem.module.css';

export interface SidebarMenuHeaderProps {
  isOpen?: boolean;
  organisation: Organisation;
}

const SidebarMenuHeader: FC<SidebarMenuHeaderProps> = (props) => {
  return (
    <div
      className={styles.innerBorder}
    >
      <div className="flex flex-row gap-2 items-center">
        <img
          className={styles.userMenuItemImage}
          src={props.organisation.organisationImgPath}
          alt=""
        />
        {props.isOpen && <div>{props.organisation.organisationName}</div>}
      </div>
      <Arrow className={styles.arrowColor} />
    </div>
  );
};

export default SidebarMenuHeader;
