import { Personae } from "../../../portal/components/ui"
import { HOME_PAGE_PROFILES } from "../../../portal/utils/constants"
import FooterComponent from "./FooterComponent"
import { KampusComponent } from "./KampusComponent"
import NavbarComponent from "./NavbarComponent"
import { RechercheComponent } from "./RechercheComponent"
import { ServiceDigital } from "./ServiceDigital"
import { VideoComponent } from "./VideoComponent"
import styles from './landing.module.css'

export const LandingPage=()=>{


    return<div className={styles['landing-page-css-var']}>
    <NavbarComponent/>
     <Personae personae={HOME_PAGE_PROFILES}/>
     <RechercheComponent/>
     <VideoComponent/>
     <ServiceDigital/>
     <KampusComponent/>
     <FooterComponent/>
    </div>
}