import {Section,TaskItem} from "../../../components/ui";
import {HOME_PAGE_PROCEDURES} from "../../../utils/constants";

export  const ProceduresWidget=()=>{
    const menuList=[
        {label:"Voir plus",value:"see_more",icon:<i className="material-flex-icon text-red-400">arrow_outward</i>},
        {label: "Masquer",value:"hide",icon:<i className="material-flex-icon text-red-400">delete</i>},
    ]
    return (
        <Section title={"Mes procédures"} materialIconLabel="settings" menuList={menuList}>
            <div className="flex flex-col gap-3 py-2">
                {HOME_PAGE_PROCEDURES.map((procedure, index) => (<TaskItem key={index} {...procedure} />))}
            </div>
        </Section>
    )
}