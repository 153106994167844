export const KSW_ANTD_THEME={
    components: {
        Message: {
            contentBg:"var(--antd-message-contentBg)"
        },
    },
    token: {
       colorText:'var(--mapping-slate-800)',
        colorBgContainer:"var(--mapping-slate-000)",


    },
}