export const transformExtension =(input: string): string=> {
    const regex = /^image\/(.*)$/;
    const match = regex.exec(input);
    if (match) {
        return match[1];
    }
    return input;
}

export const removePrefixFromBase64=(image:string)=>{
return image.split(',')[1]
}