import {object, ref, string} from "yup";
import {PasswordStrength} from "../../../../shared/utils/enums";
import {validatePasswordForm} from "../../utils/helpers";

export const ResetPasswordSchema = object().shape({

    password: string()
        .required("submit_password_formError_password_required")
        .test("",PasswordStrength.WEAK,validatePasswordForm),
    password2: string().oneOf([ref('password'), ""], "submit_password_confirmation_formError_password2_invalid").required("sign_stepOne_formError_password2_invalid"),
});