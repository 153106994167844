import { FC } from 'react';
import { Card } from '../cards/Card';
import styles from './card-grid.module.css';

const SkeletonGrid:FC<{numberOfCards?:number|10}> = ({numberOfCards}) => {
  return (
    <>
      {Array(numberOfCards)
        .fill(0)
        .map((_, index) => (
          <Card
            key={index}
            childrenCard={
              <>
                {/* Logo Placeholder */}
                <div className={styles.cardSkeletonLogo}></div>

                {/* User Placeholder */}
                <div className={styles.cardSkeletonUser}></div>

                {/* Status & Badge Placeholders */}
                <div className="flex justify-between items-center mt-4">
                  <div className={styles.cardSkeletonBL}></div>
                  <div className={styles.cardSkeletonBR}></div>
                </div>
              </>
            }
            childrenInfo={<div className={styles.cardSkeletonUser}></div>}
          />
        ))}
    </>
  );
};

export default SkeletonGrid;
