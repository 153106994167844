import { useMutation } from '@tanstack/react-query';
import {
  postSignUpActivationAccount,
  postSignUpForm,
  postSignUpResendActivationCode
} from './api';
import {
  AccountActivation,
  AccountResendActivation,
  SignUpUser
} from '../../types';
import { useHandleStandardResult } from '../../../../shared/hooks';

export const usePostSignUpData = () => {
  const mutationQuery = useMutation({
    mutationFn: (data: SignUpUser) => postSignUpForm(data)
  });

  const handleMapDataResult = useHandleStandardResult(mutationQuery);
  return { ...mutationQuery, customHandler: handleMapDataResult };
};

export const usePostSignUpActivationAccount = () => {
  const mutationQuery = useMutation({
    mutationFn: (data: AccountActivation) => postSignUpActivationAccount(data)
  });
  const handleMapDataResult = useHandleStandardResult(mutationQuery);
  return { ...mutationQuery, customHandler: handleMapDataResult };
};
export const usePostSignUpResendActivationCode = () => {
  const mutationQuery = useMutation({
    mutationFn: (data: AccountResendActivation) =>
        postSignUpResendActivationCode(data)
  });
  const handleMapDataResult = useHandleStandardResult(mutationQuery);
  return { ...mutationQuery, customHandler: handleMapDataResult };
};
