import { useState, KeyboardEvent, forwardRef, useRef, ForwardRefRenderFunction, useEffect } from 'react';
// import { TagInputText } from './TagInputText';
import { TagElement } from '../../tags/tag-element/TagElement';
import { InputTagsProps } from '../../../types';
import { InputText } from '../../input/input-text/InputText';
const ForwardedTagInput: ForwardRefRenderFunction<HTMLInputElement, InputTagsProps> = (
  props,
  ref
) => {
  const { size = 'sm', value = [], onChange, errorMessage, placeholder } = props;
  const [tagsLabel, setTagsLabel] = useState<string[]>(value);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (typeof ref === 'function') {
      ref(inputRef.current);
    } else if (ref) {
      ref.current = inputRef.current;
    }
  }, [ref]);

  useEffect(() => {
    setTagsLabel(value);
  }, [value]);

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const tag = e.currentTarget.value.trim();
    if (!tag || tagsLabel.includes(tag) || e.key !== 'Enter') return;
    const newTags = [...tagsLabel, tag];
    setTagsLabel(newTags);
    onChange?.(newTags);

    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const handleCloseTag = (tag: string) => {
    const newTags = tagsLabel.filter((label) => label !== tag);
    setTagsLabel(newTags);
    onChange?.(newTags);
  };

  const handleClearTags = () => {
    setTagsLabel([]);
    onChange?.([]);
  };

  const renderTagElements = () =>
    tagsLabel.map((label, index) => (
      <TagElement
        size={size}
        closable
        key={index}
        label={label}
        onClose={() => handleCloseTag(label)}
      />
    ));

  return (
    <>
      <InputText
        isTag={true}
        placeholder={placeholder}
        size={size}
        ref={inputRef}
        label="Tags"
        clearable
        onClear={handleClearTags}
        beforeText={tagsLabel.length > 0 && <div className="flex gap-1 flex-wrap">{renderTagElements()}</div>}
        onKeyDown={handleKeyDown}
        errorMessage={errorMessage}
      />
    </>
  );
};

export const TagInput = forwardRef(ForwardedTagInput);
