import { Input } from 'kls-ui';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import SearchSvg from '../../../../../shared/components/icons/SearchSvg';
import XSvg from '../../../../../shared/components/icons/XSvg';
import VerticalDeviderSvg from '../../../../../shared/components/icons/VerticalDeviderSvg';
import {
  FilterBarButton,
  FilterBarButtonProps
} from '../buttons/FilterBarButton';
import { IconSize } from '../../../../../shared/utils/enums';
import styles from './filterBar.module.css';
import {
  FilterBarSortByTime,
  FilterBarSortByTimeProps
} from './FilterBarSortByTime';

export interface FilterBarInputProps {
  filterBarInputValue: string;
  onChange: (value: string) => void;
  filterBarInputPlaceHolder: string;
  filterBarInputType: string;
  filterBarInputId: string;
  onSearch: (value: string) => void;
  onClear: () => void;
  isGridDisplayMode: boolean;
  itemsFilterBarSort: FilterBarSortByTimeProps;
  filterButton?: FilterBarButtonProps;
  mapButton?: FilterBarButtonProps;
  gridButton?: FilterBarButtonProps;
  listButton?: FilterBarButtonProps;
}

export const FilterBarInput: FC<FilterBarInputProps> = (props) => {
  const [searchTerm, setSearchTerm] = useState(props.filterBarInputValue);
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(
    null
  );

  useEffect(() => {
    // Clear the previous timeout on unmount or when dependencies change
    return () => {
      if (debounceTimeout) clearTimeout(debounceTimeout);
    };
  }, [debounceTimeout]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);

    // Clear the previous timeout
    if (debounceTimeout) clearTimeout(debounceTimeout);

    // Set a new timeout to debounce the search
    const newTimeout = setTimeout(() => {
      props.onChange(value); // Trigger the onChange callback after debounce
    }, 500); // 500ms debounce delay

    setDebounceTimeout(newTimeout);
  };

  const handleSearch = () => {
    // Trigger the search immediately when the search button is clicked
    if (debounceTimeout) clearTimeout(debounceTimeout);
    const newTimeout = setTimeout(() => {
      props.onSearch(searchTerm);
    }, 500); // 500ms debounce delay

    setDebounceTimeout(newTimeout);
  };

  const handleClear = () => {
    if (debounceTimeout) clearTimeout(debounceTimeout);
    const newTimeout = setTimeout(() => {
      setSearchTerm('');
      props.onClear(); // Trigger the onChange callback after debounce
    }, 500); // 500ms debounce delay

    setDebounceTimeout(newTimeout);
  };

  return (
    <div className={styles.filterInput}>
      <div className={styles.filterInputRelative}>
        <Input
          withoutError
          args={{
            value: searchTerm,
            id: props.filterBarInputId,
            type: props.filterBarInputType,
            placeholder: props.filterBarInputPlaceHolder,
            onChange: (e) => handleInputChange(e)
          }}
          size="md"
        />
        <div className={styles.filterInputAbsolute}>
          <span onClick={() => handleClear()}>
            <XSvg className={styles.filterInputIcon} size={IconSize.PX12} />
          </span>
          <span onClick={() => handleSearch()}>
            <SearchSvg
              className={styles.filterInputIcon}
              size={IconSize.PX12}
            />
          </span>
        </div>
      </div>
      <div className={styles.filterInputFilterCont}>
        <FilterBarSortByTime
          setSelectedValue={props.itemsFilterBarSort.setSelectedValue}
          label={props.itemsFilterBarSort.label}
          options={props.itemsFilterBarSort.options}
          selectedValue={props.itemsFilterBarSort.selectedValue}
        />
        <div className={styles.filterInputBtnsCont}>
          {props.filterButton && (
            <FilterBarButton
              selected={props.filterButton.selected}
              icon={props.filterButton.icon}
              label={props.filterButton.label}
              handleClick={props.filterButton.handleClick}
              reset={props.filterButton.reset}
              resetClick={props.filterButton.resetClick}
            />
          )}
         {(props.mapButton ||props.gridButton ) &&  <VerticalDeviderSvg
            size={IconSize.MD}
            className={styles.filterInputDevider}
          />
         }
          {props.mapButton && (
            <FilterBarButton
              icon={props.mapButton.icon}
              handleClick={props.mapButton.handleClick}
              selected={props.mapButton.selected}
            />
          )}
          {props.gridButton && 
          <FilterBarButton
            icon={props.gridButton.icon}
            handleClick={props.gridButton.handleClick}
            selected={props.gridButton.selected}
          />
}
        {props.listButton && <FilterBarButton
            icon={props.listButton.icon}
            handleClick={props.listButton.handleClick}
            selected={props.listButton.selected}
          /> }
        </div>
      </div>
    </div>
  );
};
