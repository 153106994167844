import {FC, ReactNode} from 'react';
import styles from './taskitem.module.css';
import classNames from 'classnames';
import {MaterialIcon} from "../../../../../../shared/components/icons";

interface ItemProps {
  icon: ReactNode;
  description: string;
}
interface StatusProps{
    title:string,
    style:string,
}
interface TaskItemProps {
  icon?: ReactNode;
  heading: string;
  status: StatusProps,
  description: string;
  itemsList: ItemProps[];
}

export const TaskItem:FC<TaskItemProps> = ({ heading, icon, status, description, itemsList }) => {
  const statusStyle = classNames({
          [styles.status]: true,
          [status.style]: true,
      }
  );

  const handleViewMoreClick = () => {
    console.log('View More clicked');
  };
  return (
    <div className={styles.taskItemContainer}>
      {icon && <div className={styles.iconContainer}>
        {icon}
      </div>}
        <div className="flex justify-between w-full" >
            <div className={styles.contentSection}>
                <div className={styles.titleSection}>
                    <span className={styles.title}>{heading}</span>
                    <span className={statusStyle}>{status.title}</span>
                </div>
                <p className={styles.description}>{description}</p>
                <ul className={styles.itemsList}>
                    {itemsList.map((item, index) => (
                        <li key={index} className={styles.item}>
                            {item.icon}
                            <span className={styles.itemDescription}>{item.description}</span>
                        </li>
                    ))}
                </ul>
            </div>
            <div className={styles.moreIcon} onClick={() => handleViewMoreClick()}>
              <MaterialIcon label="east" className="text-2xl"/>
            </div>
        </div>

    </div>
  );
};

