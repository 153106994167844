import styles from './upload.module.css'
import classNames from "classnames";
import {DocumentCard} from "../upload-cards/DocumentCard";
import {DragEvent} from 'React'
import {ChangeEvent, FC, useRef, useState} from "react";
import {Pagination} from "kls-ui";


const PAGINATION_ITEMS_PER_PAGE = 3;
const PAGINATION_MAX_PAGES=3;

interface AdvancedUploaderProps{
    title?:string;
    description?:string;
    uploaderClassName?:string;
    showFilesList?:boolean

}
export const AdvancedUploader:FC<AdvancedUploaderProps> =(props)=>{
    const {title="",description="",uploaderClassName="",showFilesList=false}=props
    const [uploadedFiles,setUploadedFiles]=useState<File[]>([]);
    const uploaderSectionStyles=classNames({
        [styles.uploaderContainer]:true,
        [uploaderClassName]:true,
        [styles["portal-advanced_uploader-css-var"]]:true
    })
    const ALLOWED_FORMATS = ['application/pdf', 'image/png', 'image/jpeg'];
    const inputRef=useRef<HTMLInputElement>(null);
    const [currentPage,setCurrentPage]=useState<number>(0);
    const handleDragOver=(e:DragEvent<HTMLDivElement>)=>e.preventDefault();
    const handleUploaderChange=(e:ChangeEvent<HTMLInputElement>)=>{
        const uploadedFiles = e.target.files;
        if (uploadedFiles)  handleUploadedFiles(e.target.files)
        if(inputRef.current) inputRef.current.value="";
    }
    const handleUploadedFiles=(files: FileList|null)=>{
        console.log("files",files);
            if(!files) return;
           const validFiles = Array.from(files).filter(file => ALLOWED_FORMATS.includes(file.type));
            if (validFiles.length > 0) setUploadedFiles( [...uploadedFiles, ...validFiles]);
    }
    const handleDrop=(e:DragEvent<HTMLDivElement>)=>{
        e.preventDefault();
        const droppedFiles = e.dataTransfer.files;
        handleUploadedFiles(droppedFiles);
    }
    const handleClick=()=>{
         if(inputRef.current) inputRef.current.click()
    }
    const handleRemoveFile=(index:number) => {
        const filtredFiles=uploadedFiles.filter((_file,key)=>key!==index);
        setUploadedFiles(filtredFiles);
    }
   const handlePageChange =(currentPage:number)=>{
       setCurrentPage(currentPage-1);
   }
   const paginationStyle=classNames({
       ["transition-all duration-500"]:true,
       ["notShow"]:uploadedFiles.length<=PAGINATION_ITEMS_PER_PAGE,
       ["show"]:uploadedFiles.length>PAGINATION_ITEMS_PER_PAGE,
   })

    return (
        <div >
            <div className={uploaderSectionStyles} onDragOver={handleDragOver} onDrop={handleDrop} onClick={handleClick}>
            <input ref={inputRef} className="hidden" accept=".jpg, .jpeg, .png, .pdf" type='file' onChange={handleUploaderChange}/>
            <div className="flex flex-col items-center gap-3">
                <i className="material-icon text-blue-600 !text-3xl">upload</i>
                <span className={styles.uploaderTitle}>{title}</span>
                <span className={styles.uploaderDescription}>{description}</span>
            </div>
            </div>
            {showFilesList &&
                <>
                <div className={"flex flex-col gap-3 py-3 min-h-[200px]"}>
                {uploadedFiles && uploadedFiles
                    .filter((_file,index)=>(currentPage*PAGINATION_ITEMS_PER_PAGE<= index && index <(currentPage+1)*PAGINATION_ITEMS_PER_PAGE))
                    .map((file,index)=>(<DocumentCard key={index} title={file.name} size={`${file.size} bytes`} onViewClick={()=>{}} onRemoveClick={()=>handleRemoveFile(index)}/>))}
            </div>
                <div className={paginationStyle}>
                    <Pagination  totalItems={uploadedFiles.length} itemsPerPage={PAGINATION_ITEMS_PER_PAGE} maxPage={PAGINATION_MAX_PAGES} handleChange={handlePageChange}  />
                </div>
            </>

}
        </div>
    )
}

