import { FC } from 'react';
import { BaseSVGIconProps } from '../../types/components.type';
import AbstractSVGIcon from './AbstractSVGIcon';
const TimeSvg: FC<BaseSVGIconProps> = (props) => (
  <AbstractSVGIcon
    size={props.size}
    className={props.className}
    spin={props.spin}
    hidden={props.hidden}
    viewBox="0 0 14 15">
    <path d="M7.99362 1.83333C4.31362 1.83333 1.33362 4.82 1.33362 8.5C1.33362 12.18 4.31362 15.1667 7.99362 15.1667C11.6803 15.1667 14.667 12.18 14.667 8.5C14.667 4.82 11.6803 1.83333 7.99362 1.83333ZM8.00029 13.8333C5.05362 13.8333 2.66695 11.4467 2.66695 8.5C2.66695 5.55333 5.05362 3.16667 8.00029 3.16667C10.947 3.16667 13.3336 5.55333 13.3336 8.5C13.3336 11.4467 10.947 13.8333 8.00029 13.8333ZM8.33362 5.16667H7.33362V9.16667L10.8336 11.2667L11.3336 10.4467L8.33362 8.66667V5.16667Z"/>
    </AbstractSVGIcon>
);
export default TimeSvg;
