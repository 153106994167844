import { FC } from 'react';
import { BaseSVGIconProps } from '../../types/components.type';
import AbstractSVGIcon from './AbstractSVGIcon';
const SearchSvg: FC<BaseSVGIconProps> = (props) => (
  <AbstractSVGIcon
    size={props.size}
    className={props.className}
    spin={props.spin}
    hidden={props.hidden}
    viewBox="0 0 12 12">
    <path d="M8.50333 7.50333H7.97667L7.79 7.32333C8.44333 6.56333 8.83667 5.57666 8.83667 4.50333C8.83667 2.11 6.89667 0.169998 4.50333 0.169998C2.11 0.169998 0.169998 2.11 0.169998 4.50333C0.169998 6.89667 2.11 8.83667 4.50333 8.83667C5.57666 8.83667 6.56333 8.44333 7.32333 7.79L7.50333 7.97667V8.50333L10.8367 11.83L11.83 10.8367L8.50333 7.50333ZM4.50333 7.50333C2.84333 7.50333 1.50333 6.16333 1.50333 4.50333C1.50333 2.84333 2.84333 1.50333 4.50333 1.50333C6.16333 1.50333 7.50333 2.84333 7.50333 4.50333C7.50333 6.16333 6.16333 7.50333 4.50333 7.50333Z" />
  </AbstractSVGIcon>
);
export default SearchSvg;
