import { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { MaterialIcon } from '../icons';
import styles from './breadcrumb.module.css';
export interface BreadcrumbProps {
  items: { title?: string; icon?: ReactNode; href?: string }[];
}
export const BreadCrumb: FC<BreadcrumbProps> = (props) => {
  const navigate = useNavigate();
  const renderJoinIcon = (render: boolean) => {
    if (render)
      return <MaterialIcon label="chevron_right" center className="text-sm" />;
    return;
  };

  return (
    <div className="flex gap-1 items-center">
      {props.items.map((item, index) => (
        <div key={index} className="flex gap-0.5 items-center">
          <div
            className={styles.item}
            onClick={() => item.href && navigate(item.href)}>
            {item.icon}
            {item.title && <span className={styles.title}>{item.title}</span>}
          </div>
          <span className={styles.joinIcon}>
            {renderJoinIcon(props.items.length > index + 1)}
          </span>
        </div>
      ))}
    </div>
  );
};
