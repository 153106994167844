import {CourseCard, Section} from "../../../components/ui";
import {HOME_PAGE_COURSES} from "../../../utils/constants";

export const TrainingProgramWidget=()=>{
    const menuList=[
        {label:"Voir plus",value:"see_more",icon:<i className="material-flex-icon text-red-400">arrow_outward</i>},
        {label: "Masquer",value:"hide",icon:<i className="material-flex-icon text-red-400">delete</i>},
    ]
    return (
        <Section title="Parcours formation" materialIconLabel="school" className="flex" menuList={menuList}>
            <div className="w-full flex justify-center gap-4">
                {HOME_PAGE_COURSES.map((element, index) => <CourseCard  key={index} {...element}/>)}
            </div>
        </Section>
    )
}