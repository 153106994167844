import globalStyles from "../input.module.css";
import customStyles from "./text-area.module.css"
import { FocusEvent, forwardRef, ForwardRefRenderFunction, useState} from "react";
import classNames from "classnames";
import {TextAreaProps} from "../../../types";


const ForwardedTextArea:ForwardRefRenderFunction<HTMLTextAreaElement,TextAreaProps>=(props,ref)=>{
   const {size="sm"}=props
    const {
        label,
        withError=false,
        disabled=false,
        onlyRead=false,
        errorMessage,
        ...textAreaProps}=props;
    const [isFocused,setIsFocused]=useState<boolean>(false);
    const getErrorMessage=errorMessage ?? "";


    const rootStyle=classNames({
        [globalStyles.rootContainer]:true,
        [globalStyles["portal-input-css-var"]]:true,
    })
    const inputContainerStyle=classNames({
        [globalStyles.inputContainer]:true,
        [customStyles.textArea]:true,
        [globalStyles.focusedInput]:isFocused,
        [globalStyles.disabledInput]:disabled,
        [globalStyles.readOnlyInput]:textAreaProps.readOnly,
        [globalStyles.onlyReadInput]:onlyRead,
        [globalStyles.errorInput]:props.errorMessage,
        ["h-8 p-2 text-[12px] rounded"]:size==="sm",
        ["h-9 p-2.5 text-[13px] rounded-lg"]:size==="md",
        ["h-9 p-3 text-[13px] rounded-lg"]:size==="lg",
    })

    const inputLabelStyle=classNames({
        [globalStyles.inputLabel]:true,
        ["text-xs"]:size==="sm",
        ["text-sm"]:["lg","md"].includes(size),
    })
    const footerStyles=classNames({
        ["transition-all duration-300 "]:true,
        ["show"]:errorMessage,
        ["notShow"]:!errorMessage,
        ["h-3"]:withError
    })

    const handleInputBlur=(e:FocusEvent<HTMLTextAreaElement>)=>{
        setIsFocused(false);
        textAreaProps.onBlur?.(e)
    }
    const handleInputFocus=(e:FocusEvent<HTMLTextAreaElement>)=>{
        setIsFocused(true);
        textAreaProps.onFocus?.(e)
    }


    const renderLabel=()=>{
        if (label) return (
            <div className="flex justify-between w-full ">
                <span className={inputLabelStyle}>{label}</span>
                <span></span>
            </div>)
    }

    const renderFooter=()=> {
        if(withError) return (<span className="text-red-600 text-xs">{getErrorMessage}</span>)
        if (props.helpText) return (<span>{props.helpText}</span>)
        return (<></>)
    }


    return (
        <div className={rootStyle}>
            {renderLabel()}
            <div className="w-full">
                        <textarea
                            ref={ref}
                            className={inputContainerStyle}
                            readOnly={onlyRead}
                            {...textAreaProps}
                            onBlur={handleInputBlur}
                            onFocus={handleInputFocus}
                        />
                <div className={footerStyles}>
                    {renderFooter()}
                </div>
            </div>
        </div>
    )
}
export const TextArea=forwardRef(ForwardedTextArea);