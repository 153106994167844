
import {useNavigate} from 'react-router-dom';
import {FC, useEffect} from "react";
import { useSelector } from 'react-redux';
import { RootState } from '../stores/store';

interface AuthProviderProps{
  children:JSX.Element
}
export const AuthProvider:FC<AuthProviderProps> = (props) => {
  const { isAuthenticated, hasAccomplishedOnboarding } = useSelector((state: RootState) => state.auth);
  const navigate=useNavigate();
console.log("ssd",hasAccomplishedOnboarding);
  useEffect(() => {
    if (!hasAccomplishedOnboarding) {navigate("/onboarding")}
  }, [hasAccomplishedOnboarding]);

  useEffect(() => {
    if (!isAuthenticated) {navigate("/landing")}
  }, [hasAccomplishedOnboarding]);

  return props.children
};

