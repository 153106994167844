import { Tabs } from "../../../../../../shared/components";
import { FC, useState } from "react";
import styles from './eservice.param.module.css';
import { PageContentContainer } from "../../../../layouts";
import { fBhEsrv } from "../../../../mockdata/MockDataFilterBar";
import { FilterBarHeader } from "../../../../components/ui/filter-bar/FilterBarHeader";
import { EServiceInfoTab } from "./tabs/EserviceInfoTab";
import { EserviceMembresTab } from "./tabs/EserviceMembresTab";
import {  EserviceContributeursTab } from "./tabs/EserviceContributeursTab";
import { EserviceUsersTab } from "./tabs/EserviceUsersTab";

export const EServiceParamsPage: FC = () => {
  const [selectedTab] = useState("eService");

  
  const tabList = [
    { name: 'eService', label: <><span className="icon">bubble_chart</span>E-service</>, children: <EServiceInfoTab /> },
    { name: 'modules', label: <><span className="icon">apps</span>Modules</>, children: <div></div> },
    { name: 'schemaRelational', label: <><span className="icon">schema</span>Schéma relationnel</>, children: <div></div> },
    { name: 'membres', label: <><span className="icon">manage_accounts</span>Membres</>, children: <EserviceMembresTab /> },
    { name: 'contributeurs', label: <><span className="icon">work_outline</span>Contributeurs</>, children: <EserviceContributeursTab/> },
    { name: 'user', label: <><span className="icon">group</span>Utilisateurs</>, children: <EserviceUsersTab/> },
    { name: 'role', label: <><span className="icon">workspaces</span>Rôles</>, children: <div></div> },
    { name: 'menu', label: <><span className="icon">menu</span>Menus</>, children: <div></div> },
  ];
  return (
    <PageContentContainer className="flex flex-col gap-1 p-5 !h-[100%]">
      <FilterBarHeader hasChilds={fBhEsrv?.hasChilds} parent={fBhEsrv?.parent} icon={fBhEsrv?.icon} childs={fBhEsrv?.childs} />
      <div className={styles.tabsContainer}>
        <Tabs tabs={tabList} align="horizontal"  current={selectedTab} defSelected={"serviceSettings"} />
      </div>
    </PageContentContainer>
  );
};

