import { FC } from 'react';
import styles from './avatar.module.css';
import classNames from 'classnames';

interface AvatarProps{
  fullname:string
  height?: string;
  width?: string;
  fontSize?: string;
}
export const AvatarComponent:FC<AvatarProps> = ({ fullname, height = "2rem", width = "2rem", fontSize = "13px" }) => {

  const nameParts = fullname.trim().split(' ');
  const firstInitial = nameParts[0][0];
  const lastInitial = nameParts.length > 1 ? nameParts[nameParts.length - 1][0] : '';
  const initials = ` ${firstInitial}${lastInitial}`.toUpperCase();
  const styleAv = classNames({
        [styles['avatar-component-css-var'] ]:true,
        [styles.avatarStyle]: true,
       
    });
  return <div className={styleAv} style={{ height, width, fontSize }}>{initials}</div>;
};

