export const PHONE_NUMBER_DATA = [
    { country: 'United States', prefix: '+1', placeholder: '(555) 123-4567' },
    { country: 'United Kingdom', prefix: '+44', placeholder: '07123 456789' },
    { country: 'Australia', prefix: '+61', placeholder: '0412 345 678' },
    { country: 'Germany', prefix: '+49', placeholder: '0151 23456789' },
    { country: 'France', prefix: '+33', placeholder: '06 12 34 56 78' },
    { country: 'India', prefix: '+91', placeholder: '91234 56789' },
    { country: 'Brazil', prefix: '+55', placeholder: '(11) 91234-5678' },
    { country: 'Japan', prefix: '+81', placeholder: '090-1234-5678' },
    { country: 'Mexico', prefix: '+52', placeholder: '55 1234 5678' },
    { country: 'South Africa', prefix: '+27', placeholder: '071 123 4567' },
    { country: 'Nigeria', prefix: '+234', placeholder: '0801 234 5678' },
    { country: 'Egypt', prefix: '+20', placeholder: '0100 123 4567' },
    { country: 'Kenya', prefix: '+254', placeholder: '0712 345 678' },
    { country: 'Ghana', prefix: '+233', placeholder: '024 123 4567' },
    { country: 'Morocco', prefix: '+212', placeholder: '0612-345678' },
    { country: 'Ethiopia', prefix: '+251', placeholder: '091 123 4567' },
    { country: 'Uganda', prefix: '+256', placeholder: '0712 345678' },
    { country: 'Tanzania', prefix: '+255', placeholder: '0655 123 456' },
    { country: 'Algeria', prefix: '+213', placeholder: '0551 23 45 67' },
];