import { useState, useEffect } from 'react';
import {
    PasswordResetConfirm,
    PasswordResetSubmit, ResetPasswordData
} from '../../types';
import ForgotPasswordRequest from './forgetpassword-steps/ForgotPasswordRequest'
import ForgotPasswordConfirm from './forgetpassword-steps/ForgotPasswordConfirm'
import ForgotPasswordSubmit from  './forgetpassword-steps/ForgotPasswordSubmit'
import styles from './forget-password.module.css';
import classNames from 'classnames';
import { OuterContainer } from '../../../../shared/components';
import {
    usePostPasswordResetRequest,
    usePostPasswordResetVerifyCode,
    usePostPasswordResetSubmit
} from '../../api';
import {useNavigate} from "react-router-dom";

enum ForgotPasswordStep {
    REQUEST = 'REQUEST_VERIFY',
    VERIFY_CODE = 'VERIFY_CODE',
    SUBMIT_NEW_PASSWORD = 'SUBMIT_NEW_PASSWORD'
}

const ForgotPassword = () => {
    const [currentStep, setCurrentStep] = useState<ForgotPasswordStep>(ForgotPasswordStep.REQUEST);
    const [activationId, setActivationId] = useState<string | undefined>();
    const [email, setEmail] = useState<string | undefined>();
    const [activationCode, setActivationCode] = useState<string | undefined>();
    const [deadline, setDeadline] = useState<string | undefined>();

    const postPasswordResetRequest = usePostPasswordResetRequest();
    const postPasswordResetVerifyCode = usePostPasswordResetVerifyCode();
    const postPasswordResetSubmit = usePostPasswordResetSubmit();
    const navigate = useNavigate();

    const submitResetRequest = (email: string) => {
        postPasswordResetRequest.mutate({ email });
        setEmail(email);
    };

    const submitVerificationCode = (activationCode: string) => {
        if (!email) return;
        const data: PasswordResetConfirm = {activationId, activationCode, email};
        setActivationCode(activationCode);
        postPasswordResetVerifyCode.mutate(data);
    };

    const submitNewPassword = ({ password, password2 }: ResetPasswordData) => {
        if (password !== password2) {
            return;
        }

        if (!activationId || !activationCode || !email) {
            return;
        }
        const resetData: PasswordResetSubmit = {activationId, activationCode, email, password, password2};
        postPasswordResetSubmit.mutate(resetData);
    };



    useEffect(() => {
        if (postPasswordResetRequest.customHandler.isSuccess) {
            const loginData  = postPasswordResetRequest.customHandler.data;
            setActivationId(loginData?.activationId);
            setDeadline(loginData?.expired);
            setCurrentStep(ForgotPasswordStep.VERIFY_CODE);
        }
    }, [postPasswordResetRequest.customHandler.isSuccess]);

    useEffect(() => {
        if (postPasswordResetVerifyCode.customHandler.isSuccess) {
            setCurrentStep(ForgotPasswordStep.SUBMIT_NEW_PASSWORD);
        }
    }, [postPasswordResetVerifyCode.customHandler.isSuccess]);

    useEffect(() => {
        if (postPasswordResetSubmit.customHandler.isSuccess) {
            navigate('/acceuil');
        }
    }, [postPasswordResetSubmit.customHandler.isSuccess]);

    const forgotPasswordStyles = (step: ForgotPasswordStep) =>
        classNames({
            [styles.stepsContainer]: true,
            ['show']: currentStep === step,
            ['notDisplayV2']: currentStep !== step
        });

    return (
        <OuterContainer>
            <ForgotPasswordRequest
                className={forgotPasswordStyles(ForgotPasswordStep.REQUEST)}
                onSubmit={submitResetRequest}
                isLoading={postPasswordResetRequest.isPending}
                isError={postPasswordResetRequest.customHandler.isError}
                submitError={postPasswordResetRequest.customHandler.errorMessage}
            />
            {activationId &&  (
                <ForgotPasswordConfirm
                    className={forgotPasswordStyles(ForgotPasswordStep.VERIFY_CODE)}
                    onSubmit={submitVerificationCode}
                    isLoading={{requestPasswordReset:postPasswordResetVerifyCode.isPending, resendActivationCode:postPasswordResetVerifyCode.isPending}}
                    isError={{requestPasswordReset:postPasswordResetVerifyCode.isError,resendActivationCode:postPasswordResetVerifyCode.isError}}
                    submitError={postPasswordResetVerifyCode.error?.message}
                    deadline={deadline}
                />
            )}
            {activationId && activationCode && (
                <ForgotPasswordSubmit
                    className={forgotPasswordStyles(ForgotPasswordStep.SUBMIT_NEW_PASSWORD)}
                    onSubmit={submitNewPassword}
                    isLoading={postPasswordResetSubmit.isPending}
                    isError={postPasswordResetSubmit.isError}
                    submitError={postPasswordResetSubmit.error?.message}
                />
            )}
        </OuterContainer>
    );
};


export default ForgotPassword;
