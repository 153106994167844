import {PageContentContainer} from "../../../layouts";
import {OrgCreationStep} from "../../../components/ui";
import {useCreateWindow, useGetIndustriesList} from "../../../api";
import {useEffect} from "react";
import {OnboardingGuichetCreationRequest} from "../../../types";
import {useNavigate} from "react-router-dom";
import {useHandleTranslation} from "../../../../../shared/hooks";
import {message} from "antd";

export const CreateWindowPage=()=>{
    const [messageApi, contextHolder] = message.useMessage();

    const navigate=useNavigate();
    const postCreateOrganization=useCreateWindow();
    const getIndustriesList =useGetIndustriesList();
    const { t:tW } = useHandleTranslation("window");

    const handleSubmitGuichetCreationStep=(data:OnboardingGuichetCreationRequest)=>{
        postCreateOrganization.mutate(data);
    }
    const navigateToWindowList=()=>{
        const newPath = window.location.pathname.replace('/create-window', '');
        navigate(newPath);
    }
    useEffect(() => {
        if (postCreateOrganization.customHandler.isSuccess)  {
                    messageApi.success({
                        content: "Guichet a été créé avec succès",
                        duration: 2.5
                    }).then(navigateToWindowList);
                }
    }, [postCreateOrganization.customHandler.isSuccess]);
   console.log(postCreateOrganization.customHandler);

    return (
        <PageContentContainer className="flex items-center justify-center h-[100%] overflow-auto py-3" slate>
            {contextHolder}
            <OrgCreationStep
                data={{industriesList:getIndustriesList.customHandler.data}}
                isLoading={{postCreateOrganization:postCreateOrganization.isPending,industriesList:getIndustriesList.isLoading}}
                isSuccess={{postCreateOrganization:postCreateOrganization.customHandler.isSuccess,industriesList:getIndustriesList.customHandler.isSuccess}}
                backButton={{label:tW("orgCreation.standard.back_button"),onClick:()=>navigateToWindowList()}}
                submitError={postCreateOrganization.customHandler.errorMessage}
                onSubmit={handleSubmitGuichetCreationStep}/>
    </PageContentContainer>)
}