import {Section,ESignWidget} from "../../../components/ui";

export const MyEparapheurWidget=()=>{
    const menuList=[
        {label:"Voir plus",value:"see_more",icon:<i className="material-flex-icon text-red-400">arrow_outward</i>},
        {label: "Masquer",value:"hide",icon:<i className="material-flex-icon text-red-400">delete</i>},
    ]
    return (
        <Section title="Mon e-parapheur" materialIconLabel="drive_file_rename_outline" className="flex justify-center" menuList={menuList}>
            <ESignWidget/>
        </Section>
    )
}