import { useEffect, useRef, useState } from 'react';
import { TimeUnitMS } from '../utils/enums';

export const useTimer = (
  deadline: string | undefined,
  interval: TimeUnitMS = TimeUnitMS.SECOND
) => {
  const [timespan, setTimespan] = useState<number>(0);
  const [isRunning, setRunStatus] = useState<boolean>(false);
  const timer = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (isRunning) {
      const intervalId = setInterval(() => {
        setTimespan((_timespan) => _timespan - interval);
      }, interval);
      timer.current = intervalId;
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [interval, isRunning]);
  useEffect(() => {
    if (deadline) {
      const deadlineDate = new Date(deadline).getTime();
      const currentDate = Date.now();
      const diffTime = deadlineDate - currentDate;
      setTimespan(diffTime);
      setRunStatus(true);
    }
  }, [deadline]);
  useEffect(() => {
    if (isRunning && timer.current && timespan < interval) {
      console.log('enter');
      clearInterval(timer.current);
      setRunStatus(false);
    }
  }, [timespan]);

  return {
    isRunning,
    days: Math.floor(timespan / TimeUnitMS.DAY),
    hours: Math.floor((timespan / TimeUnitMS.HOUR) % 24),
    minutes: Math.floor((timespan / TimeUnitMS.MINUTE) % 60),
    seconds: Math.floor((timespan / TimeUnitMS.SECOND) % 60)
  };
};
