import { useMutation, useQuery } from '@tanstack/react-query';
import {
  AllGuichetsRequest,
  FavoriteGuichetRequest,
  OnboardingGuichetCreationRequest,
  OnboardingJoinGuichetRequest
} from '../../types/data.types';
import {
  addFavoriteGuichet,
  getAllGuichets,
  postCreateWindow,
  postJoinWindow
} from './api';
import {
  useHandleGenericResponse,
  useHandleStandardResult
} from '../../../../shared/hooks';

export const useGetAllGuichets = (data: AllGuichetsRequest) => {
  const queryKey = ['all-window', JSON.stringify(data)];
  const reactQuery = useQuery({
    queryKey,
    queryFn: () => getAllGuichets(data),
    retry: 0,
    staleTime: 0,
    gcTime:0
  });
  const customHandler = useHandleGenericResponse(reactQuery);
  return { ...reactQuery, customHandler };
};

export const useFavoriteGuichet = (data: FavoriteGuichetRequest) => {
  const queryKey = ['favorite-guichet', JSON.stringify(data)];
  const reactQuery = useQuery({
    queryKey,
    queryFn: () => addFavoriteGuichet(data),
    retry: 0,
    enabled: data.guichetId!="",
    staleTime: 0
  });
  const customHandler = useHandleGenericResponse(reactQuery);
  return { ...reactQuery, customHandler };
};
export const useCreateWindow = () => {
  const mutationQuery = useMutation({
    mutationFn: (data: OnboardingGuichetCreationRequest) =>
      postCreateWindow(data)
  });
  const customHandler = useHandleStandardResult(mutationQuery);
  return { ...mutationQuery, customHandler };
};
export const useJoinWindow = () => {
  const mutationQuery = useMutation({
    mutationFn: (data: OnboardingJoinGuichetRequest) => postJoinWindow(data)
  });
  const customHandler = useHandleStandardResult(mutationQuery);
  return { ...mutationQuery, customHandler };
};
