import {Controller, FieldPath, FieldValues} from "react-hook-form";
import {ControlledUiProps,TextAreaProps} from "../../types";
import {TextArea as NativeTextArea} from "../input/text-area/TextArea";

export interface ControlledTextAreaProps <TFieldValues extends FieldValues,TName extends FieldPath<TFieldValues>>
    extends TextAreaProps, ControlledUiProps<TFieldValues,TName>{}

export const TextArea  = <
    TFieldValues extends FieldValues,
    TName extends FieldPath<TFieldValues>
>
(props:ControlledTextAreaProps<TFieldValues, TName>) => {
    return (
        <Controller
            control={props.control}
            name={props.name}
            render={({ field: { onChange, onBlur, value,ref } }) => (
                <NativeTextArea
                    ref={ref}
                    {...props}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    errorMessage={props.error}
                />
            )}
        />
    );
};
