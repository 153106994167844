import React, { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './statistics-item.module.css';

interface StatisticsProps {
  label: string;
  icon: ReactNode;
  color: string;
  className?: string;
}

const StatisticsItem: React.FC<StatisticsProps> = (props) => {
  const {className=""}=props
  const containerStyles = classNames({
    [styles.statistics]: true,
    [className]: true
  });

  return (
    <div className={containerStyles} style={{color:props.color}}>
      <div className={styles.icon}>{props.icon}</div>
      <span className={styles.label}>{props.label}</span>
    </div>
  );
};

export default StatisticsItem;
