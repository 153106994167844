import {BaseSVGIconProps} from "../../../../../shared/types";
import {AbstractSVGIcon} from "../../../../../shared/components/icons";
import {FC} from "react";

export const DesktopIcon: FC<BaseSVGIconProps> = (props) => {
    return (<AbstractSVGIcon
        size={props.size}
        className={props.className}
        spin={props.spin}
        hidden={props.hidden}
        viewBox="0 0 48 48"
    >
        <path
            d="M38.0621 28.2188C38.0621 26.6423 36.8261 25.4062 35.2496 25.4062H12.7496C11.1731 25.4062 9.93709 26.6423 9.93709 28.2188C8.36059 28.2188 7.12459 29.4548 7.12459 31.0312V33.8438C7.12459 35.4202 8.36059 36.6562 9.93709 36.6562C9.93709 38.2327 11.1731 39.4688 12.7496 39.4688H35.2496C36.8261 39.4688 38.0621 38.2327 38.0621 36.6562C39.6386 36.6562 40.8746 35.4202 40.8746 33.8438V31.0312C40.8746 29.4548 39.6386 28.2188 38.0621 28.2188ZM36.6558 1.5H28.2183C25.892 1.5 23.9996 3.39244 23.9996 5.71875V22.5938C23.9996 23.371 24.6286 24 25.4058 24H39.4683C40.2456 24 40.8746 23.371 40.8746 22.5938V5.71875C40.8746 3.39244 38.9822 1.5 36.6558 1.5ZM12.7221 24C13.4967 24 14.1257 23.3738 14.1284 22.5979L14.1558 14.1604C14.1586 13.3845 13.5296 12.7527 12.7551 12.75C11.9586 12.6566 11.3462 13.3734 11.3433 14.1521L11.3159 22.5896C11.3132 23.3683 12.0272 23.989 12.7221 24ZM18.3471 24C19.1217 24 19.7507 23.3738 19.7534 22.5979L19.7808 14.1604C19.7836 13.3817 19.0228 12.7184 18.3746 12.75C17.6 12.75 16.9711 13.3762 16.9683 14.1521L16.9409 22.5896C16.9382 23.3683 17.4682 23.989 18.3471 24ZM8.42922 23.0428L11.2692 14.6053C11.5164 13.8692 11.1209 13.0714 10.3847 12.8228C9.64862 12.5825 8.85212 12.9738 8.605 13.7072L5.76503 22.1447C5.51781 22.8808 5.91334 23.6786 6.64947 23.9272C7.38419 24.1721 8.18069 23.7799 8.42922 23.0428Z"
            fill="#BAE6FD"/>
        <path
            d="M46.5938 21.1875H1.40625C0.618656 21.1875 0 21.8062 0 22.5938C0 23.3812 0.618656 24 1.40625 24H2.90625V45.0938C2.90625 45.8812 3.52491 46.5 4.3125 46.5C5.10009 46.5 5.71875 45.8812 5.71875 45.0938V43.6875H42.2812V45.0938C42.2812 45.8812 42.8999 46.5 43.6875 46.5C44.4751 46.5 45.0938 45.8812 45.0938 45.0938V24H46.5938C47.3813 24 48 23.3812 48 22.5938C48 21.8062 47.3813 21.1875 46.5938 21.1875ZM39.4688 33.8438C39.4688 34.6312 38.8501 35.25 38.0625 35.25C37.2749 35.25 36.6562 35.8687 36.6562 36.6562C36.6562 37.4437 36.0376 38.0625 35.25 38.0625H12.75C11.9624 38.0625 11.3438 37.4437 11.3438 36.6562C11.3438 35.8687 10.7251 35.25 9.9375 35.25C9.14991 35.25 8.53125 34.6312 8.53125 33.8438V31.0312C8.53125 30.2437 9.14991 29.625 9.9375 29.625C10.7251 29.625 11.3438 29.0062 11.3438 28.2188C11.3438 27.4312 11.9624 26.8125 12.75 26.8125H35.25C36.0376 26.8125 36.6562 27.4312 36.6562 28.2188C36.6562 29.0062 37.2749 29.625 38.0625 29.625C38.8501 29.625 39.4688 30.2437 39.4688 31.0312V33.8438Z"
            fill="#0EA5E9"/>
    </AbstractSVGIcon>)
}

