import { PasswordStrength } from '../../../../shared/utils/enums';

export const validatePasswordForm=(password:string):boolean=>{
  if (!password) return false;
  if (password.length < 8) return false;
  const hasUppercase = /[A-Z]/.test(password);
  const hasLowercase = /[a-z]/.test(password);
  const hasDigit = /\d/.test(password);
  const hasNonAlphanumeric = /\W/.test(password);
  const categoriesMet = [hasUppercase, hasLowercase, hasDigit, hasNonAlphanumeric].filter(Boolean).length;

  return categoriesMet >= 3;
}

export const validateStrongPassword=(password:string):boolean=>{
  if (!password) return false;

  const hasNoRepeats = !/(.)\1{2,}/.test(password);

  const commonSequences = /(012|123|234|abcd|bcde|cdef|qwerty|azerty|asdf)/i;
  const hasNoCommonSequences = !commonSequences.test(password);

  const uniqueChars = new Set(password).size;
  const hasEnoughUniqueChars = uniqueChars >= 6;

  const dictionaryWords = ['password', 'welcome', 'admin', 'user'];
  const hasNoDictionaryWords = !dictionaryWords.some((word) => new RegExp(word, 'i').test(password));

  const categoriesMet = [hasNoRepeats, hasNoCommonSequences, hasEnoughUniqueChars, hasNoDictionaryWords].filter(Boolean).length;

  return categoriesMet == 4;
}

export const validatePasswordStrength = (password: string):PasswordStrength   => {
  if (!validatePasswordForm(password)) return PasswordStrength.WEAK
  else if (validateStrongPassword(password)) return PasswordStrength.STRONG
   return PasswordStrength.MODERATE;
}

