import { useEffect, useState } from "react";
import FilterSvg from "../../../../../../../shared/components/icons/FilterSvg";
import PeopleSvg from "../../../../../../../shared/components/icons/PeopleSvg";
import StarSvg from "../../../../../../../shared/components/icons/StarSvg";
import { FilterBar } from "../../../../../components/ui/filter-bar/FilterBar";
import { FilterBarContentProps } from "../../../../../components/ui/filter-bar/FilterBarContent";
import { FilterBarInputProps } from "../../../../../components/ui/filter-bar/FilterBarInput";
import { fBsEserv } from "../../../../../mockdata/MockDataFilterBar";
import { EserviceMembersListRequest, EserviceMembersListResponse } from "../../../../../types/data.types";
import { Table } from "../../../../../components/ui/table/Table";
import { EmptyData } from "../../../../../../../shared/components";
import { message } from "antd";
import TimeSvg from "../../../../../../../shared/components/icons/TimeSVG";
import { useAcceptRequestJoinEservice, useGetEserviceMembersList, useGetRequestJoinEserviceList, useRefuseRequestJoinEservice } from "../../../../../api";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../shared/stores/store";
import { useParams } from "react-router-dom";
import { Eservice } from "../../../../../components/ui/cards/base-cards/CardEservice";

export const EserviceContributeursTab = () => {

const selectedEservice = useSelector((state: RootState) => state.eservices.selectedEservice);
const { eserviceID } = useParams<{ eserviceID: string }>();
const id = (selectedEservice && (selectedEservice as Eservice).id) ? (selectedEservice as Eservice ).id : eserviceID || '';

const getRequestJoinEserviceList = useGetRequestJoinEserviceList({EserviceId:id});
const [selectedRowOption, setSelectedRowOption] = useState<{  id: string; selectedValue: string;emailPersonnel:string;nameUser:string;logoUser:string;role:string;ownerLogin:string;note:string} | null>(null);
const postAcceptJoinEservice = useAcceptRequestJoinEservice();
const postRefuseJoinEservice = useRefuseRequestJoinEservice();
const [messageApi, contextHolder] = message.useMessage();
const emptyIcon = <i className="material-flex-icon text-blue-500 text-3xl">search_off</i>
const [totalNumber, setTotalNumber] = useState(0);
const [page, setPage] = useState<number>(1);
const [isFetching, setIsFetching] = useState<boolean>(false);
const [searchValue, setSearchValue] = useState<string>('');
const [requestMembersData, setRequestMembersData] = useState<EserviceMembersListResponse[]>([]);
const [typeRequest ,setTypeRequest]=useState('DEMANDE');
const [selectedButton,setSelectedButton]=useState('demandes')
const [statusRequest,setStatusRequest]=useState('Brouillon');


const [allMembersParams, setAllMembersParams] =
    useState<EserviceMembersListRequest>({
      numPage: 1,
      pageSize: 10,
      idRequest: '',
      key:'',
      idEservice: id,
      typeRequest: typeRequest,
      statusRequest:statusRequest,
      statusRole: '',
      role: 'CONTRIBUTEUR',
      Fields: '',
    });

    const getEserviceMembersList = useGetEserviceMembersList(allMembersParams);
    useEffect(() => {
      setIsFetching(true);
      setAllMembersParams((prevData) => ({
        ...prevData,
        numPage: page,
        typeRequest:typeRequest,
        statusRequest:statusRequest,
        key:searchValue,
      }));
  
    }, [searchValue, page,typeRequest,statusRequest ]);

    const fBc: FilterBarContentProps = {
        label: 'Contributeurs',
        searchLength: totalNumber,
        buttons: [
          {
            icon: StarSvg,
            label: 'Approuvés',
            isSelected: selectedButton === 'approuve',
            handleClick: () => {
              if (selectedButton === 'approuve') return;
              if (page > 1) {
                setPage(1);
              }
              setRequestMembersData([]);
              setSelectedButton('approuve');
              setTypeRequest('');
              setStatusRequest('ACCEPT')
            }
          },
          {
            icon: PeopleSvg,
            label: 'Invitations',
            isSelected: selectedButton === 'invitations',
            handleClick: () => {
              if (selectedButton === 'invitations') return;
              if (page > 1) {
                setPage(1);
              }
              setRequestMembersData([]);
              setSelectedButton('invitations');
              setTypeRequest('INVITATION');
              setStatusRequest('Brouillon');
            }
          },
          {
            icon: TimeSvg,
            label: 'Demandes',
            isSelected: selectedButton === 'demandes',
            handleClick: () => {
              if (selectedButton === 'demandes') return;

              if (page > 1) {
                setPage(1);
              }
              setRequestMembersData([]);
              setSelectedButton('demandes');
              setTypeRequest('DEMANDE');
              setStatusRequest('Brouillon');

            }
          }
        ],
    }

useEffect(()=>{console.log("typeRequest",typeRequest)},[typeRequest])
    const fBi: FilterBarInputProps = {
        filterBarInputId: 'search',
        filterBarInputPlaceHolder: 'Rechercher',
        filterBarInputType: 'text',
        filterBarInputValue: searchValue,
        ...((typeRequest == 'DEMANDE' || typeRequest == 'INVITATION' )? {} : {
          filterButton: {
            handleClick: () => {
            },
            icon: FilterSvg,
            label: 'Filtres',
            selected: false
          }
        }),
        isGridDisplayMode: false,
        itemsFilterBarSort: {
          label: 'Trier par:',
          options: [
            {
              value: 'popular',
              label: 'Populaire'
            },
            {
              value: 'recent',
              label: 'Récent'
            }
          ],
          selectedValue: {},
          setSelectedValue: () => {
            
          }
        },
        onChange: (e) => {
          if (page > 1) {
            setPage(1);
          }
          setRequestMembersData([]);
          setSearchValue(e);
        },
        onClear: () => {
          if (searchValue == '') return;
          if (page > 1) {
            setPage(1);
          }
          setRequestMembersData([]);
          setSearchValue('');
        },
        onSearch: (e) => {
          if (page > 1) {
            setPage(1);
          }
          setRequestMembersData([]);
          setSearchValue(e);
        },
       
        
      };

      useEffect(()=>{ 
        if (getEserviceMembersList.isFetching) {
          setIsFetching(true);
        }
        if (getEserviceMembersList.isSuccess) {
          const membersListData = getEserviceMembersList.data;
          const totalItems = getEserviceMembersList.data.body.pagination.totalLength;

          if (membersListData) {
            if (totalItems !== undefined) {
              setTotalNumber((prev) => (totalItems !== prev ? totalItems : prev));
              setRequestMembersData(membersListData.body.data);

            }
            setIsFetching(false);

          }
        return;
      }
      
      },[getEserviceMembersList.fetchStatus]) 

      useEffect(()=>{
        if(postAcceptJoinEservice.isPending)
          { messageApi.loading({
            content: 'Loading...',
            duration: 0
          });
  
          }
        if(postAcceptJoinEservice.customHandler.isSuccess)
          {
            messageApi.destroy(); 
            messageApi.success({
              content: 'Votre demande a été approuvé avec succès',
              duration: 2.5
            });
          } 
        if(postAcceptJoinEservice.customHandler.isError)
          {
            messageApi.destroy();
  
            messageApi.error({
              content: postAcceptJoinEservice.customHandler.errorMessage,
              duration: 2.5
            });
          }
       
    
        },[postAcceptJoinEservice.status]);

        useEffect(()=>{

          if(postRefuseJoinEservice.isPending)
            { messageApi.loading({
              content: 'Loading...',
              duration: 0
            });
    
            }
          if(postRefuseJoinEservice.customHandler.isSuccess)
            {
              messageApi.destroy(); 
              messageApi.success({
                content: 'La demande a été refusé avec succès',
                duration: 2.5
              });
            } 
          if(postRefuseJoinEservice.customHandler.isError)
            {
              messageApi.destroy();
    
              messageApi.error({
                content: postRefuseJoinEservice.customHandler.errorMessage,
                duration: 2.5
              });
            }
         
      
          },[postRefuseJoinEservice.status]);
  useEffect(()=>{console.log(selectedRowOption)},[selectedRowOption])
    return(
          <div className="flex flex-col gap-7 p-5 h-[100%]">

            {contextHolder}

<FilterBar
      filterBarContent={fBc}
      filterBarInput={fBi}
      filterBarSortBy={fBsEserv}
    />
{requestMembersData.length < 1 ? <EmptyData
                            icon={emptyIcon}
                            title={"Aucun pour l'instant"}
                            description={"La liste sera affichée ici"}
                        /> : <>{selectedButton == 'approuve' ? 
                            
                                <Table
                                  isFitching={isFetching}
                                  type="memberApprouve"
                                  setCurrentPage={() => {}}
                                  currentPage={0}
                                  totalItems={totalNumber}
                                  onSelectDropDownForMembers={setSelectedRowOption}
                                  headerItems={{
                                    items: ['Contibuteur', '', 'login', 'Role', 'Activation']
                                  }}
                                  rows={requestMembersData}
                                /> 
                        :
                                <Table
                                isFitching={getRequestJoinEserviceList.isFetching}
                                type='contributeurNonApprouve'
                                setCurrentPage={() => {}}
                                currentPage={0}
                                totalItems={totalNumber}
                                onSelectDropDownForMembers={setSelectedRowOption}
                                headerItems={{
                                  items: [ 'Contibuteur','', 'login','Note']
                                }}
                                rows={requestMembersData}
                              />
                              
                              
                                 }

</>
}
</div>);
}