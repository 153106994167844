import { useMutation } from '@tanstack/react-query';
import {
  postPasswordResetRequest,
  postPasswordResetVerifyCode,
  postPasswordResetSubmit
} from './api';
import { PasswordResetRequest, PasswordResetConfirm, PasswordResetSubmit } from '../../types';
import { useHandleStandardResult } from '../../../../shared/hooks';

export const usePostPasswordResetRequest = () => {
  const mutationQuery = useMutation({
    mutationFn: (data: PasswordResetRequest) => postPasswordResetRequest(data)
  });

  const handleMapDataResult = useHandleStandardResult(mutationQuery);
  return { ...mutationQuery, customHandler: handleMapDataResult };
};

export const usePostPasswordResetVerifyCode = () => {
  const mutationQuery = useMutation({
    mutationFn: (data: PasswordResetConfirm) => postPasswordResetVerifyCode(data)
  });

  const handleMapDataResult = useHandleStandardResult(mutationQuery);
  return { ...mutationQuery, customHandler: handleMapDataResult };
};

export const usePostPasswordResetSubmit = () => {
  const mutationQuery = useMutation({
    mutationFn: (data: PasswordResetSubmit) => postPasswordResetSubmit(data)
  });

  const handleMapDataResult = useHandleStandardResult(mutationQuery);
  return { ...mutationQuery, customHandler: handleMapDataResult };
};
