import {
  ERROR_STATUS,
  ERROR_STATUS_CODES,
  SUCCESS_STATUS_CODES
} from '../utils/constants';
import type {
  UseMutationResult,
  UseQueryResult
} from '@tanstack/react-query/src/types';
import { GenericResponseWrapper } from '../types';

export const useHandleStandardResult = <T, R>(
  reactQuery:
    | UseMutationResult<GenericResponseWrapper<R>, Error, T>
    | UseQueryResult<GenericResponseWrapper<R>, Error>
) => {
  const isSuccess = reactQuery?.data
    ? SUCCESS_STATUS_CODES.includes(reactQuery?.data?.data?.status)
    : undefined;
  const isError = reactQuery?.data
    ? ERROR_STATUS_CODES.includes(reactQuery?.data?.data?.status)
    : reactQuery.isError ?? undefined;
  const isErrorMessageExist =
    isError &&
    reactQuery?.data?.data.message?.messageType === ERROR_STATUS &&
    reactQuery?.data?.data.message?.messageContent !== null;
  const errorMessage = isErrorMessageExist
    ? reactQuery?.data?.data.message?.messageContent
    : reactQuery.error?.message ?? undefined;
  const data = reactQuery?.data?.data?.body;
  const state = reactQuery?.data?.data?.state ?? undefined;

  return { isSuccess, isError, isErrorMessageExist, errorMessage, data ,state };
};
