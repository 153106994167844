import {AbstractSVGIcon} from "../../../../../shared/components/icons";
import {FC} from "react";
import {BaseSVGIconProps} from "../../../../../shared/types";

export const GichetIcon: FC<BaseSVGIconProps> = (props) => {
    return (<AbstractSVGIcon
        size={props.size}
        className={props.className}
        spin={props.spin}
        hidden={props.hidden}
        viewBox="0 0 18 15"
    >
        <path
            d="M9.00033 3.33333V0H0.666992V15H17.3337V3.33333H9.00033ZM7.33366 13.3333H2.33366V11.6667H7.33366V13.3333ZM7.33366 10H2.33366V8.33333H7.33366V10ZM7.33366 6.66667H2.33366V5H7.33366V6.66667ZM7.33366 3.33333H2.33366V1.66667H7.33366V3.33333ZM15.667 13.3333H9.00033V5H15.667V13.3333ZM14.0003 6.66667H10.667V8.33333H14.0003V6.66667ZM14.0003 10H10.667V11.6667H14.0003V10Z"
        />
    </AbstractSVGIcon>)
}
