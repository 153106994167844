
import { EserviceMembersListResponse } from "../types/data.types";

export const ESERVICE_WIDGET_MOCK_DATA: EserviceMembersListResponse[] = [
    {
      emailPersonnel: "a.jalal@ram.co.ma",
      loginUser: "alem.jalal@karaz.org",
      logoUser: "",
      nameUser: "Jalal Alem",
      note: "",
      roleStatusUser: "",
      roleUserApproved: "",
      roleUserDemanded: "",
      statusRequest: "",
      typeRequest: "",
      idRequest: "",
      role: ""
    },
    {
      emailPersonnel: "hamza.amrani@ram.co.ma",
      loginUser: "hamza.amrani@karaz.org",
      logoUser: "",
      nameUser: "Hamza Amrani EL ALAMI",
      note: "",
      roleStatusUser: "",
      roleUserApproved: "",
      roleUserDemanded: "",
      statusRequest: "",
      typeRequest: "",
      idRequest: "",
      role: ""
    },
    {
      emailPersonnel: "ines.hermouch@ram.co.ma",
      loginUser: "hermouch.ines@karaz.org",
      logoUser: "",
      nameUser: "Ines Hermouch",
      note: "",
      roleStatusUser: "",
      roleUserApproved: "",
      roleUserDemanded: "",
      statusRequest: "",
      typeRequest: "",
      idRequest: "",
      role: ""
    },
    {
      emailPersonnel: "i.jabbouri@ram.co.ma",
      loginUser: "ilham.jabbouri@karaz.org",
      logoUser: "",
      nameUser: "Ilham Jabbouri",
      note: "",
      roleStatusUser: "",
      roleUserApproved: "",
      roleUserDemanded: "",
      statusRequest: "",
      typeRequest: "",
      idRequest: "",
      role: ""
    },
    {
      emailPersonnel: "souhail.hamiddi@ram.co.ma",
      loginUser: "souhail.hamiddi@karaz.org",
      logoUser: "",
      nameUser: "Souhail Hamiddi",
      note: "",
      roleStatusUser: "",
      roleUserApproved: "",
      roleUserDemanded: "",
      statusRequest: "",
      typeRequest: "",
      idRequest: "",
      role: ""
    },
    {
      emailPersonnel: "ayoub.senouss@ram.co.ma",
      loginUser: "ayoub.senouss@karaz.org",
      logoUser: "",
      nameUser: "Ayoub Senouss",
      note: "",
      roleStatusUser: "",
      roleUserApproved: "",
      roleUserDemanded: "",
      statusRequest: "",
      typeRequest: "",
      idRequest: "",
      role: ""
    },

   
]
   


