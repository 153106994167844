// src/features/eservices/eservicesSlice.ts
import { createSlice, PayloadAction} from '@reduxjs/toolkit';
import { AllGuichetsRequest } from '../../types';
import { Eservice } from '../../components/ui/cards/CardComponents/CardEservice';

interface EsrvicesState {
  eservices: Eservice[];
  selectedEservice: Eservice|object;
  totalEservices: number;
  filters: AllGuichetsRequest;
  page: number;
}

const initialState: EsrvicesState = {
  eservices: [],
  selectedEservice:{},
  totalEservices: 0,
  filters: {
    numPage: 1,
    pageSize: 10,
    contributeur: false,
    key: '',
    name: '',
    status: '',
    statutRole: '',
    reference: '',
    domainName: '',
    email: '',
    activation: '',
    verification: '',
    tags: '',
    type: '',
    role: '',
    favorite: false,
    industry: '',
    entity: '',
    recent: '',
    Fields: 'ALL',
  },
  page: 1,
};

const eservicesSlice = createSlice({
  name: 'eservice',
  initialState,
  reducers: {
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    updateFilters(state, action: PayloadAction<Partial<AllGuichetsRequest>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
    setEservices(state,action:PayloadAction<Eservice[]>){
        state.eservices = action.payload
    },
    resetEservices(state) {
      state.eservices = [];
    },
    setSelectedEservice(state,action: PayloadAction<Eservice>){
        state.selectedEservice = action.payload;
    },
    setTotalEservices(state,action: PayloadAction<number>){
        state.totalEservices = action.payload;
    }
  }
});

export const { setPage, updateFilters, resetEservices,setSelectedEservice,setEservices,setTotalEservices } = eservicesSlice.actions;
export default eservicesSlice.reducer;
