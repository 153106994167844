import {useForm} from "react-hook-form";
import {OnboardingQARequest, OnboardingQAResponse} from "../../../../types";
import {yupResolver} from "@hookform/resolvers/yup";
import partyImage from "../../../../assets/images/party.png";
import styles from "./steps.module.css";
import {Select} from "../../../../../../shared/components/controlled-ui";
import {Button} from "kls-ui";
import {FC} from "react";
import classNames from "classnames";
import {QAStepSchema} from "../../../../holders/schemas";
import {NotificationCard, SimpleCard} from "../../../../../../shared/components";
import {NotificationType} from "../../../../../../shared/utils/enums";
import {useHandleTranslation} from "../../../../../../shared/hooks";

interface OnboardingQAStepApiStatus {
    onboardingQuestions: boolean | undefined;
    postAnswers: boolean | undefined;
}
interface QAStepProps {
    data: OnboardingQAResponse | undefined;
    onSubmit: (data: OnboardingQARequest) => void;
    isLoading: OnboardingQAStepApiStatus;
    isError?: OnboardingQAStepApiStatus;
    submitError?: string | undefined;
    rootClassName?: string;
}

export const QAStep: FC<QAStepProps> = (props) => {
    const { rootClassName = "" } = props;
    const { t:tO } = useHandleTranslation("window");

    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useForm<OnboardingQARequest>({
        resolver: yupResolver(QAStepSchema)
    });

    const rootStyle = classNames({
        ["flex flex-col gap-9 py-6"]: true,
    });

    const cardStyle = classNames({
        [rootClassName]: true,
        ["w-1/2"]: true,
    });

    const notificationErrorStyles = classNames({
        ["absolute"]: true,
        ['notShow']: !props.isError?.postAnswers,
        ['show transition-all duration-500']: props.isError?.postAnswers
    });

    const QADescriptionStyles = classNames({
        [styles.QAStepDescription]: true,
        ['notShow']: props.isError?.postAnswers,
    });
    return (
        <SimpleCard spin={props.isLoading.postAnswers} className={cardStyle}>
            <div className={rootStyle}>
                <div className="flex flex-col items-center gap-3">
                    <img className={"size-20"} src={partyImage} alt=""/>
                        <div className="flex w-full justify-center">
                            <p className={styles.QAStepTitle}>
                                {tO('qa.step_title')}
                            </p>
                        </div>
                    <div className="relative w-4/5 flex justify-center pb-6">
                        <p className={QADescriptionStyles}>
                            {tO('qa.step_description')}
                        </p>
                        <NotificationCard
                            type={NotificationType.ERROR}
                            className={notificationErrorStyles}>
                            <>
                                {(props.submitError && props.submitError)}
                            </>
                        </NotificationCard>
                    </div>
                </div>
                <div className="flex flex-col gap-3">
                    <div className="flex flex-col gap-2">
                        <Select
                            label={tO('qa.detection_label')}
                            control={control}
                            error={errors.detection?.message}
                            size="lg"
                            name="detection"
                            placeholder="Chosissez un élément"
                            options={props.data?.detections || []}
                        />

                        <Select
                                control={control}
                                error={errors.usage?.message}
                                name="usage"
                                size="lg"
                                label={tO('qa.usage_label')}
                                placeholder="Chosissez un élément"
                                options={props.data?.usages || []}
                                alwaysClose
                            />

                            <Select
                                control={control}
                                size="lg"
                                label={tO('qa.role_label')}
                                error={errors.role?.message}
                                name="role"
                                options={props.data?.roles || []}
                                placeholder="Chosissez un élément"
                                alwaysClose
                            />
                    </div>
                    <div className={styles.QAStepActionContainer}>
                        <Button
                            label={tO('qa.submit_button')}
                            color="primary"
                            size="sm"
                            styling="solid"
                            args={{ onClick: handleSubmit(props.onSubmit) }}
                        />
                    </div>
                </div>
            </div>
        </SimpleCard>
    );
};
