import {ConfigProvider} from "antd";
import type {RadioProps as AntdRadioProps} from "antd"
import {Radio as AntdRadio} from "antd"
import {FC} from "react";
interface RadioProps extends AntdRadioProps {
    label:string
}
export const Radio:FC<RadioProps>=(props)=>{
    return <>
        <ConfigProvider>
                <AntdRadio {...props}>{props.label}</AntdRadio>
        </ConfigProvider>
    </>
}