import {Controller, FieldPath, FieldValues} from "react-hook-form";
import {ControlledUiProps,InputPhoneProps} from "../../types";
import {InputPhone as NativeInputPhone} from "../input/input-phone/InputPhone";

export interface ControlledInputPhoneProps<TFieldValues extends FieldValues,TName extends FieldPath<TFieldValues>>
    extends InputPhoneProps, ControlledUiProps<TFieldValues,TName>{}

export const InputPhone  = <
    TFieldValues extends FieldValues,
    TName extends FieldPath<TFieldValues>
>
(props:ControlledInputPhoneProps<TFieldValues, TName>) => {
    return (
        <Controller
            control={props.control}
            name={props.name}
            render={({ field: { onChange,ref } }) => (
                <NativeInputPhone
                    {...props}
                    ref={ref}
                    onChange={onChange}
                    errorMessage={props.error}                />
            )}
        />
    );
};
