import {BadgeLogo} from "../badge-logo/BadgeLogo";
import RabbitImage from '../../../../../assets/images/gamification-badges/Rabbit.png'
import RaccoonImage from '../../../../../assets/images/gamification-badges/Raccoon.png'
import BullImage from '../../../../../assets/images/gamification-badges/Bull.png'
import GorillaImage from '../../../../../assets/images/gamification-badges/Gorilla.png'
import BabouinImage from '../../../../../assets/images/gamification-badges/Babouin.png'
import LionImage from '../../../../../assets/images/gamification-badges/Lion.png'
import OwlImage from '../../../../../assets/images/gamification-badges/Owl.png'
import PandaImage from '../../../../../assets/images/gamification-badges/Panda.png'
import BearImage from '../../../../../assets/images/gamification-badges/Bear.png'
import {Swiper} from "../../../../../../../shared/components";



export const EarnedBadges=()=>{

    return (
        <div className="flex gap-2 items-center">
            <Swiper maxItems={8}>
                <BadgeLogo backgroundColor="#0EA5E9" logo={RabbitImage} label="rapide"/>
                <BadgeLogo backgroundColor="#D946EF" logo={BabouinImage} label="Agile"/>
                <BadgeLogo backgroundColor="#10B981" logo={LionImage} label="Puissant" obtained/>
                <BadgeLogo backgroundColor="#F59E0B" logo={OwlImage} label="Sage"/>
                <BadgeLogo backgroundColor="#F43F5E" logo={RaccoonImage} label="Bosseur" obtained />
                <BadgeLogo backgroundColor="#06B6D4" logo={BullImage} label="Fonceur"/>
                <BadgeLogo backgroundColor="#57534e" logo={GorillaImage} label="Fort" obtained />
                <BadgeLogo backgroundColor="#9333ea" logo={BearImage} label="Protecteur"/>
                <BadgeLogo backgroundColor="#7c3aed" logo={PandaImage} label="Calme"/>
            </Swiper>

    </div>)
}