import { FC } from 'react';
import { BaseSVGIconProps } from '../../types/components.type';
import AbstractSVGIcon from './AbstractSVGIcon';
const ListSvg: FC<BaseSVGIconProps> = (props) => (
  <AbstractSVGIcon
    size={props.size}
    className={props.className}
    spin={props.spin}
    hidden={props.hidden}
    viewBox="0 0 14 10">
    <path d="M1.83334 4C1.28 4 0.833336 4.44667 0.833336 5C0.833336 5.55333 1.28 6 1.83334 6C2.38667 6 2.83334 5.55333 2.83334 5C2.83334 4.44667 2.38667 4 1.83334 4ZM1.83334 0C1.28 0 0.833336 0.446667 0.833336 1C0.833336 1.55333 1.28 2 1.83334 2C2.38667 2 2.83334 1.55333 2.83334 1C2.83334 0.446667 2.38667 0 1.83334 0ZM1.83334 8C1.28 8 0.833336 8.45333 0.833336 9C0.833336 9.54667 1.28667 10 1.83334 10C2.38 10 2.83334 9.54667 2.83334 9C2.83334 8.45333 2.38667 8 1.83334 8ZM3.83334 9.66667H13.1667V8.33333H3.83334V9.66667ZM3.83334 5.66667H13.1667V4.33333H3.83334V5.66667ZM3.83334 0.333333V1.66667H13.1667V0.333333H3.83334Z"/>

  </AbstractSVGIcon>
);
export default ListSvg;
