import styles from './landing.module.css';
import facebookLogo from '../../../../shared/assets/icons/facebook.svg';
import linkedinLogo from '../../../../shared/assets/icons/linkedin.svg';


export const FooterComponent = () => {
    return (
      <footer className={styles.footer}>
        <div className={styles.footerLeft}>
          <img src="/pictures/ksw-white.svg" alt="Logo" className={styles.footerLogo} />
          <p className={styles.footerMadeby}>&copy;2024, Made with &#10084; by Ribatis
          </p>
        </div>
  
        <div className={styles.footerLinks}>
          <div className={styles.footerColumn}>
            <h4>Services</h4>
            <a href="/route1">Référentiel e-services</a>
            <a href="/route2">Guichets uniques</a>
            <a href="/route3">Suivi dossier</a>
          </div>
          <div className={styles.footerColumn}>
            <h4>Liens rapides</h4>
            <a href="/route4">Qui sommes-nous</a>
            <a href="/route5">Découvrir comment ça marche </a>
            <a href="/route6">FAQ </a>
          </div>
          <div className={styles.footerColumn}>
            <h4>Partenaire
            </h4>
            <a href="/route7">Offre</a>
            <a href="/route8">Produits</a>
            <a href="/route9">Fonctionnalités</a>
          </div>
          <div className={styles.footerColumn}>
            <h4>Support</h4>
            <a href="/route10">Guides et Tutoriels </a>
            <a href="/route11">Base de connaissance  </a>
            <a href="/route12">Communauté</a>
            <a href="/route12">Contacter le support   </a>
          </div>
        </div>
  
        <div className={styles.footerSocial}>
        <a href="https://www.linkedin.com/company/ribatis" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
          <img src={linkedinLogo}/>

          </a>
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
          <img src={facebookLogo}/>
          </a>
          
        </div>
      </footer>
    );
  };
  
export default FooterComponent;