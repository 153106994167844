import {FC} from "react";
import {InputText} from "../input-text/InputText";
import {MaterialIcon} from "../../icons";
import {InputTextProps} from "../../../types";

export const InputSearch:FC<InputTextProps>=(props)=>{
    const SearchIcon=<MaterialIcon  label="search" center className="text-slate-400 text-base"/>
    return (
        <InputText {...props}  afterText={SearchIcon} clearable/>
    )
}