import {FC, ReactNode} from "react";
import styles from "./layout.module.css";

interface StepsHeader{
    icon:ReactNode;
    title:string;
    description:string;
}
export const StepsHeader:FC<StepsHeader>=(props)=>{
    return (
        <div className="flex gap-4 pt-6 items-center">
            {props.icon}
            <div className="flex flex-col ">
                <p className={styles.title}>{props.title}</p>
                <p className={styles.description}>{props.description}</p>
            </div>
        </div>
    )
}
