import {FC,MouseEvent} from "react";
import classNames from "classnames";

interface MaterialIconProps {
    label:string
    className?:string
    center?:boolean
    onClick?:(e:MouseEvent<HTMLElement>)=>void
}

export const MaterialIcon:FC<MaterialIconProps>=(props)=>{
    const {center=false,onClick=(()=>{})}=props
  const {className=""}=props;
    const rootStyle=classNames({
        ["material-icon"]:!center,
        ["material-flex-icon"]:center,
        [className]:true
    })
    return <i className={rootStyle} onClick={onClick}>{props.label}</i>
}