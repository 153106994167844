import {IdentityCreateOrganizationApiRequest, IdentityCreateOrganizationRequest} from "../../types";

export const mapCreateOrganizationRequest=(data:IdentityCreateOrganizationRequest):IdentityCreateOrganizationApiRequest=>{
    return {
        name: data.name,
        acronyme:data.acronym,
        site:data.webSite,
        email:data.email,
        phoneNumber:data.phoneNumber,
        identifiant:data.identifier,
        typeAdress:data.coordinates.longitude && data.coordinates.latitude ? "Physique":"Virtuelle",
        logoAsBase64:data.logo.base64,
        logoFormat:data.logo.format,
        description: data.description,
        guichetId:data.windowId,
        longitude:data.coordinates.longitude,
        latitude:data.coordinates.latitude,
        type:"ORGANISM"
    };
}