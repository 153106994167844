import { FC, useCallback } from 'react';
import { HeaderTable, HeaderTableProps } from '../table-items/HeaderTable.tsx';
import { RowTable } from '../table-items/RowTable.tsx';
import styles from './table.module.css';
import { Pagination } from 'antd';

import {
  CARD_DROPDOWN_ADMIN,
  CARD_DROPDOWN_ALL,
  CARD_DROPDOWN_CITIZEN,
  CARD_DROPDOWN_CONTRIBUTOR_AD_CD,
  CARD_DROPDOWN_NO_ROLE
} from '../../../utils/constants/components.constants.tsx';
import { DynamicListData } from '../../../../../shared/types/data.type.ts';
import { RowSkeleton } from '../table-items/RowSkeleton.tsx';
import { Guichet } from '../cards/base-cards/CardGuichet.tsx';
import { Eservice } from '../cards/base-cards/CardEservice.tsx';
import { RowTableMember } from '../table-items/RowTableMember.tsx';
import { EserviceMembersListResponse, FavoriteEserviceRequest, FavoriteGuichetRequest, ListRequestJoinEserviceData } from '../../../types/index.ts';
import { RowTableSelectable } from '../table-items/RowTableSelectable.tsx';
export interface TableProps {
  headerItems: HeaderTableProps;
  rows: Guichet[] | Eservice[] | ListRequestJoinEserviceData[]| EserviceMembersListResponse[];
  totalItems: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  setFavoriteParams?: (params: FavoriteGuichetRequest) => void;
  onSelectDropDown?: (selection: { id: string; selectedValue: string }) => void;
  onSelectDropDownForMembers?: (selection: {
    id: string,
    selectedValue: string,
    emailPersonnel:string,
    nameUser:string,
    logoUser:string,
    role:string,
    ownerLogin:string,
    note:string,
  }) => void;
  setFavoriteEservice?:(params:FavoriteEserviceRequest)=>void;
  type: 'eservice' | 'guichet'  | 'memberApprouve' | 'memberNonApprouve' |'contributeurNonApprouve' | 'users'|'selectable';
  isFitching?: boolean | false;
  dataDomaine?: DynamicListData[] | undefined;
  selectedItems?: Array<Eservice>;
  setSelectedItems?: (items: Array<Eservice>) => void;
}
export const Table: FC<TableProps> = (props) => {
  const getDropDownData = useCallback((role: string[] | string | undefined) => {
    if (!role) return CARD_DROPDOWN_NO_ROLE;

    if (Array.isArray(role)) {
      if (role.includes('CONTRIBUTEUR') && role.includes('CITIZEN DEVELOPER')) {
        return CARD_DROPDOWN_CONTRIBUTOR_AD_CD;
      }
      if (role.includes('CONTRIBUTEUR') && role.includes('Administrateur')) {
        return CARD_DROPDOWN_CONTRIBUTOR_AD_CD;
      }
      
      if (role.includes('Administrateur')) {
        return CARD_DROPDOWN_ADMIN;
      }

      if (role.includes('CITIZEN DEVELOPER')) {
        return CARD_DROPDOWN_CITIZEN;
      }
    }
  
    return CARD_DROPDOWN_ALL;
}, []);

const handleSelectItem = (item:Eservice, isSelected: boolean) => {
  if(props.selectedItems && props.setSelectedItems){
  const updatedSelection = isSelected
    ? [...props.selectedItems, item]
    : props.selectedItems.filter((selected) => selected.id !== item.id);

  props.setSelectedItems(updatedSelection);
  }
};
  return (
    <>
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead className={styles.header}>
            <HeaderTable items={props.headerItems.items} />
          </thead>
          <tbody className={styles.body}>
            {!props.isFitching
              ? props.rows.map(
                  (
                    row: Guichet | Eservice | ListRequestJoinEserviceData|EserviceMembersListResponse,
                    index: number
                  ) => {
                    if ('role' in row && props.type === 'guichet') {
                      return (
                        <RowTable
                          item={row as Guichet}
                          onSelectDropDown={props.onSelectDropDown}
                          setFavoriteParams={props.setFavoriteParams}
                          key={index}
                          icon={
                            props.dataDomaine &&
                            (row as Guichet).industry &&
                            props.dataDomaine.filter(
                              (domaine) =>
                                domaine.label === (row as Guichet).industry
                            )[0]?.icon
                              ? props.dataDomaine.filter(
                                  (domaine) =>
                                    domaine.label === (row as Guichet).industry
                                )[0].icon
                              : undefined
                          }
                          dropDownData={getDropDownData(row.role)}
                        />
                      );
                    } else if (props.type === 'eservice') {
                      return (
                        <RowTable
                          item={row as Eservice}
                          setFavoriteEservice={props.setFavoriteEservice}
                          key={index}
                          onSelectDropDown={props.onSelectDropDown}
                          dropDownData={getDropDownData(row.role)}

                        />
                      );
                    }else if(props.type === 'selectable' && props.selectedItems && 'id' in row) {  
                      return(
                        <RowTableSelectable
                        key={index}
                        item={row as Eservice}
                        isSelected={props.selectedItems.some((selected) => selected.id === row.id )}
                        onSelect={handleSelectItem}
                      />
                      );

                    }
                      else if (props.type === 'memberApprouve' || props.type === 'memberNonApprouve' ||  props.type === 'contributeurNonApprouve' || props.type ==='users')
                        return (
                          <RowTableMember
                            item={row as EserviceMembersListResponse}
                            key={index}
                            onSelectDropDown={props.onSelectDropDownForMembers }
                            type={props.type}                          />
                        );
                          
                    return;
                  }
                )
              : Array.from({ length: 6 }).map((_, index) => (
                  <RowSkeleton key={index}/>
                ))}
          </tbody>
        </table>
      </div>
      {props.totalItems > 10 && <Pagination
        onChange={(page: number) => {
          props.setCurrentPage(page);
        }}
        showSizeChanger={false}
        pageSize={10}
        total={props.totalItems}
        current={props.currentPage}
      />}
    </>
  );
};
