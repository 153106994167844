import classNames from 'classnames';
import styles from './outer-container.module.css'
import { FC, ReactNode } from 'react';

interface  OuterContainerProps{
  children:ReactNode;
}
export const OuterContainer:FC<OuterContainerProps>=(props)=>{
  const rootStyle=classNames({
    [styles["outer-container-css-var"]]:true,
    [styles.root]:true
  })
 return (
   <div className={rootStyle}>
     {props.children}
  </div>
 )
}
