import  {FC, ReactNode} from "react";
import styles from './cards.module.css'
import classNames from "classnames";

interface OrganisationSelectCardProps{
    title:string;
    children:ReactNode
}
const rootStyles=classNames({
    [styles.orgCardSelectedContainer]:true,
    [styles["onboarding-card-css-var"]]:true
})
export const WindowWrapperSelectCard:FC<OrganisationSelectCardProps>=(props)=> {
    return (
        <div className={rootStyles}>
            <div className={styles.orgSelectCardTitle}>
                <i className="material-icon !text-md ">flare</i>
                <div>{props.title}</div>
            </div>
            <div className="flex flex-col gap-2 py-3 px-2">
                {props.children}
            </div>
        </div>
    )

}