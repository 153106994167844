import ReactDOM from 'react-dom/client';
import './shared/styles/index.css';
import './shared/styles/colors.css';
import './shared/styles/variables.css';
import App from './App';
import './i18n.ts';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import "react-leaflet-fullscreen/styles.css";
import {KSWAntdProvider} from "./shared/context";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


root.render(
    <KSWAntdProvider>
    <App />
    </KSWAntdProvider>);
