import {ApiJaxRsResponse} from "../../types";

export const REMOTE_ACTION_BASE_PATH = '/karazal/kas/FeedBackAction';
export const ERROR_STATUS_CODES = [
  400, 401, 402, 403, 404, 405, 406, 407, 408, 409, 410, 411, 412, 413, 414,
  415, 416, 417, 418, 422, 423, 424, 425, 426, 428, 429, 431, 451, 500, 501,
  502, 503, 504, 505, 506, 507, 508, 510, 511
];
export const SUCCESS_STATUS_CODES = [
  200, 201, 202, 203, 204, 205, 206, 207, 208, 226
];
export const ERROR_STATUS = 'ERROR';


export const DYNAMIC_LIST_API_PATH:ApiJaxRsResponse ={
  path:"/karazal/ow-kls-extrat/appmanager/dynamicList",
  apiKey: "AB90G-BH903-W4EE1-Z66Q9-907E6"
}
