import {FC} from "react";
import classNames from "classnames";

interface ProfileSwitcherItemProps{
id:number
avatar:string;
selected:boolean;
setSelectStatus:(value:number) => void;
title?:string;
onClick:()=>void;
}
export const ProfileSwitcherItem:FC<ProfileSwitcherItemProps>=(props)=>{
   const sharedStyle="h-16  aspect-square rounded-full opacity-20";
   const select=()=>props.setSelectStatus(props.id);
   
   const imgStyle = classNames(sharedStyle, {
    "!opacity-100": props.selected,
 });
   const selectedProfileEffectStyle=classNames({
    [sharedStyle]:true,
    ["absolute text-xs whitespace-nowrap"]:true,
    ["show transition-all duration-300 !opacity-100"]  :props.selected

   })

    return (<>
        <div className="relative aspect-square rounded-full cursor-pointer border-2 border-slate-200" onClick={select}>
            <img src={props.avatar} className={imgStyle}/>
            <div style={{marginLeft:"-2.5rem"}} className={selectedProfileEffectStyle }></div>
        </div>
        </>
    )
}