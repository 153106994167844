import { MapContainer, TileLayer, ZoomControl } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import './map.css';
import { FC } from 'react';
import { MarkerClusterContainer } from './mapComponents/MarkerClusterContainer';
import { FullscreenControl } from 'react-leaflet-fullscreen';
import { LocateControl } from './mapComponents/LocateControl';
import { FilterControl } from './mapComponents/FilterControl';
import { HomeControl } from './mapComponents/HomeControl';
import { FilterBarSortByProps } from '../../../modules/portal/components/ui/filter-bar/FilterBarSortBy';
import { MarkerControl } from './mapComponents/MarkerControl';
import { Guichet } from '../../../modules/portal/components/ui/cards/base-cards/CardGuichet';

interface MapProps {
  position?: L.LatLngLiteral;
  onChoosePosition?: (position: L.LatLngLiteral) => void;
  markersData?: Guichet[];
  filtersData?: FilterBarSortByProps;
  isFetching?: boolean;
  clearFilters?: () => void;
  openAction?: (selection: { id: string; selectedValue: string }) => void;
}

export const Map: FC<MapProps> = (props) => {
  return (
    <div className="relative w-full h-[500px]">
      {props.isFetching && (
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
          <div className="loader"></div>
        </div>
      )}
      <MapContainer
        className="w-full h-full z-0 rounded-md fullscreen-css-var"
        center={
          props.position
            ? [props.position.lat, props.position.lng]
            : [31.7917, -7.0926]
        }
        zoom={5}
        maxZoom={17}
        zoomControl={false}>
        <ZoomControl position="bottomright" />
        <FullscreenControl position="topright" />
        {props.filtersData && <FilterControl items={props.filtersData.items} />}
        <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}" />
        <LocateControl />
        {props.onChoosePosition && (
          <>
            <MarkerControl setPosition={props.onChoosePosition} />
          </>
        )}
        {props.markersData && props.markersData.length > 0 && (
          <MarkerClusterContainer
            openAction={props.openAction}
            markersData={props.markersData}
          />
        )}
        <HomeControl
          clearFilters={props.clearFilters}
          defaultCenter={[31.7917, -7.0926]}
          defaultZoom={5}
        />
      </MapContainer>
    </div>
  );
};
