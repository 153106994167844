import { object, string} from "yup";

export const IdentityOrganizationSchema = object().shape({
    name: string().required('Le nom est obligatoire.'),
    acronym: string().required('L’acronyme est obligatoire.'),
    description: string().required('La description est obligatoire.'),
    email: string().required('L’email est obligatoire.'),
    phoneNumber: string().required('Le numéro de téléphone est obligatoire.'),
    identifier: string().required('L’ID de l’organisation est obligatoire.'),
    webSite: string().required('Le site web est obligatoire.'),
    type: string().required('Le type est obligatoire.'),
});