import { FileUploadStateErrors } from "../types";
import {useState} from "react";

export const useHandleFile = (
    file: File | undefined,
    [minSize, maxSize]: [number, number],
    allowedExtensions: string[],
) => {
    const [image64,setImage64]=useState<string>();
    let errors: FileUploadStateErrors | undefined = undefined;
    let isError :boolean|undefined= undefined;
    let isSuccess :boolean|undefined = undefined;
    let isFileValid = true;
    let format = undefined;
    let url = undefined;


    if (!file) {
        isFileValid = false;
        return { url, image64, isFileValid,isSuccess,errors, isError };
    }

    format = file.type;
    if (!allowedExtensions.includes(file.type)) {
        errors = { isAllowedFormatError: true, isResolutionError: undefined, isSizeError: undefined };
        isError = true;
        isSuccess=false;
    }
    if (file.size < minSize || file.size > maxSize) {
        errors = { isAllowedFormatError: undefined, isResolutionError: undefined, ...errors, isSizeError: true };
        isError = true;
        isSuccess = false;
    }

    const reader = new FileReader();
    reader.onload = function (e) {
        if (e.target?.result) {
            setImage64 (e.target.result.toString());
        }
    };
    reader.readAsDataURL(file);

    if (isSuccess===undefined && isFileValid) {
        isSuccess=true;
        url = URL.createObjectURL(file);
    }
    if (isError===undefined && isFileValid) isError=false;

    return { url, image64, errors, isError,isSuccess, isFileValid, format };
};