import {Tabs} from "../../../../../shared/components";
import {PageContentContainer} from "../../../layouts";
import {MaterialIcon} from "../../../../../shared/components/icons";
import {OrganizationIdentityTab} from "./tabs/organization-identity-tab/OrganizationIdentityTab";

export const WindowIdentity =()=>{
    const tabs = [
        { name: 'eService',
          label:
              <div className="flex gap-1 items-center">
                  <MaterialIcon className="text-base" label="window"/>
                  <span>Guichet unique</span>
              </div>,
          children: <></>
        },
        {
          name: 'modules',
          label:
              <div  className="flex gap-1 items-center">
                  <MaterialIcon className="text-base" label="language"/>
                  <span>Nom de domaine</span>
              </div>,
          children: <div></div> },
        {
          name: 'schemaRelational',
          label:
              <div  className="flex gap-1 items-center">
                  <MaterialIcon className="text-base" label="corporate_fare"/>
                  <span>Organisation</span>
             </div>,
          children: <OrganizationIdentityTab/> },
    ];
    return (
        <PageContentContainer  className="px-6 py-8 flex flex-col gap-6">
            <Tabs tabs={tabs} overflow/>
     </PageContentContainer>)
}