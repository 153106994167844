import { FC } from 'react';
import { BaseSVGIconProps } from '../../types/components.type';
import AbstractSVGIcon from './AbstractSVGIcon';
const VerifiedSvg: FC<BaseSVGIconProps> = (props) => (
  <AbstractSVGIcon
    size={props.size}
    className={props.className}
    spin={props.spin}
    hidden={props.hidden}
    viewBox="0 0 14 15">
    <path d="M13.4166 7.5L11.9933 5.8725L12.1916 3.72L10.0858 3.24167L8.98331 1.375L6.99998 2.22667L5.01665 1.375L3.91415 3.23583L1.80831 3.70833L2.00665 5.86667L0.583313 7.5L2.00665 9.1275L1.80831 11.2858L3.91415 11.7642L5.01665 13.625L6.99998 12.7675L8.98331 13.6192L10.0858 11.7583L12.1916 11.28L11.9933 9.1275L13.4166 7.5ZM5.88581 10.2533L3.66915 8.03083L4.53248 7.1675L5.88581 8.52667L9.29831 5.1025L10.1616 5.96583L5.88581 10.2533Z"/>
    </AbstractSVGIcon>
);
export default VerifiedSvg;
