import {FC, ReactNode, useRef, useState} from "react";
import SwiperItem from "./SwiperItem";
import classNames from "classnames";
import styles from './style.module.css'
import {Button} from "kls-ui";
interface SwiperProps{
     maxItems:number
    children:ReactNode[]
}
export const  Swiper:FC<SwiperProps>=(props)=>{

    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const containerRef = useRef<HTMLDivElement>(null);
   console.log("sd",currentIndex);
    const handleRightButtonClick=()=>{
        if(isRightBtnDisabled) return;
        scrollToIndex(currentIndex+1);
    }
    const handleLeftButtonClick=()=>{
        if(isLefBtnDisabled) return;
        scrollToIndex(currentIndex-1);
    }

    const isLefBtnDisabled= (currentIndex === 0)
    const isRightBtnDisabled=(currentIndex>=props.children.length-5);
console.log(props.children.length,props.maxItems)


    const scrollToIndex = (index:number) => {
        setCurrentIndex(index);
        const horizontalScrollElement = containerRef.current;
        if (horizontalScrollElement) {
            const child = horizontalScrollElement.children[index];
            console.log("sd",child);
            child.scrollIntoView({ behavior: 'smooth',  block: 'nearest', inline: 'start'});
        }
    };


    const swiperWrapperStyle=classNames({
        [styles.swiperWrapper]:true,
        ["justify-center"] :props.maxItems<7
    })

   const  directionButtonStyles=(btnDisabled:boolean)=>(
       classNames({
       ["material-icon text-2xl flex justify-center items-center"]:true,
       ["text-gray-500"]:btnDisabled,
   }))
    const ChevronLeftIcon=<i className={directionButtonStyles(isLefBtnDisabled)}>chevron_left</i>;
    const ChevronRightIcon=<i className={directionButtonStyles(isRightBtnDisabled)}>chevron_right</i>
    return (
        <div className={styles.swiperContainer} dir="ltr">

            <div className="flex items-center">
               <Button size="sm" color="primary" styling={"ghost"} leftIcon={ChevronLeftIcon} args={{onClick:handleLeftButtonClick}}/>
            </div>

            <div className={swiperWrapperStyle} ref={containerRef} >
                {props.children.map((item:ReactNode,index)=> (<SwiperItem key={index} maxItems={props.maxItems}>{item}</SwiperItem>))}
            </div>

            <div className="flex items-center">
                <Button size="sm" color="primary" styling={"ghost"} leftIcon={ChevronRightIcon} args={{onClick:handleRightButtonClick}}/>
            </div>
        </div>

)
}

