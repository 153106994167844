import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import {ControlledUiProps, InputTextProps} from '../../types';
import {InputText as NativeInputText} from "../input/input-text/InputText";

export interface ControlledInputProps <TFieldValues extends FieldValues,TName extends FieldPath<TFieldValues>>
  extends InputTextProps, ControlledUiProps<TFieldValues,TName>{}

export const InputText  = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>
(props:ControlledInputProps<TFieldValues, TName>) => {
    return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field: { onChange, onBlur, value,ref } }) => (
        <NativeInputText
            ref={ref}
            {...props}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            errorMessage={props.error}
        />
      )}
    />
  );
};

