import { FC, useState } from 'react';
import ArrowSvg from '../../../../../shared/components/icons/ArrowSvg';
import { IconSize } from '../../../../../shared/utils/enums';
import styles from './filterBar.module.css';
import { AdvancedDropDown } from 'kls-ui';
export interface FilterBarSortByTimeProps {
  label: string;
  options: Array<{
    [key: string]: string | undefined;
  }>;
  selectedValue: {
    [key: string]: string | undefined;
  };
  setSelectedValue:(v:{
  value:string,label:string
  })=>void;
}
export const FilterBarSortByTime: FC<FilterBarSortByTimeProps> = (props) => {
  const [toggleSortByBtn, setToggleSortByBtn] = useState<boolean>(false);
  return (
    <div className={styles.filterSortByT}>
      <span>{props.label}</span>
      <div
        onClick={() => { 
            setToggleSortByBtn(!toggleSortByBtn);
        }}
        className={styles.filterSortByIcon}>
        {props.selectedValue.label}
        <ArrowSvg size={IconSize.PX12} />
      </div>
      {toggleSortByBtn && (
        <AdvancedDropDown
          selectedValue={props.selectedValue.value}
          style={{ top: '20px', left: '55px' }}
          closeWhenClickOutside={() => {
            if (toggleSortByBtn) setToggleSortByBtn(!toggleSortByBtn);
          }}
          handleChange={(e) => {
            props.setSelectedValue(e)
            if (toggleSortByBtn) setToggleSortByBtn(!toggleSortByBtn);
          }}
          enableSearch={false}
          data={props.options}></AdvancedDropDown>
      )}
    </div>
  );
};
