import { ControlledUiProps, InputPasswordProps } from '../../types';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { InputPassword as NativeInputPassword} from '../input/input-password/InputPassword';

export interface ControlledInputPasswordProps<TFieldValues extends FieldValues,TName extends FieldPath<TFieldValues>> extends InputPasswordProps, ControlledUiProps<TFieldValues, TName> {}

export const InputPassword= <TFieldValues extends FieldValues,TName extends FieldPath<TFieldValues>>(props:ControlledInputPasswordProps<TFieldValues, TName>) => {
  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field: { onChange, onBlur, value ,ref} }) => (
        <NativeInputPassword
          ref={ref}
          {...props}
          errorMessage={props.error}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
        />
      )}
    />
  );
};

