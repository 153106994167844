import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import {ControlledUiProps, SelectProps} from '../../types';
import {Select as NativeSelect} from "../drop-down/select/Select";

export interface ControlledDropdownProps <TFieldValues extends FieldValues,TName extends FieldPath<TFieldValues>>
    extends SelectProps, ControlledUiProps<TFieldValues,TName>{}

export const Select = <
    TFieldValues extends FieldValues,
    TName extends FieldPath<TFieldValues>
>
(props:ControlledDropdownProps<TFieldValues, TName>) => {
    return (
        <Controller
            control={props.control}
            name={props.name}
            render={({ field: { onChange } }) => (
                <NativeSelect  {...props}
                    onChange={onChange}
                    errorMessage={props.error}
                    withError
                />
              )}
                />

    );
};

