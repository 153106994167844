import { FC } from 'react';
import { BaseSVGIconProps } from '../../types/components.type';
import AbstractSVGIcon from './AbstractSVGIcon';
const PeopleSvg: FC<BaseSVGIconProps> = (props) => (
  <AbstractSVGIcon
    size={props.size}
    className={props.className}
    spin={props.spin}
    hidden={props.hidden}
    viewBox="0 0 16 12">
    <path d="M4.21335 6.67332C4.85335 6.68666 5.44669 7.00666 5.84669 7.56666C6.33335 8.25332 7.14002 8.66666 8.00002 8.66666C8.86002 8.66666 9.66669 8.25332 10.1534 7.55999C10.5534 6.99999 11.1467 6.67999 11.7867 6.66666C11.3067 5.85332 9.38669 5.33332 8.00002 5.33332C6.62002 5.33332 4.69335 5.85332 4.21335 6.67332Z" />
    <path d="M2.66669 6.66666C3.77335 6.66666 4.66669 5.77332 4.66669 4.66666C4.66669 3.55999 3.77335 2.66666 2.66669 2.66666C1.56002 2.66666 0.666687 3.55999 0.666687 4.66666C0.666687 5.77332 1.56002 6.66666 2.66669 6.66666Z" />
    <path d="M13.3334 6.66666C14.44 6.66666 15.3334 5.77332 15.3334 4.66666C15.3334 3.55999 14.44 2.66666 13.3334 2.66666C12.2267 2.66666 11.3334 3.55999 11.3334 4.66666C11.3334 5.77332 12.2267 6.66666 13.3334 6.66666Z" />
    <path d="M8.00002 4.66666C9.10669 4.66666 10 3.77332 10 2.66666C10 1.55999 9.10669 0.666656 8.00002 0.666656C6.89335 0.666656 6.00002 1.55999 6.00002 2.66666C6.00002 3.77332 6.89335 4.66666 8.00002 4.66666Z" />
    <path d="M14 7.33332H11.82C11.3067 7.33332 10.92 7.63332 10.7 7.94666C10.6734 7.98666 9.79335 9.33332 8.00002 9.33332C7.04669 9.33332 5.98002 8.90666 5.30002 7.94666C5.04002 7.57999 4.63335 7.33332 4.18002 7.33332H2.00002C1.26669 7.33332 0.666687 7.93332 0.666687 8.66666V11.3333H5.33335V9.82666C6.10002 10.36 7.02669 10.6667 8.00002 10.6667C8.97335 10.6667 9.90002 10.36 10.6667 9.82666V11.3333H15.3334V8.66666C15.3334 7.93332 14.7334 7.33332 14 7.33332Z" />
  </AbstractSVGIcon>
);
export default PeopleSvg;
