import { FC } from 'react';
import Close from '../../../../shared/components/icons/CloseSvg';
import Open from '../../../../shared/components/icons/OpenSvg';
import styles from './menu.module.css';
export interface MenuButtonProps {
  isOpen: boolean;
  onClick: () => void;
}
const MenuButton: FC<MenuButtonProps> = ({ isOpen, onClick }) => {
  return (
    <button className={styles.menuButton} onClick={onClick}>
      {isOpen ? (
        <Close className={styles.menuBottonIcon} />
      ) : (
        <Open className={styles.menuBottonIcon} />
      )}
    </button>
  );
};
export default MenuButton;
