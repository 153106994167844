import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import signupAr from './modules/auth/assets/locales/signup/signup.ar.json';
import signupFr from './modules/auth/assets/locales/signup/signup.fr.json';
import menuFr from './modules/portal/assets/layouts/menu/menu.fr.json';
import menuAr from './modules/portal/assets/layouts/menu/menu.ar.json';
import loginAr from './modules/auth/assets/locales/login/login.ar.json';
import loginFr from './modules/auth/assets/locales/login/login.fr.json';
import forgetPasswordFr from './modules/auth/assets/locales/forgetpassword/forgetpassword.fr.json';
import forgetPasswordAr from './modules/auth/assets/locales/forgetpassword/forgetpassword.fr.json';
import windowFr from './modules/portal/assets/locales/window/window.fr.json';
import windowAr from './modules/portal/assets/locales/window/window.ar.json'

const storedLanguage = localStorage.getItem('selectedLanguage') || 'fr';

const resources = {
  ar: {
    signup: signupAr,
    menu: menuAr,
    login: loginAr,
    resetPassword:forgetPasswordAr,
    onboarding:windowAr
  },
  fr: {
    signup: signupFr,
    login: loginFr,
    menu: menuFr,
    resetPassword:forgetPasswordFr,
    window:windowFr
  }
};

i18n.use(initReactI18next).init({
  supportedLngs: ['fr', 'ar'],
  resources,
  fallbackLng: 'fr',
  lng: storedLanguage,
  interpolation: {
    escapeValue: false
  }
});
