
import {ControlledUiProps } from '../../types';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { CheckboxLabel, CheckboxLabelProps } from 'kls-ui';


export interface ControlledInputProps <TFieldValues extends FieldValues,TName extends FieldPath<TFieldValues>>
  extends CheckboxLabelProps,
    ControlledUiProps<TFieldValues,TName> {}

export const CheckBox =<TFieldValues extends FieldValues,TName extends FieldPath<TFieldValues>>
(props:ControlledInputProps<TFieldValues,TName>) => {
  return (
    <>
      <Controller
        control={props.control}
        name={props.name}
        render={({ field: { onChange, onBlur, value } }) => (
          <CheckboxLabel
            {...props}
            Check={value}
            args={{
              onChange: onChange,
              onBlur: onBlur
            }}
          />
        )}
      />
    </>
  );
};
