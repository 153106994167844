
import { InputText, Select } from "../../../../shared/components"
import { MaterialIcon } from "../../../../shared/components/icons";
import styles from './landing.module.css'
export const RechercheComponent = ()=>{
    const  location= <MaterialIcon label="location_on"/>;
    const  search= <MaterialIcon label="search"/>;

    return <>
    <div className={`${styles.searchContainer} flex flex-col gap-7`}>
        <span className={styles.textSearch}>Découvrez et accédez aux services numériques réellement opérationnels des organisations publiques et privées qui vous intéressent</span>
    <div className="flex gap-7 ">
    <div className="relative col-span-2 flex items-center w-8/12">
        <InputText
        beforeText={search}
          icon={location}
          size="md"
          placeholder="Mot clé"
        />
         </div>
         <div className="w-1/4">
      <Select
        size="lg"
            placeholder={'Sélectionner un domaine'} 
            options={[]}          
          />
          </div>
    </div>
    </div>
    </>
}