import {FC, ReactNode} from "react";
import styles from "./cards.module.css";
import classNames from "classnames";

interface ConfigOnboardingCardProps{
    icon:ReactNode;
    title:string;
    description:string;
    onClick:()=>void;
    selected:boolean
}

export const ConfigCard:FC<ConfigOnboardingCardProps>=(props)=>{
  const rootStyle=classNames({
      [styles.configCardContainer]:true,
      [styles["onboarding-card-css-var"]]:true,
      [styles.selectedCard]:props.selected
  })
    return (
        <div className={rootStyle} onClick={props.onClick}>
            <div className="flex justify-center">{props.icon}</div>
            <div className="flex flex-col gap-3">
                <span className={styles.configCardTitle}>{props.title}</span>
                <span className={styles.configCardDescription}>{props.description}</span>
            </div>
        </div>
    )
}
