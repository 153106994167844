import {useLocation} from "react-router-dom";
import {USER_ROUTE_LOCATION_PATHNAME, WINDOW_ROUTE_LOCATION_PATHNAME} from "../utils/constants";


export const useHandleDomLocation=()=>{
    const location=useLocation();
    const profilePathName=location.pathname.split("/")[1];
    const isWindowProfile=profilePathName===WINDOW_ROUTE_LOCATION_PATHNAME;
    const isUserProfile=profilePathName===USER_ROUTE_LOCATION_PATHNAME;

    return {isUserProfile,profilePathName,isWindowProfile};
}