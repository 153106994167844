
import {useGetAllGuichets} from "../../../api";
import {PageContentContainer} from "../../../layouts";
import {EmptyData} from "../../../../../shared/components";
import {WindowListPage} from "./WindowListPage";
import {AllGuichetsRequest} from "../../../types";
import {useNavigate} from "react-router-dom";

const INITIAL_STATE:AllGuichetsRequest = {
    numPage: 1,
    pageSize: 10,
    contributeur: false,
    key: '',
    name: '',
    status: '',
    statutRole: '',
    reference: '',
    domainName: '',
    email: '',
    activation: '',
    verification: '',
    tags: '',
    type: '',
    role: '',
    favorite: false,
    industry: '',
    entity: '',
    recent: '',
    Fields: 'ALL'
}

export const WindowPage=()=>{
    const navigate=useNavigate()
    const getAllGuichets = useGetAllGuichets(INITIAL_STATE)
    const EmptyDataIcon=<i className="material-flex-icon text-blue-500 text-3xl">corporate_fare</i>
    const AddIcon=<i className="material-flex-icon text-base">add</i>
    if (!getAllGuichets.isFetched && !getAllGuichets.customHandler.isSuccess && (getAllGuichets.customHandler.data?.pagination.totalLength==0)){
        return (  <PageContentContainer  className="flex items-center justify-center">
            <EmptyData title="Aucun guichet pour l'instant"
                       description={"Les guichets dont vous êtes membre apparaitront ici"}
                       icon={EmptyDataIcon}
                       firstBtnArgs={{size:"md",styling:"solid",color:"primary",label:"Créer un nouveau guichet",leftIcon:AddIcon,args:{onClick:()=>navigate("create-window")}}}
                       secondBtnArgs={{size:"md",styling:"outline",color:"primary",label:"Rejoindre un guichet existant",args:{}}}/>
        </PageContentContainer>)
    }
   return (<WindowListPage/>)
}