import { FC } from 'react';
import { BaseSVGIconProps } from '../../types/components.type';
import AbstractSVGIcon from './AbstractSVGIcon';
import { IconSize } from '../../utils/enums';
const ArrowSvg: FC<BaseSVGIconProps> = (props) => (
  <AbstractSVGIcon
    size={IconSize.XS}
    className={props.className}
    spin={props.spin}
    hidden={props.hidden}
    viewBox='0 0 8 6'
  >
    <path d="M7.06024 0.530029L4.00024 3.58336L0.940244 0.530029L0.000244141 1.47003L4.00024 5.47003L8.00024 1.47003L7.06024 0.530029Z" />
  </AbstractSVGIcon>
);
export default ArrowSvg;
