import { useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import { addFavoriteEservice, getCategoriesList, getEserviceMembersList, getEservicesList, getRequestJoinEservicesList, postAcceptRequestJoinEservice, postCreateEservice, postRefuseRequestJoinEservice, postRequestJoinEservice } from "./api";
import { EserviceAcceptJoin, EserviceCreationRequest, EserviceJoinRequest, EserviceMembersListRequest, FavoriteEserviceRequest, ListEserviceRequest, RequestJoinEservice } from "../../types/data.types";
import { useHandleGenericResponse, useHandleStandardResult } from "../../../../shared/hooks";



export const useGetEserviceList = (data:ListEserviceRequest) => {
  const queryKey= ["list-services" , JSON.stringify(data)];
  const reactQuery = useQuery({
      queryKey,
      queryFn: () => getEservicesList(data),
      retry: 0,
      staleTime:0,
      gcTime:0
    });

    const customHandler = useHandleGenericResponse(reactQuery);
  return { ...reactQuery, customHandler };
};
export const useGetRequestJoinEserviceList = (data:RequestJoinEservice) => {
  const queryKey= ["list-request" , JSON.stringify(data)];
  const reactQuery = useQuery({
      queryKey,
      queryFn: () => getRequestJoinEservicesList(data),
      retry: 0,
      staleTime:0,
      gcTime:0
    });

    const customHandler = useHandleGenericResponse(reactQuery);
  return { ...reactQuery, customHandler };
};

export const useGetEserviceMembersList = (data:EserviceMembersListRequest) => {
  const queryKey= ["list-members" , JSON.stringify(data)];
  const reactQuery = useQuery({
      queryKey,
      queryFn: () => getEserviceMembersList(data),
      retry: 0,
      staleTime:0,
      gcTime:0
    });

    const customHandler = useHandleGenericResponse(reactQuery);
  return { ...reactQuery, customHandler };
};

export const useGetCategoriesList=()=>{
  const reactQuery= useQuery({
      queryKey:["categories-list"],
      queryFn:  () => getCategoriesList(),

  });
  const customHandler = useHandleGenericResponse(reactQuery);
  return { ...reactQuery,customHandler };
}

export const useFavoriteEservice = (data: FavoriteEserviceRequest) => {
  const queryKey = ['favorite-eservices', JSON.stringify(data)];
  const reactQuery = useQuery({
    queryKey,
    queryFn: () => addFavoriteEservice(data),
    retry: 0,
    enabled: data.idEservice!="",
    staleTime: 0
  });
  const customHandler = useHandleGenericResponse(reactQuery);
  return { ...reactQuery, customHandler };
};



  export const useCreateEservice=()=>{
    const queryClient = useQueryClient(); 

    const mutationQuery= useMutation({
        mutationFn:  (data:EserviceCreationRequest) => postCreateEservice(data),
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ['list-services'] });
        },
    });
    const customHandler = useHandleStandardResult(mutationQuery);
    return { ...mutationQuery,customHandler };
}

export const useRequestJoinEservice=()=>{
  const mutationQuery= useMutation({
      mutationFn:  (data:EserviceJoinRequest) => postRequestJoinEservice(data),
  });
  const customHandler = useHandleStandardResult(mutationQuery);
  return { ...mutationQuery,customHandler };
}

export const useAcceptRequestJoinEservice=()=>{
  const queryClient = useQueryClient();
  const mutationQuery= useMutation({
      mutationFn:  (data:EserviceAcceptJoin) => postAcceptRequestJoinEservice(data),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["list-members"] });
      },
  
    });    
  
  const customHandler = useHandleStandardResult(mutationQuery);
  return { ...mutationQuery,customHandler };
}

export const useRefuseRequestJoinEservice=()=>{
  const queryClient = useQueryClient();
  const mutationQuery= useMutation({
      mutationFn:  (data:EserviceAcceptJoin) => postRefuseRequestJoinEservice(data),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["list-members"] });
      },
  
    });    
  
  const customHandler = useHandleStandardResult(mutationQuery);
  return { ...mutationQuery,customHandler };
}