import { FC } from 'react';
import { BaseSVGIconProps } from '../../types/components.type';
import AbstractSVGIcon from './AbstractSVGIcon';
import { IconSize } from '../../utils/enums';
const ArrowChildSvg: FC<BaseSVGIconProps> = (props) => (
  <AbstractSVGIcon
    size={IconSize.XS}
    className={props.className}
    spin={props.spin}
    hidden={props.hidden}
    viewBox="0 0 4 6">
    <path d="M0.852461 0L0.147461 0.705L2.43746 3L0.147461 5.295L0.852461 6L3.85246 3L0.852461 0Z" />
  </AbstractSVGIcon>
);
export default ArrowChildSvg;
