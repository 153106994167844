import classNames from "classnames";
import {FC, ReactNode} from "react";
import styles from './course-card.module.css'
import {Progress, Rate} from "antd";
import {Button} from "kls-ui";


interface CourseCardProps{
    heading:{title:string,description:string,icon:ReactNode,backgroundColor:string};
    title :{icon:ReactNode,text:string};
    rate:{rate:number,totalRating:number};
    progress:number
    price:string;
}
export const CourseCard:FC<CourseCardProps>=(props)=>{
    const rootStyle =classNames({
        [styles["portal-karaz_academy-courses_course_card-css-var"]]:true,
        [styles.rootContainer]:true,
    })

    const RightIcon=<i className="material-icon text-lg">arrow_outward</i>
    return(
        <div className={rootStyle}>
            <div className={styles.imageContainer} style={{backgroundColor:props.heading.backgroundColor}}>
                <div className={styles.imageItem}/>
                <div>
                    <div className="absolute top-0 left-0 px-3 py-4">
                        <p className={styles.courseHeading}>{props.heading.title}</p>
                        <p className={styles.courseHeading}>{props.heading.description}</p>
                    </div>
                    <div className="absolute bottom-0 right-0 px-2 py-3">
                        {props.heading.icon}
                    </div>
                </div>
            </div>
            <div className="flex  gap-2">
                {props.title.icon}
                <span className={styles.titleText}>{props.title.text}</span>
            </div>
            <div className="flex justify-between items-baseline">
                <div className="flex items-baseline gap-2">
                    <Rate disabled defaultValue={props.rate.rate}/>
                    <span className={styles.totalRating}>({props.rate.totalRating})</span>
                </div>
                 <span className={styles.price}>{props.price}</span>
            </div>
            <div className={styles.divider}/>
            <div className="flex justify-between items-center px-2 w-full">
                <Progress size={40} type="circle" percent={props.progress} />
                <Button size="sm" color="primary" styling="solid" rightIcon={RightIcon} label="Compléter" args={{}}/>
            </div>
        </div>
    )
}