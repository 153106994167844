import { FC, useState } from 'react';
import { IconSize } from '../../../../../../shared/utils/enums';
import { Card } from '../Card';
import styles from '../cards.module.css';
import classNames from 'classnames';
import VerifiedSvg from '../../../../../../shared/components/icons/VerifiedSvg';
import logo from '../../../../assets/icons/logo-app.svg';
import { FavoriteGuichetRequest } from '../../../../types/data.types';
import { Menu } from '../../../../../../shared/components';
import { MaterialIcon } from '../../../../../../shared/components/icons';
import { Star } from '@phosphor-icons/react';
export interface CardGuichetProps {
  guichet: Guichet;
  cardAction?: CardActionProps;
  dropDownData?: { label: string; value: string; icon: JSX.Element }[];
  setFavoriteParams?: (params: FavoriteGuichetRequest) => void;
  icon?: string;
}
export interface Guichet {
  id: string;
  name: string;
  industry: string;
  role: string;
  status: string;
  verification: string;
  favorite: boolean;
  imageUrl: string;
  changeTime: string;
  typeGuichet?:string;
  lat?: number;
  lng?: number;
}
interface CardActionProps {
  onSelectDropDown: (selection: { id: string; selectedValue: string }) => void;
}
export const CardGuichet: FC<CardGuichetProps> = (props) => {
  const [favorite, setFavorite] = useState<boolean>(props.guichet.favorite);

  const cardRoleStyle = classNames({
    [styles.cardRole]: true
  });
  const handleSelect = (value: string) => {
    if (props.cardAction?.onSelectDropDown) {
      props.cardAction.onSelectDropDown({
        id: props.guichet.id,
        selectedValue: value
      });
      return;
    }
  };
  const handleFavoriteIconClick = () => {
    if (props.setFavoriteParams) {
      setFavorite(!favorite);
      props.setFavoriteParams({
        guichetId: props.guichet.id,
        favorite: !favorite
      });
    }
  };

  const renderFavoriteIcon = () => {
    return (
      <span
        onClick={(e) => {
          handleFavoriteIconClick();
          e.stopPropagation();
        }}>
        {favorite && (
          <div className="flex items-center">
            <Star
              size={16}
              weight="fill"
              className="text-amber-400 stroke-2 stroke-amber-400"
            />
          </div>
        )}
        {!favorite && (
          <div className={`flex items-center ${styles.headerIcons}`}>
            <Star
              size={16}
              weight="fill"
              className="text-slate-200 stroke-2 stroke-slate-100"
            />
          </div>
        )}
      </span>
    );
  };
  const renderVerificationIcon = () => {
    if (props.guichet.verification === 'Vérifiée') {
      return (
        <VerifiedSvg size={IconSize.PX14} className={styles.cardTitleSvg} />
      );
    }
    return null;
  };
  const menuIcon = (
    <MaterialIcon label="more_horiz" className={styles.menuIcon} />
  );
  const renderMenu = () => {
    if (props.dropDownData) {
      return (
        <Menu
          icon={menuIcon}
          onChange={(selectedValue: string[]) => handleSelect(selectedValue[0])}
          list={props.dropDownData}
        />
      );
    }
    return null;
  };
  const handleClickCard = () => {
    if (props.cardAction) {
      props.cardAction.onSelectDropDown({
        id: props.guichet.id,
        selectedValue: 'open'
      });
    }
  };
  return (
    <Card
      onClick={() => handleClickCard()}
      childrenCard={
        <>
          <div className={styles.starFContainer}>
            {renderFavoriteIcon()}
            <div className={styles.headerIcons}>{renderMenu()}</div>
          </div>
          <div className={styles.imageContainer}>
            <img
              className={styles.image}
              src={props.guichet.imageUrl || logo}
            />
          </div>
          <div className={styles.industryContainer}>
            <div></div>
            {props.guichet.role && (
              <span className={cardRoleStyle}>{props.guichet.role}</span>
            )}
            <span className={styles.industry}>
              <i className="material-flex-icon text-lg">{props.icon}</i>
            </span>
          </div>
        </>
      }
      childrenInfo={
        <>
          <div className={styles.cardnameTime}>
            <span className={styles.cardTitleGuichet}>
              {props.guichet.name}
              {renderVerificationIcon()}
            </span>
          </div>
        </>
      }
    />
  );
};
