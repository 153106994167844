import {AbstractSVGIcon} from "../../../../../shared/components/icons";
import {BaseSVGIconProps} from "../../../../../shared/types";
import {FC} from "react";

export const ExploreIcon: FC<BaseSVGIconProps> = (props) => {
    return (<AbstractSVGIcon
        size={props.size}
        className={props.className}
        spin={props.spin}
        hidden={props.hidden}
        viewBox="0 0 18 21"
    >
        <path
            d="M15.667 3.83333H2.33366C1.41699 3.83333 0.666992 4.58333 0.666992 5.5V15.5C0.666992 16.4167 1.41699 17.1667 2.33366 17.1667H15.667C16.5837 17.1667 17.3337 16.4167 17.3337 15.5V5.5C17.3337 4.58333 16.5837 3.83333 15.667 3.83333ZM15.667 15.5H2.33366V5.5H15.667V15.5ZM2.33366 0.5H15.667V2.16667H2.33366V0.5ZM2.33366 18.8333H15.667V20.5H2.33366V18.8333ZM9.00033 10.5C10.1503 10.5 11.0837 9.56667 11.0837 8.41667C11.0837 7.26667 10.1503 6.33333 9.00033 6.33333C7.85033 6.33333 6.91699 7.26667 6.91699 8.41667C6.91699 9.56667 7.85033 10.5 9.00033 10.5ZM9.00033 7.58333C9.45866 7.58333 9.83366 7.95833 9.83366 8.41667C9.83366 8.875 9.45866 9.25 9.00033 9.25C8.54199 9.25 8.16699 8.875 8.16699 8.41667C8.16699 7.95833 8.54199 7.58333 9.00033 7.58333ZM13.167 13.825C13.167 12.0833 10.4087 11.3333 9.00033 11.3333C7.59199 11.3333 4.83366 12.0833 4.83366 13.825V14.6667H13.167V13.825ZM6.34199 13.4167C6.85033 12.9833 8.03366 12.5833 9.00033 12.5833C9.97533 12.5833 11.1587 12.9833 11.667 13.4167H6.34199Z"
        />
    </AbstractSVGIcon>)
}
