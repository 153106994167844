import { FC } from 'react';
import styles from './personae.module.css';
export interface PersonaeAvatarProps {
  title?: string;
  avatar?: string;
}
const PersonaeAvatar: FC<PersonaeAvatarProps> = (props) => {
  return (
    <div className={`${styles.containerAvatar} ${styles["personae-avatar-css-var"]}`}>
      <img className={styles.imgAvatar} src={props.avatar} alt="avatar" />
      <span className={styles.titleAvatar}>{props.title}</span>
    </div>
  );
};
export default PersonaeAvatar;
