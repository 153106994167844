import { FC } from 'react';
import styles from './filterBar.module.css';
import { DynamicListData } from '../../../../../shared/types';
import { Select } from '../../../../../shared/components';
export interface FilterBarItemSortByProps {
  label: string;
  data:
    | Array<{
        [key: string]: string | undefined;
      }>
    | DynamicListData[]
    | undefined;
  placeHolder: string;
  selectedValue: string;
  multiple?: boolean | false;
  clearable?: boolean | false;
  onChange: (selectedValue: string) => void;
}

export interface FilterBarSortByProps {
  items: FilterBarItemSortByProps[];
}

export const FilterBarSortBy: FC<FilterBarSortByProps> = (props) => {
  const renderDataOptions = (data: DynamicListData[]) => {
    return data?.map((item: DynamicListData) => ({
      ...item,
      icon: (
        <i className="material-flex-icon text-lg text-blue-600">{item.icon}</i>
      )
    }));
  };
  return (
    <div className={styles.filterSortBy}>
      {props.items.map((item: FilterBarItemSortByProps, index: number) => (
        <div key={index} className={styles.filterSortItemContainer}>
          <Select
            label={item.label}
            selectedValues={item.selectedValue}
            options={renderDataOptions(item.data as DynamicListData[])}
            onChange={(e) => {
              if (e.target && ("value" in (e.target as HTMLInputElement))) {
                item.onChange((e.target as HTMLInputElement).value);
              }
            }}
            onClear={()=>item.onChange('')}
            placeholder={item.placeHolder}
            multiple={item.multiple}
            clearable={item.clearable}
          />
          {/* <AdvancedDropDownList
            list={item.data}
            withoutError
            key={index}
            size={1}
            selectedValue={item.selectedValue}
            placeholder={item.placeHolder}
            handlerChange={(e) =>
              item.onChange((e.target as HTMLInputElement).value)
            }
          /> */}
        </div>
      ))}
    </div>
  );
};
