import {FC, useState,MouseEvent} from "react";
import {MaterialIcon} from "../../icons";
import classNames from "classnames";
import styles from "./tag-element.module.css";
interface TagElementProps {
    size?:"sm"|"md"|"lg";
    label:string;
    color?:string;
    borderColor?:string;
    className?:string;
    closable?: boolean;
    onClose?:()=>void
}

export const TagElement:FC<TagElementProps>=(props)=>{
    const {closable=false,size="sm",onClose=()=>{},color="",className="",borderColor=""}=props;
    const [isClosed,setHasClosed]=useState<boolean>(false)
    const handleCloseTag=(e:MouseEvent<HTMLElement>)=>{
        console.log("tag",props.label);
        e.stopPropagation();
        setHasClosed(true);
        onClose();
    }
    const renderCloseIcon=()=>{
        if (closable) return <MaterialIcon label="close" center onClick={handleCloseTag} className="text-xs cursor-pointer text-slate-500 hover:text-red-500"/>
        return (<></>)
    }
    const labelStyle=classNames({
        [styles.label]:true,
        "text-[11px]":size=="sm",
        "text-xs":size=="md",
        "text-[13px]":size=="lg",

    })
    const rootStyles=classNames({
        [className]:true,
        [styles.rootContainer]:true,
        ["!hidden"]:isClosed,
        "py-0.5":size=="sm",
        "py-1":["md","lg"].includes(size),
    })
    return (
        <div className={rootStyles} style={{borderColor}} onClick={(e)=>{e.stopPropagation()}}>
        <span style={{color}} className={labelStyle}>{props.label}</span>
            {renderCloseIcon()}
       </div>
    )
}