import { FC } from 'react';
import { BaseSVGIconProps } from '../../types/components.type';
import AbstractSVGIcon from './AbstractSVGIcon';
const OrdinateurSvg: FC<BaseSVGIconProps> = (props) => (
  <AbstractSVGIcon
    size={props.size}
    className={props.className}
    spin={props.spin}
    hidden={props.hidden}
    viewBox="0 0 15 11">
    <path d="M14.2857 8.35714H0.71426C0.518947 8.35714 0.357117 8.51897 0.357117 8.71429V9.07143C0.357117 9.85826 0.998858 10.5 1.78569 10.5H13.2143C14.0011 10.5 14.6428 9.85826 14.6428 9.07143V8.71429C14.6428 8.51897 14.481 8.35714 14.2857 8.35714ZM13.2143 9.78571H1.78569C1.39183 9.78571 1.0714 9.46529 1.0714 9.07143H13.9285C13.9285 9.46529 13.6081 9.78571 13.2143 9.78571ZM2.14283 7.64286C2.34024 7.64286 2.49997 7.48313 2.49997 7.28571V1.92857C2.49997 1.53446 2.82049 1.21429 3.21426 1.21429H11.7857C12.1795 1.21429 12.5 1.53446 12.5 1.92857V7.28571C12.5 7.48313 12.6597 7.64286 12.8571 7.64286C13.0545 7.64286 13.2143 7.48313 13.2143 7.28571V1.92857C13.2143 1.14105 12.5736 0.5 11.7857 0.5H3.21426C2.42638 0.5 1.78569 1.14105 1.78569 1.92857V7.28571C1.78569 7.48313 1.94542 7.64286 2.14283 7.64286Z" />
  </AbstractSVGIcon>
);
export default OrdinateurSvg;
