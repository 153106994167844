import {RefObject, useEffect, useRef, useState} from "react";

export const useHandleComponentVisibility = () => {
    const [isOpen, setOpenStatus] = useState<boolean>(false);
    const ref: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            setOpenStatus(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    return { ref, isOpen, setOpenStatus };
};