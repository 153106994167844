import {FC, ReactNode} from "react";
import styles from "./empty-data.module.css";
import { Button, ButtonProps } from "kls-ui";
import {MaterialIcon} from "../icons";

interface EmptyData{
    icon?:ReactNode;
    title?:string;
    description?:string;
    firstBtnArgs?: ButtonProps;
    secondBtnArgs?: ButtonProps;
    searchIcon?:boolean;
}
export const EmptyData:FC<EmptyData>=(props)=>{
  const {
         icon=(<></>),
         title="",
         searchIcon=false
      } = props;
  const renderIcon=()=>{
      if (searchIcon) return <MaterialIcon label="search_off" className="text-4xl text-blue-500" />
      return icon;
  }
    return (
        <div className={styles.noItemFound}>
       {renderIcon()}
            <div className="flex flex-col justify-center items-center gap-1">
                <span className={styles.title}>{title}</span>
                <span className={styles.description}>{props.description}</span>
            </div>

            {
                <>
                    {props.firstBtnArgs && !props.secondBtnArgs && <Button {...props.firstBtnArgs} size="sm" />}
              {props.firstBtnArgs && props.secondBtnArgs && <div className="flex gap-5"><Button {...props.firstBtnArgs} size="sm" /> <Button {...props.secondBtnArgs} size="sm" /></div>}

         </>
      }

      </div>
     
    )
}
