import { ApiRemoteAction } from '../../../../shared/types';
import { REMOTE_ACTION_BASE_PATH } from '../../../../shared/utils/constants';

export const FORGOT_PASSWORD_ACTION = 'karazapps/karaz/kls/creationcompte/signup/action/SignupBacker';


export const PASSWORD_RESET_REQUEST_PATH: ApiRemoteAction = {
  path: REMOTE_ACTION_BASE_PATH,
  karazAction: FORGOT_PASSWORD_ACTION,
  operation: 'restPassword'
};

export const PASSWORD_RESET_VERIFY_CODE_PATH: ApiRemoteAction = {
  path: REMOTE_ACTION_BASE_PATH,
  karazAction: FORGOT_PASSWORD_ACTION,
  operation: 'verifyCodeRestPassword'
};

export const PASSWORD_RESET_SUBMIT_PATH: ApiRemoteAction = {
  path: REMOTE_ACTION_BASE_PATH,
  karazAction: FORGOT_PASSWORD_ACTION,
  operation: 'submitRestPassword'
};
