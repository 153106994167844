import {InputText} from "../input-text/InputText";
import {MaterialIcon} from "../../icons";
import styles from "./input-color.module.css"
import {ColorPicker, ColorPickerProps, GetProp} from "antd";
import {
    forwardRef,
    ForwardRefRenderFunction,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import {AggregationColor} from "antd/es/color-picker/color";
import {InputColorProps} from "../../../types";
import {useEvent} from "../../../hooks";

type Color = Extract<GetProp<ColorPickerProps, 'value'>, string |  AggregationColor>;

 const ForwardedInputColor:ForwardRefRenderFunction<HTMLInputElement,InputColorProps>=(props,ref)=>{
     const {onChange,...inputTextProps}=props;
     const handleEvent=(e:Event)=>{
         if (onChange) onChange(e);
     }
     const [inputRef,emitEvent]=useEvent<HTMLInputElement>("input-select",handleEvent);
    const [color,setColor]=useState<Color>("")
    const afterElementColor = useMemo<string>(
        () => (typeof color === 'string' ? color : color!.toHexString()),
        [color],
    );
    const inputTextRef=ref ?? useRef<HTMLInputElement>(null)

     useEffect(() => {
         if (inputTextRef && typeof inputTextRef !== 'function' && inputTextRef.current) inputTextRef.current.value=afterElementColor;
         if (inputRef.current && afterElementColor) {
             inputRef.current.value=afterElementColor;
             emitEvent();
         }
     }, [color]);
     useEffect(() => {
         if (props.defaultValue) setColor(props.defaultValue)
     }, [props.defaultValue]);
    const BeforeIcon=(<MaterialIcon label="color_lens" className={styles.beforeIcon} />)
    const AfterElement=<div className={styles.afterElement} style={{backgroundColor:afterElementColor}}/>
    const renderAfterText=()=>{
        if(!props.disabled && !props.readOnly) return <ColorPicker  onChange={setColor}  defaultValue="#1677ff" trigger="hover" >{AfterElement}</ColorPicker>
        return (AfterElement)
    }

    return (<>
            <input className="hidden" ref={inputRef} />
           <InputText {...inputTextProps} ref={inputTextRef} onlyRead  beforeText={BeforeIcon} afterText={renderAfterText()}/>
        </>
    )
}

export const  InputColor=forwardRef(ForwardedInputColor);