import {postGenericKarazActionRequest} from '../../../../shared/utils/helpers';
import {
    LOGIN_ACCOUNT_ACTIVATION_PATH,
    LOGIN_PATH,
    LOGIN_RESEND_CODE_PATH,
} from './apiPath';
import {ResponseResult} from '../../../../shared/utils/enums';
import {
    LoginUser,
} from '../../types';
import {
    GenericResponseWrapper
} from '../../../../shared/types';
import {
    LoginActivation, LoginActivationResponse,
    LoginResendCode, LoginResendCodeResponse,
    LoginResponse,
} from '../../types/data.type';


export const postLoginForm = async (
    data: LoginUser
): Promise<GenericResponseWrapper<LoginResponse >> => {
    return await postGenericKarazActionRequest<LoginUser, GenericResponseWrapper<LoginResponse>>(
        data,
        LOGIN_PATH,
        ResponseResult.JSON
    );
};

export const postLoginActivationAccount = async (
    data: LoginActivation
): Promise<GenericResponseWrapper<LoginActivationResponse>> => {
    return await postGenericKarazActionRequest<LoginActivation, GenericResponseWrapper<LoginActivationResponse>>(
        data,
        LOGIN_ACCOUNT_ACTIVATION_PATH,
        ResponseResult.JSON
    );
};

export const postLoginResendCode = async (
    data: LoginResendCode
): Promise<GenericResponseWrapper<LoginResendCodeResponse>> => {
    return await postGenericKarazActionRequest<LoginResendCode, GenericResponseWrapper<LoginResendCodeResponse>>(
        data,
        LOGIN_RESEND_CODE_PATH,
        ResponseResult.JSON
    );
};

