<svg width="18" height="18"  fill="none" xmlns="http://www.w3.org/2000/svg">
</svg>

import { FC } from 'react';
import { BaseSVGIconProps } from '../../types/components.type';
import AbstractSVGIcon from './AbstractSVGIcon';
const FavoriteSvg: FC<BaseSVGIconProps> = (props) => (
  <AbstractSVGIcon
    size={props.size}
    className={props.className}
    spin={props.spin}
    hidden={props.hidden}
    viewBox="0 0 18 18">
    <path d="M10.2448 1.34899L10.2444 1.348C10.0367 0.843752 9.55388 0.5 9.00006 0.5C8.44604 0.5 7.96321 0.843986 7.7556 1.34915L7.75536 1.34973L6.04383 5.52923L1.72605 5.93827L1.7252 5.93836C1.17529 5.9914 0.726599 6.3773 0.564509 6.89745C0.403197 7.41512 0.549057 7.9896 0.953457 8.35813C0.953607 8.35827 0.953758 8.35841 0.953908 8.35855L4.23151 11.358L3.26332 15.8084L3.26322 15.8089C3.14826 16.3399 3.34329 16.8995 3.78149 17.23L3.78159 17.2301C4.01603 17.4068 4.29541 17.5 4.58065 17.5C4.82681 17.5 5.06831 17.4305 5.28116 17.2976C5.28121 17.2976 5.28125 17.2976 5.2813 17.2976C5.28133 17.2975 5.28136 17.2975 5.28139 17.2975L9.00002 14.9783L12.7163 17.2969C12.7163 17.2969 12.7164 17.2969 12.7165 17.297C13.1851 17.591 13.7781 17.5627 14.2184 17.2296C14.656 16.8987 14.8513 16.3393 14.7361 15.8087L14.7361 15.8084L13.7679 11.3581L17.046 8.35873C17.0461 8.35862 17.0462 8.35851 17.0464 8.3584C17.0464 8.35838 17.0464 8.35837 17.0464 8.35836C17.4494 7.99011 17.5971 7.41724 17.4359 6.89824C17.2745 6.3775 16.8246 5.99026 16.2734 5.93827C16.2732 5.93826 16.2731 5.93825 16.273 5.93824L11.9563 5.52924L10.2448 1.34899Z" />
    </AbstractSVGIcon>
);
export default FavoriteSvg;
