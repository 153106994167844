// src/features/auth/authSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUserProfile } from '../../../../shared/utils/helpers';
import { getCookiesUser } from '../../../../shared/utils/helpers/local-storage.helper';
import {UserProfile} from "../../../../shared/types";

interface AuthState {
  isAuthenticated: boolean;
  userProfile:UserProfile|undefined;
  hasAccomplishedOnboarding: boolean;
  isComingFromSignup:boolean
}

const checkUserAuth = () => {
    const userProfile = getUserProfile();
    const userCookies = getCookiesUser();
    return !!(userProfile && Object.keys(userProfile).length > 0 && userCookies?.karazalAccessToken);
};

const checkOnboardingStatus = () => {
    const userProfile= getUserProfile();
    return (userProfile?.onboardingAccomplished === "true");
}

const initialState: AuthState = {
  isAuthenticated: checkUserAuth(),
  userProfile:getUserProfile(),
  hasAccomplishedOnboarding: checkOnboardingStatus(),
  isComingFromSignup:false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthenticated(state, action: PayloadAction<boolean>) {
      state.isAuthenticated = action.payload;
    },
      setUserProfile(state, action: PayloadAction<UserProfile>) {
        state.userProfile=action.payload;
        setUserProfile(action.payload);
      },
    setOnboardingStatus(state, action: PayloadAction<boolean>) {
      state.hasAccomplishedOnboarding = action.payload;
    },
      setComingFromSignupStatus(state, action: PayloadAction<boolean>) {
     state.isComingFromSignup = action.payload;
   },
  },
});

export const { setAuthenticated,setUserProfile, setOnboardingStatus,setComingFromSignupStatus } = authSlice.actions;
export default authSlice.reducer;
