import { useMutation } from '@tanstack/react-query';
import {
  postLoginActivationAccount,
  postLoginForm,
  postLoginResendCode,
} from './api';
import {
  LoginUser,

} from '../../types';
import { useHandleStandardResult } from '../../../../shared/hooks';
import {LoginActivation, LoginResendCode } from '../../types/data.type';

export const usePostLoginData = () => {
  const mutationQuery = useMutation({
    mutationFn: (data: LoginUser) => postLoginForm(data)
  });

  const handleMapDataResult = useHandleStandardResult(mutationQuery);
  return { ...mutationQuery, customHandler: handleMapDataResult };
};

export const usePostLoginActivationAccount = () => {
  const mutationQuery = useMutation({
    mutationFn: (data: LoginActivation) => postLoginActivationAccount(data)
  });

  const handleMapDataResult = useHandleStandardResult(mutationQuery);
  return { ...mutationQuery, customHandler: handleMapDataResult };
};

export const usePostLoginResendCode = () => {
  const mutationQuery = useMutation({
    mutationFn: (data: LoginResendCode) => postLoginResendCode(data)
  });

  const handleMapDataResult = useHandleStandardResult(mutationQuery);
  return { ...mutationQuery, customHandler: handleMapDataResult };
};


