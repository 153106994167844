import { FC} from 'react';
import { IconSize } from '../../utils/enums';
import classNames from 'classnames';
import componentsStyles from '../global-styles.module.css';
import { AbstractSVGIconProps } from '../../types';


const AbstractSVGIcon:FC<AbstractSVGIconProps>=(props)=> {
    const {spin=false,className="",size=IconSize.SM,children,hidden=false,viewBox="0 0 15 15"} = props
    const  iconStyle=classNames({
      [componentsStyles.iconBaseStyle]:true,
      [componentsStyles.rotate]: !hidden && spin,
      [className]:true
    })
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} className={iconStyle}  viewBox={viewBox}>
        {!hidden && children}
     </svg>)
  }
  export default AbstractSVGIcon
