import { Input } from 'kls-ui';
import styles from './input-pin.module.css';
import {
  ChangeEvent,
  ClipboardEvent,
  FC,
  KeyboardEvent,
  useEffect,
  useState
} from 'react';

interface PinCode {
  code_1: string;
  code_2: string;
  code_3: string;
  code_4: string;
  code_5: string;
  code_6: string;
}
type PinCodeKeys = keyof PinCode;

const PIN_CODE_DEFAULT: PinCode = {
  code_1: '',
  code_2: '',
  code_3: '',
  code_4: '',
  code_5: '',
  code_6: ''
};

export interface InputPinProps {
  onSubmit?: (value: string) => void;
  disabled?: boolean;
  upperCase?: boolean;
  empty?: boolean | undefined;
}
const InputPin: FC<InputPinProps> = ({
  upperCase = false,
  empty = false,
  disabled = false,
  ...props
}) => {
  const [pinCode, setPinCode] = useState<PinCode>(PIN_CODE_DEFAULT);
  const objectKeys = Object.keys(pinCode);
  const defaultStyles = {
    baseClassName: 'text-center',
    containerStyle: { aspectRatio: '1', maxWidth: '50px', margin: 'auto' }
  };
  const isLastInput = (key: PinCodeKeys): boolean =>
    objectKeys.indexOf(key) + 1 === objectKeys.length;
  const isFirstInput = (key: PinCodeKeys): boolean =>
    objectKeys.indexOf(key) === 0;
  const adaptValue = (value: string) =>
    upperCase ? value.toUpperCase() : value;
  const fillPinCode = (key: PinCodeKeys, value: string): void => {
    value = adaptValue(value.trim());
    if (value.length == 0) return;
    if (pinCode[key] === '') setPinCode({ ...pinCode, [key]: value[0] });
    else setPinCode({ ...pinCode, [key]: value[1] });
    if (isLastInput(key)) return;
    const nextKey = objectKeys[objectKeys.indexOf(key) + 1];
    (document.getElementById(nextKey) as HTMLElement)?.focus();
  };

  const handleKeyDown = (
    key: PinCodeKeys,
    event: KeyboardEvent<HTMLInputElement>
  ): void => {
    const overrideDeleteKeyEvent = () => {
      event.preventDefault();
      setPinCode({ ...pinCode, [key]: '' });
      if (isFirstInput(key)) return;
      const previousKey = objectKeys[objectKeys.indexOf(key) - 1];
      (document.getElementById(previousKey) as HTMLElement)?.focus();
    };
    const overrideArrowRightKeyEvent = () => {
      event.preventDefault();
      if (isLastInput(key)) return;
      const previousKey = objectKeys[objectKeys.indexOf(key) + 1];
      (document.getElementById(previousKey) as HTMLElement)?.focus();
    };
    const overrideArrowLeftKeyEvent = () => {
      event.preventDefault();
      if (isFirstInput(key)) return;
      const previousKey = objectKeys[objectKeys.indexOf(key) - 1];
      (document.getElementById(previousKey) as HTMLElement)?.focus();
    };

    if (event.key === 'Backspace') overrideDeleteKeyEvent();
    if (event.key === 'ArrowRight') overrideArrowRightKeyEvent();
    if (event.key === 'ArrowLeft') overrideArrowLeftKeyEvent();
  };
  const overridePaste = (
    key: PinCodeKeys,
    event: ClipboardEvent<HTMLInputElement>
  ): void => {
    event.preventDefault();
    if (!event.clipboardData.getData('text')) return;
    const text = adaptValue(event.clipboardData.getData('text').trim());
    const keysToFill = objectKeys.slice(objectKeys.indexOf(key));
    const pinCodeObj = keysToFill.reduce((acc, key, currentIndex) => {
      if (text[currentIndex]) return { ...acc, [key]: text[currentIndex] };
      return acc;
    }, {});
    const focusedKeyIndex =
      Math.min(objectKeys.indexOf(key) + text.length, objectKeys.length) - 1;
    const focusedKey = objectKeys[focusedKeyIndex];
    (document.getElementById(focusedKey) as HTMLElement)?.focus();
    setPinCode({ ...pinCode, ...pinCodeObj });
  };
  const args = (key: PinCodeKeys): object => ({
    id: key,
    onChange: (e: ChangeEvent<HTMLInputElement>) =>
      fillPinCode(key, e.target.value),
    onPaste: (e: ClipboardEvent<HTMLInputElement>) => {
      overridePaste(key, e);
    },
    onKeyDown: (e: KeyboardEvent<HTMLInputElement>) => handleKeyDown(key, e),
    disabled,
    value: pinCode[key]
  });
  const emptyFields = () => {
    setPinCode(PIN_CODE_DEFAULT);
    const focusedKey = objectKeys[0];
    (document.getElementById(focusedKey) as HTMLElement)?.focus();
  };
  useEffect(() => {
    if (empty || disabled) emptyFields();
  }, [empty, disabled]);

  useEffect(() => {
    const finalPinCode = Object.values(pinCode).join('');
    if (finalPinCode.length === 6 && props.onSubmit)
      props.onSubmit(finalPinCode);
  }, [pinCode]);

  return (
    <div className={styles.container}>
      <Input
        size="sm"
        withoutError
        {...defaultStyles}
        args={args('code_1')}></Input>
      <Input
        size="sm"
        withoutError
        {...defaultStyles}
        args={args('code_2')}></Input>
      <Input
        size="sm"
        withoutError
        {...defaultStyles}
        args={args('code_3')}></Input>
      <div>
        <span className={styles.spaceBetween}>-</span>
      </div>
      <Input
        size="sm"
        withoutError
        {...defaultStyles}
        args={args('code_4')}></Input>
      <Input
        size="sm"
        withoutError
        {...defaultStyles}
        args={args('code_5')}></Input>
      <Input
        size="sm"
        withoutError
        {...defaultStyles}
        args={args('code_6')}></Input>
    </div>
  );
};
export default InputPin;
