
import { FC } from 'react';
import { BaseSVGIconProps } from '../../types/components.type';
import AbstractSVGIcon from './AbstractSVGIcon';
const StarSvg: FC<BaseSVGIconProps> = (props) => (
  <AbstractSVGIcon
    size={props.size}
    className={props.className}
    spin={props.spin}
    hidden={props.hidden}
    viewBox="0 0 14 14">
    <path d="M6.99998 10.8467L11.12 13.3333L10.0266 8.64666L13.6666 5.49332L8.87331 5.08666L6.99998 0.666656L5.12665 5.08666L0.333313 5.49332L3.97331 8.64666L2.87998 13.3333L6.99998 10.8467Z"/>
    </AbstractSVGIcon>
);
export default StarSvg;
