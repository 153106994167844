import {useTranslation} from "react-i18next";

export const useHandleTranslation=(translationKey:string="")=>{
  const RTL_LANGUAGES=['ar'];
  const LTR_LANGUAGES=['fr',"en"];

  const {i18n,t}=useTranslation(translationKey);
  const isLngAr=(i18n.language==="ar");
  const isLngFr=(i18n.language==="fr");
  const isLTR=(LTR_LANGUAGES.includes(i18n.language));
  const isRTL=(RTL_LANGUAGES.includes(i18n.language));

  const getDirX=()=>{
    if (isLngFr) return "left";
    if (isLngAr) return "right";
    return "";
  }

  const getDivDir=()=>{
    if (isRTL) return "rtl";
    if (isLTR) return "ltr";
    return "";
  }
  const getInverseDivDir=()=>{
    if (isLTR) return "rtl";
    if (isRTL) return "ltr";
    return "";
  }
  const currLng=i18n.language;
  const currDir=i18n.dir;

  return {isLngAr,isLngFr,currLng,t,currDir,getDirX,getInverseDivDir,getDivDir};
}
