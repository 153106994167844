import classNames from 'classnames';
import styles from './cards.module.css';
import { FC, ReactNode } from 'react';
import {MaterialIcon} from "../../../../../shared/components/icons";
interface CardProps {
  childrenCard?: ReactNode;
  childrenInfo?: ReactNode;
  onClick?:()=>void;
  toAdd?:boolean;
  selected?:boolean;
}

export const Card: FC<CardProps> = ({ childrenCard,childrenInfo,onClick,toAdd,selected}) => {
  const cardContainerStyle = classNames({
    [styles.cardContainer]: true,
    [styles['portal-cards-css-var']]: true,
  });
  const cardStyle=classNames({
     [styles.card]:true,
    [`!border-solid ${styles.normalCard}`]:!toAdd,
    '!border-dashed justify-center items-center':toAdd,
    [styles.notSelected]:!selected,
    [styles.selected]:selected,

  })
  const renderCardChildren=()=>{
  if(toAdd) return  <MaterialIcon label="add" className={styles.addCardIcon}/>;
  return childrenCard;
  }

  return (
    <div className={cardContainerStyle}>
       <div onClick={onClick} className={cardStyle}>{renderCardChildren()}</div>
      {childrenInfo && <div className={styles.cardInfoContainer}>{childrenInfo}</div>}
    </div>
  );
};
