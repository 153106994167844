import { FC, useCallback } from 'react';
import styles from './table-items.module.css';
import { Menu } from '../../../../../shared/components/index.tsx';

import { EserviceMembersListResponse } from '../../../types/data.types.ts';
import { MaterialIcon } from '../../../../../shared/components/icons/index.ts';
import { AvatarComponent } from '../../../../../shared/components/avatar/AvatarComponent.tsx';
import { USERS_ACTIONS_ALL, USERS_ACTIONS_AP, USERS_ACTIONS_NONAP } from '../../../utils/constants/components.constants.tsx';
interface RowTableMemberProps {
  item: EserviceMembersListResponse;
  onSelectDropDown?: (selection: { id: string; selectedValue: string;emailPersonnel:string;nameUser:string;logoUser:string;role:string,ownerLogin:string,note:string }) => void;
  type:string;
}
export const RowTableMember: FC<RowTableMemberProps> = (props) => {

  const getDropDownData = useCallback((type: string) => {
    if (!type) return ;

    if (type=== 'memberApprouve') {
      return USERS_ACTIONS_AP;
    }
    if(type=== 'memberNonApprouve')
    return USERS_ACTIONS_NONAP;
  
    if(type=== 'contributeurNonApprouve')
      return USERS_ACTIONS_NONAP;
    
  return USERS_ACTIONS_ALL;
  }, []);
  
  const handleSelect = (value: string) => {
    if (props.onSelectDropDown) {
      
      props.onSelectDropDown({
        id: props.item.idRequest,
        selectedValue: value,
        emailPersonnel:props.item.emailPersonnel,
        nameUser:props.item.nameUser,
        logoUser:props.item.logoUser,
        role:props.item.roleUserDemanded,
        ownerLogin:props.item.loginUser,
        note:props.item.note
      });
    }
  };
  const menuIcon = (
    <MaterialIcon label="more_vert" className={styles.menuIcon} />
  );
  
  const renderMenu = () => {
    
    if (props.type) {
      return (
        <Menu
          className={styles.moreIcon}
          icon={menuIcon}
          onChange={(selectedValue: string[]) => handleSelect(selectedValue[0])}
          list={getDropDownData(props.type)}
        />
      );
    }
    return null;
  };
  return (
    <tr key={props.item.idRequest}>
         <td className={styles.fCaseContainer}>
        <div className={styles.fCase}>
        {props.item.logoUser  ? (
        <img
          src={props.item.logoUser}
          alt={props.item.nameUser}
          className="w-8 h-8 rounded-full"
        />
      ) : (
        <AvatarComponent fullname={props.item.nameUser} />
      )
    }
        </div>
      </td>

      <td className={styles.aCaseContainer}>
        <div className={`${styles.aCase} !grid`}>{props.item.nameUser} <span className={styles.emailPersonnel}>{props.item.emailPersonnel}</span></div>
      </td>
      {props.type === 'users' ?
 <td className={styles.lCaseContainer}>
        <div className={styles.lCase}><span> {props.item.loginUser}</span>
        <div className={styles.headerIcons}>{renderMenu()}</div>
        </div>
      </td>
      :
      <td className={styles.aCaseContainer}>
      <div className={styles.aCase}>{props.item.loginUser}</div>
      </td>
}

      
      {
        props.type ===  'contributeurNonApprouve' &&   
        <td className={styles.lCaseContainer}>
        <div className={styles.lCase}><span> {props.item.note||'N/A'}</span>
        <div className={styles.headerIcons}>{renderMenu()}</div>
        </div>
      </td>
      }
    { props.type == 'memberNonApprouve' &&
      <td className={styles.lCaseContainer}>
        <div className={styles.lCase}>
          {props.item.roleUserDemanded === 'ADMINISTRATEUR' ? (
            <div><span className={styles.statutContainerAdmin}>{props.item.roleUserDemanded}</span>
            <MaterialIcon label={'schedule'} className={styles.timeIcon}/></div>
          ) : 
            <div><span className={styles.statutContainerCD}>
              {props.item.roleUserDemanded} 
            </span><MaterialIcon label={'schedule'} className={styles.timeIcon}/></div> }
                   <div className={styles.headerIcons}>{renderMenu()}</div>

        </div>
      </td>
}
{ props.type == 'memberApprouve' &&
<>
      <td className={styles.aCaseContainer}>
        <div className={styles.aCase}>
        {props.item.roleUserDemanded === 'ADMINISTRATEUR' ? (
            <div><span className={styles.statutContainerAdmin}>{props.item.roleUserApproved}</span>
           </div>
          ) : 
            <div><span className={styles.statutContainerCD}>
              {props.item.roleUserDemanded} 
            </span></div> }
                  

        </div>
      </td>
        <td className={styles.lCaseContainer}>
        <div className={styles.lCase}><span> {props.item.roleStatusUser}</span><div className={styles.headerIcons}>{renderMenu()}</div> </div>
        
      </td>
</>
}


    </tr>
  );
};
