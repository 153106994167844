import  styles from "./image-uploader.module.css"
import {GichetIcon} from "../../../icons";
import classNames from "classnames";
import {useRef, useState, ChangeEvent, FC, useEffect} from "react";
import {useHandleFile} from "../../../../../../shared/hooks";

const  ALLOWED_FORMATS = ["image/png", "image/svg+xml"];
const MO=1024 * 1024;
export interface ImageUploaderProps {
    onChange?:(url:string|undefined,error:string|undefined,format:string|undefined)=>void;
    readOnly? :boolean;
    defaultImageUrl?:string;
}
export const ImageUploader:FC<ImageUploaderProps>=(props)=> {
    const {readOnly}=props
    const [isHovered, setHovered] = useState<boolean>(false);
    const [file,setFile] = useState<File|undefined>();
    const {url,isError,isSuccess,image64,errors,format}=useHandleFile(file,[0,2*MO],ALLOWED_FORMATS)
    const rootStyle=classNames({
        ["flex items-center gap-4"]:true,
        [styles["onboarding-image-uploader-css-var"]]:true,
    })
    const iconStyle=(showOnHover:boolean)=>classNames({
        ["show transition-all duration-700"]:showOnHover===isHovered || readOnly,
        ["notShow"]:showOnHover!==isHovered
    })
    const imageStyle=classNames({
        ["absolute show transition-all duration-700"]:true,
        [styles.imageStyle]:true,
        ["!opacity-15"]:isHovered && isSuccess && !readOnly

    })
    const inputRef=useRef<HTMLInputElement>(null);
    const handleUpload=()=>{
        if(inputRef.current && !readOnly) inputRef.current.click();
    }
    const handleUploadImageEvent=(e:ChangeEvent<HTMLInputElement>)=>{
         if (!e.target.files) return ;
         const file = e.target.files[0];
         if (!file) return ;
         setFile(file);
    }
    const renderImage=()=> {
        if(isSuccess) return <img src={url} alt="" className={imageStyle}/>;
        if(props.defaultImageUrl)  return <img src={props.defaultImageUrl} alt="" className={imageStyle}/>;
        return <GichetIcon className={`size-6 absolute ${styles.iconColor} ${iconStyle(false)}`}/>;
    }
    useEffect(() => {
        if (isError && errors){
            let errorsMessage:string|undefined;
            if(errors.isAllowedFormatError) errorsMessage="L'image doit être au format PNG ou SVG."
            if (errors.isSizeError) errorsMessage="Votre image dépasse la taille de fichier maximale de 2 Mo autorisée";
            if (props.onChange) props.onChange(undefined,errorsMessage,format);
        }
        else if (isSuccess && props.onChange) props.onChange(image64,undefined,format);
    }, [isSuccess,image64]);
    const renderDescription=()=>{
        if (!readOnly) return (<div>
            <div>
                <span className={styles.imageInfoLabel}>Formats autorisés : </span>
                <span className={styles.imageInfoValue}> .png et .svg</span>
            </div>
            <div>
                <span className={styles.imageInfoLabel}>Taille maximale autorisée : </span>
                <span className={styles.imageInfoValue}> 2 Mo</span>
            </div>
            <div>
                <span className={styles.imageInfoLabel}>Dimensions idéales de l’image:</span>
                <span className={styles.imageInfoValue}> 100px * 100 px </span>
            </div>
        </div>)
    }
    return (
        <div className={rootStyle}>
            <input type="file" ref={inputRef} className="hidden" onChange={handleUploadImageEvent}/>
            <div className={styles.imageUploadContainer} onMouseOver={() => setHovered(true)}
                 onMouseOut={() => setHovered(false)} onClick={handleUpload}>
                <div className="absolute flex items-center justify-center w-full">
                    {!readOnly && <i className={`material-icon !text-2xl ${styles.iconColor}  ${iconStyle(true)}`}>edit</i>}
                    {renderImage()}
                </div>
            </div>
            {renderDescription()}
        </div>
    );
}