import { Spin } from 'antd';
import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import styles from './cards.module.css'
interface  SimpleCardProps{
  spin?:boolean;
  className?:string;
  children?:ReactNode;
}
export  const SimpleCard:FC<SimpleCardProps> =(props)=>{
  const {spin=false,className="",children}=props;
  const containerStyles=classNames({
    [styles["portal-card-css-var"]]:true,
    [className]:true,
    [styles.simpleCardRootContainer]:true,
  })
  return(
    <div className={containerStyles}>
      <Spin spinning={spin} size="large">
        {children}
      </Spin>
    </div>
  )
}