import {FC} from "react";
import {
    flexRender,
    getCoreRowModel,
    useReactTable,
    getSortedRowModel,
    getPaginationRowModel,
    ColumnDef,
} from '@tanstack/react-table';
import {EmptyData} from "../empty-data";
import {Spin} from "antd";

interface GenericTableProps{
data:object[],
columns:ColumnDef<object, object>[],
isLoading?:boolean,
emptyData?:{title:string,description:string},
}
export const GenericTable:FC<GenericTableProps>=(props)=>{
    const {isLoading=false,emptyData={title:"Aucune donnée trouvée",description:""}}=props
    const table = useReactTable({
        data:props.data,
        columns:props.columns,
        initialState: {
            pagination: {
                pageSize: 2,
            },
        },
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    });

    const renderTableBody=()=>{
        if (isLoading) return (<td colSpan={table.getAllColumns().length}>
            <div className="flex min-h-[20vh] justify-center items-center w-full">
                <Spin size="large"/>
            </div>
        </td>)
        if(table.getRowModel().rows.length==0) return(
            <td colSpan={table.getAllColumns().length}>
                <EmptyData  searchIcon title={emptyData?.title} description={emptyData?.description}/>
            </td>
           )
       return (
            table.getRowModel().rows.map((row) => (
                <tr key={row.id} className="border-b">
                    {row.getVisibleCells().map((cell) => (
                        <td key={cell.id} className="px-4 pt-[14px] pb-[18px] border-b border-b-slate-200">
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                    ))}
                </tr>
            )))
    }

    return (
        <div>
        <table className="border border-solid border-slate-200 rounded-md w-full">
            <thead className="text-slate-500 border border-solid bg-slate-50 text-xs  ">
            {table.getHeaderGroups().map((headerGroup) => (
                <tr
                    key={headerGroup.id}
                    className="uppercase"
                >
                    {headerGroup.headers.map((header) => (
                        <th
                            key={header.id}
                            className="px-4 pr-2 py-4 border-b border-slate-200 font-medium "
                        >
                            {header.isPlaceholder ? null : (
                                <div
                                    {...{
                                        className: header.column.getCanSort()
                                            ? 'cursor-pointer select-none flex min-w-[36px]'
                                            : '',
                                        onClick: header.column.getToggleSortingHandler(),
                                    }}
                                >
                                    {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                    )}
                                    {{
                                        asc: <span className="pl-2">↑</span>,
                                        desc: <span className="pl-2">↓</span>,
                                    }[header.column.getIsSorted() as string] ?? null}
                                </div>
                            )}
                        </th>
                    ))}
                </tr>
            ))}
            </thead>
            <tbody>
            {renderTableBody()}
            </tbody>
        </table>
    </div>)
}